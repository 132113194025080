<template>
    <div class="mb-50">
        <div class="request-status-block">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <h1> Coach</h1>
                </li>
            </ul>
        </div>
        <b-row>
            <b-col md="12">
                <div class="card">
                    <div class="card-body">
                        <h3>Request</h3>
                        <label class="ask-header">Subject</label>
                        <p v-text="ask.subject"></p>
                        <!-- <p v-text="ask.subject" v-if="setStatus(ask.status) == 'Closed'"></p>
                        <div v-else >
                            <input name="subject" type="text" placeholder="Enter subject" v-model.trim="ask.subject" class="form-control">
                            <p></p>
                        </div> -->
                        <label class="ask-header">Description</label>
                        <p class="md-justify" v-text="ask.text"></p>
                        <!-- <p class="md-justify" v-text="ask.text" v-if="setStatus(ask.status) == 'Closed'"></p>
                        <b-form-textarea name="text" placeholder="Enter description" rows="14" v-model.trim="ask.text" v-else class="form-control md-justify"></b-form-textarea> -->
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card">
                    <div class="card-body">
                        <b-form-textarea name="text" placeholder="Enter description" rows="14" v-model.trim="coach" class="form-control md-justify"></b-form-textarea>
                    </div>
                    <div class="card-body">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <button type="button" class="btn custom-card-header-filter btn-dropbox tab-focus" @click="coachRequest()" :disabled="!coach">
                                    Coach Request
                                </button>
                            </li>
                            <li class="list-inline-item">
                                <button type="button" class="btn custom-card-header-filter btn-cancel tab-focus" @click="$router.push(`/`)"  >Cancel</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications'

export default {
    name: 'RequestCoachView',
    data() {
        return {
            ask: {},
            token: '',
            authUser: {},
            coach: ''
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/asks/${this.$route.params.request_id}`, {headers: {'x-access-token': this.token}}).then(request => {
                this.ask = request.data;
                if (setRequestStatus( this.ask.status) != 'In Review' && setRequestStatus( this.ask.status) != 'Active') {
                    this.$router.push('/');
                }
                if (this.ask && this.ask.community && this.ask.community.content && this.ask.community.content.coach) {
                    this.coach =  this.ask.community.content.coach;
                }
            });
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        coachRequest: function() {
            let action = setRequestStatus( this.ask.status) == 'In Review'? 'reject': setRequestStatus( this.ask.status) == 'Active'? 'close': '';
            let params = {
                askId: this.ask._id,
                action: action,
                status: 'coach',
                approvedBy: 'dashboard',
                coachMsg: this.coach
            }
            if (this.authUser.memberID) {
                params.adminId = this.authUser.memberID;
            }
            this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
                this.showSuccessMsg({message: 'Thank you for your response'})
                if(action == 'reject') {
                    this.$router.push('/');
                } else {
                    let query = this.$route.query;
                    if(query.from == 'request-detail-page') {
                        this.$router.push(`/engagement/requests/${this.ask._id}`)
                    }
                    if(query.from == 'request-page') {
                        this.$router.push('/engagement/requests')
                    }
                }
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        }
    }
}
</script>
