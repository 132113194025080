const reportType = {
    Interaction: 'interaction',
    OptOut: 'opt-out',
    OverDueRequests: 'overdue-requests',
    InvalidEmails: 'invalid-emails',
    NoFollowUp: 'no-follow-up',
    DonorEngagement: 'donor-engagement',
    ProfileUpdates: 'profile-updates',
    Referrals: 'referrals'
}

module.exports = {
    reportType
}