// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/index'
import VueAxios from 'vue-axios';
import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone'
import {ServerTable, ClientTable} from 'vue-tables-2';
import VeeValidate from 'vee-validate';
import lodash from 'lodash';
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr'
import helpers from './helpers/helper.js';

Vue.prototype.$helpers = helpers;
Vue.use(VeeValidate, {
	inject: true,
	fieldsBagName: 'veeFields'
  });
Vue.use(VueAxios, axios);
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.prototype.$moment = moment;
Vue.prototype.$lodash = lodash;
Vue.prototype.$isLoading = false;
const toastTypes = {
  	success: 'success',
  	error: 'error',
  	info: 'info',
  	warn: 'warn'
}

miniToastr.init({types: toastTypes})

function toast ({title, message, type, timeout, cb}) {
  	return miniToastr[type](message, title, timeout, cb)
}

const options = {
  	success: toast,
  	error: toast,
  	info: toast,
  	warn: toast
}
//  VueNotifications.setPluginOptions(options)

Vue.use(VueNotifications, options)

// cssVars()
//localStorage.setItem('test', 'test');
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		const authUser = JSON.parse(window.localStorage.getItem('authUser'));
		if (authUser && typeof authUser === 'object') {
			next()
		} else {
			if (authUser && typeof authUser === 'object') {
				// console.log('authUser');
			}

			// console.log("No user stored...log in.");
			if(to.name == 'RequestDetail') {
				localStorage.setItem('afterLogin', to.fullPath);
			}
			next({
				name: 'Login'
			})
		}
	} else {
		// if(to.matched.some(record => record.name  == 'Login')) {
		// 	const authUser = JSON.parse(window.localStorage.getItem('authUser'));
		// 	if (authUser && typeof authUser === 'object') {
		// 		next({name: 'Dashboard'});
		// 	}
		// }
		next()
	}
})

router.afterEach((to, from, next) => {
	Vue.nextTick(() => {
		document.title = to.meta.title
	})
})

Vue.filter('momentDate', function (value) {
	if (value && value !== 'N/A') {
		var tz = moment.tz.guess();
		return moment(value).tz(tz).format('MM/DD/YYYY HH:mm')
	} else {
    	return value;
  }
});
Vue.filter('onlyDate', function (value) {
	if (value && value !== 'N/A') {
		return moment(value).tz("America/New_York").format('MM/DD/YYYY')
	} else {
    	return value;
  }
});
Vue.use(BootstrapVue)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  }
})
