<template>
  <div>
    <b-card>
      <template slot="header">
        <h4 class="mt-2 custom-card-header-text">Accepted Responses with "{{ $route.query.tag }}"</h4>
        <ul class="list-inline float-right custom-header-rightstyle">
            <li v-if="communities.length>1" class="list-inline-item">
                <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                  <option :value="null" selected disabled>Community</option>
                  <option value="all">All</option>
                  <option v-for="community in communities" v-bind:value="community._id" :key="community._id">{{community.name}}</option>
                </b-form-select>
            </li>
            <li class="list-inline-item">
              <button type="button" aria-label="Export" aria-live="assertive" @click="exportData()" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
            </li>
        </ul>
      </template>
      <b-card-body>
        <v-client-table v-bind:data="askData" v-bind:columns="columns" :options="options" id="dataTable" class="responses-table">
            <span slot="community" slot-scope="props">
              {{props.row.community ? props.row.community : 'N/A'}}
            </span>
            <span slot="title" slot-scope="props">
              {{props.row.title ? props.row.title : 'N/A'}}
            </span>
            <span slot="adviser" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
              {{props.row.adviser}}
            </span>
            <span slot="adviser" slot-scope="props" v-else-if="props.row.adviser">
              {{props.row.adviser}}
            </span>
            <span slot="sent" slot-scope="props" v-if="props.row.sent">
              {{ props.row.sent | momentDate }}
            </span>
            <span slot="sent" v-else>N/A</span>
            <span slot="status" v-if="props.row.isReferralGive == 'referred'" slot-scope="props" class="badge badge-warning">
            Referred
            </span>
            <span v-else slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setStatus(props.row.status) == 'Accepted'}, {'badge-danger': setStatus(props.row.status) == 'Declined'}, {'badge-blue': setStatus(props.row.status) == 'Snoozed'}, {'badge-warning': setStatus(props.row.status) == 'In Review' || setStatus(props.row.status, props.row) == 'Pending'}]">
              {{props.row.status ? setStatus(props.row.status) : 'N/A'}}
            </span>
            <span slot="followUp" slot-scope="props" class="capitalize" v-if="props.row.followUp">
              {{ props.row.followUp }}
            </span>
            <span slot="action" slot-scope="props">
              <b-button v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox" size="sm" class="notification-btn tab-focus" @click="openRequestInNewTab(props.row.requestId)">
                  <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                  <i class="icon-eye"></i>
              </b-button>
            </span>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils';
import axios from 'axios';
import { exportCSV } from '@/assets/js/common';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications'
import { setResponseStatus } from '@/assets/js/set-status';

export default {
  name: 'responses',
  data() {
    return {
      responses: [],
      communities: [],
      selected: null,
      actions: ['accepted', 'declined', 'referred', 'pending', 'in review'],
      columns: ['community', 'sent', 'adviser', 'request_Action', 'status', 'open', 'click', 'thankYou', 'followUp', 'action'],
      token: '',
      askData: [],
      perPage: 25,
      options: {
        headings: {
          requestId: 'Request ID',
          open: 'O',
          click: 'C',
          thankYou: 'TY',
          followUp: 'FU'
        },
        sortable: ['sent', 'adviser', 'request_Action', 'status'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
            chunk: 10,
            nav: 'fixed'
        },
        requestFunction: async function (data) {
            if (this.$route.query.action) {
                data.queryAction = this.$route.query.action;
            }
            return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},  params: data}).catch(function (e) {
                if (e.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            }.bind(this));
        },
        responseAdapter({data}) {
            return {
                data : data.responses,
                count: data.askCount
            }
        },
        customSorting: {
          sent: function (ascending) {
              return function (a, b) {
                  var isValid = function(date) {
                    return !isNaN(isFinite(date))
                  }
                  var lastA = a.sent;
                  var lastB = b.sent;
                  if (ascending && isValid(lastA) && isValid(lastB)) {
                    return new Date(lastA) - new Date(lastB);
                  }
                  if (!ascending && isValid(lastA) && isValid(lastB)) {
                    return lastA <= lastB ? 1 : -1;
                  }
              }
          }
        }
      },
      serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/responders-matched-to-tag`
    }
  },
  notifications: {
      showSuccessMsg: {
          type: VueNotifications.types.success,
          message: 'We are now preparing your export and will send you an email once it is available for download.',
          timeout: 3000
      },
      showWarnMsg: {
          type: VueNotifications.types.warn,
          message: "No data found for export file"
      }
  },
  async created() {
    this.token = localStorage.getItem('token');
    await this.getCommunity();
    await this.loadData();
    document.title = 'Accepted Responses with ' + this.$route.query.tag
  },
  methods: {
    loadData() {
        var user = localStorage.getItem('authUser');
        this.axios.get(`${this.serverURL}?tag=${this.$route.query.tag}&community=${this.selected}`, {
          headers: {'x-access-token': this.token}
        })
        .then(response => {
          this.askData = response.data;
        })
        .catch(err => {
          if (err.response.status === 401) {
              localStorage.removeItem('authUser');
              this.$router.push('/login');
          }
        });
        var queryParams = {
            headers: {'x-access-token': this.token}
        }
        if (this.selected && this.selected != 'all') {
            queryParams.params = {community: this.selected};
        }
    },
    checkData(value) {
      if(value) { return value; } else { return 'N/A';}
    },
    async getCommunity() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
      .catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.communities = data;
      if(this.$route.params.community_id) {
        var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
        if(selectedCommunity.length) {
          this.selected = selectedCommunity[0]._id;
        }
      }
    },
    filterByCommunity: async function(value) {
      this.selected = value
      await this.loadData();
    },
    filterByAction: async function(value) {
        window.open((`/engagement/responses/?action=${value}`), '_blank');
    },
    setStatus: function(status) {
      return setResponseStatus(status);
    },
    exportData: async function() {
        let responsesWithTagArray = []

        if (this.$route.query.tag) {
          if (this.askData.length) {
            await exportCSV(this.askData.slice(), 'responses', ['memberId', 'requestId', 'commentCount', 'isReferralGive', 'title'], ['sent']);
          } else {
            this.showWarnMsg();
          }
        } else {
            this.showWarnMsg();
        }
    },
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    openRequestInNewTab(path) {
      window.open((`/engagement/requests/${path}`), '_blank');
    }
  }
}
</script>
