<template>
    <div>
    <b-card class="admins-list">
      <template slot="header">
        <h3 aria-level="1">Upload SFTP Files</h3>
      </template>
      <b-card-body>
        <v-client-table v-bind:data="uploadFiles" v-bind:columns="columns" :options="options" id="dataTable" class="uploads-file-list">
            <span slot="community" slot-scope="props" class="display-link"> {{props.row.community && props.row.community.name}} </span>
            <span slot="file name" slot-scope="props"> {{props.row.originalFileName}} </span>
            <span slot="status" slot-scope="props"> {{setStatus(props.row.currentStatus)}} </span>
            <span slot="upload date" slot-scope="props"> {{props.row.when | momentDate}} </span>
            <span slot="process date" slot-scope="props" > {{props.row.fileUploadDate? props.row.fileUploadDate: props.row.completed ? props.row.completed: 'N/A' | momentDate}} </span>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
    name: 'Uploads',
    data() {
        return {
            uploadFiles: [],
            columns: ['community', 'file name', 'status', 'upload date', 'process date'],
            token: localStorage.getItem('token'),
            options: {
              sortable: ['community', 'status', 'upload date', 'process date'],
              sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
              pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
              }
            }
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        if (authUser && authUser.role === 'Admin') {
            this.columns = ['community', 'file name', 'status', 'upload date', 'process date'];
        }
        this.loadData();
    },
    methods: {
      setStatus(status) {
        let currentStatus = '';
        switch (status) {
          case "pending":
            currentStatus = "Queued"
            break;
          case "processing":
            currentStatus = "Processed"
            break;
          case "finished":
            currentStatus = "Completed"
            break;
          case "finish":
            currentStatus = "Completed"
            break;
          default:
            break;
        }
        return currentStatus;
      },
      loadData() {
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard//upload-files-data`, {headers: {'x-access-token': this.token}}).then(async response => {
          console.log("response.data.uploadFiles : ", response.data)
          if (response.data) {
            this.uploadFiles = response.data;
          }
        }).catch(err => {
          if (err.response.status  === 401) {
            localStorage.removeItem('authUser');
            this.$router.push('/login');
          }
        });
        this.$helpers.setTabindexOnTableCells();
      },
    }
}
</script>
