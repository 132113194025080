<template>
    <div class="mb-50">
        <div class="request-status-block">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <h1> Request <!-- <span v-if="ask.status">{{setStatus(ask.status)}}</span> --> </h1>
                </li>
                <li class="list-inline-item float-right" v-if="!tempask.isClose">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Show options" variant="btn btn-dropbox btn-sm tab-focus" right>
                                <template slot="button-content">
                                    <i class="icon-settings"></i>
                                </template>
                                <b-dropdown-item  v-if="(authUser.memberID || authUser.role =='SuperAdmin')" @click="openComment()" class="comment-info">
                                    Comment<span class="count" v-if="commentCount">{{commentCount}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) !== 'Closed'" @click="closeRequest(tempask._id)">Close the request</b-dropdown-item>
                            </b-dropdown>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <div class="ask-detail">
                            <div class="inline-grid">
                                <p class="ask-header" v-if="ask && ask.first && ask.last">
                                    <span class="display-link" @click="goToProfile(createdByMember)" v-if="createdByMember">{{ ask.first + ' ' +  ask.last }}</span>
                                    <span v-else>{{ ask.first + ' ' +  ask.last }}</span>
                                </p>
                                <span><div class="edit-ask" v-if="ask && ask.email">{{ask.email}}</div></span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Channel</p>
                                <span>{{ ask.channelType ? ask.channelType : 'N/A'}}</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Request Received</p>
                                <span v-if="this.tempask.createdAt">{{this.tempask.createdAt | momentDate}}</span>
                                <span v-else>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Advisors Asked</p>
                                <span v-if="Array.isArray(ask.recommendedAdvisors) && ask.recommendedAdvisors.length > 0">{{Array.isArray(ask.recommendedAdvisors) ? ask.recommendedAdvisors.length : 0}}</span>
                                <span v-else>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Advisor Feedback</p>
                                <span>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Request Status</p>
                                <span>Incomplete {{this.tempask.isClose ? '(Closed)' : ''}}</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Advice Area</p>
                                <span v-if="ask.dynamicFields && ask.dynamicFields.adviceArea">{{ ask.dynamicFields.adviceArea }}</span>
                                <span v-else>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Request Sent</p>
                                <span v-if="ask.reqSentDate">{{ask.reqSentDate | momentDate}}</span>
                                <span v-else>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Responses</p>
                                <span>N/A</span>
                            </div>
                            <div class="inline-grid">
                                <p class="ask-header">Member Feedback</p>
                                <span>N/A</span>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Request</h3>
                        <label class="ask-header">Subject</label>
                        <p v-text="ask.subject" v-if="ask.subject"></p>
                        <p v-else> N/A </p>
                        <!-- <div v-else >
                            <input name="subject" @blur="UpdateAskDetail('subject')" type="text" placeholder="Enter subject" v-model.trim="ask.subject" class="form-control">
                            <p></p>
                        </div> -->
                        <label class="ask-header">Description</label>
                        <p class="md-justify" v-text="ask.text" v-if="ask.text"></p>
                        <p class="md-justify" v-else> N/A</p>
                        <!-- <b-form-textarea name="text" @blur="UpdateAskDetail('text')" placeholder="Enter description" rows="14" v-model.trim="ask.text" v-else class="form-control md-justify"></b-form-textarea> -->
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Tags <span v-text="`(0)`"></span></h3>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Advisors <span v-text="`(0)`"></span></h3>
                        <b-table id="advisers" hover :fields="adviserColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Responses <span v-text="`(0)`"></span></h3>
                        <b-table id="responses" :fields="responseColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Advice <span v-text="`(0)`"></span></h3>
                        <b-table id="advice" :fields="adviceColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Referrals <span v-text="`(0)`"></span></h3>
                        <b-table id="advice" :fields="referralColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Member Follow Up <span v-text="`(0)`"></span></h3>
                        <b-table id="thankYou" :fields="thankYouColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Feedback <span v-text="`(0)`"></span></h3>
                        <b-table :fields="feedbackColumns"></b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" id="commentView">
                <b-card>
                    <b-card-body>
                        <h3>Comments <span v-text="`(${comments.length})`"></span></h3>
                        <b-table id="comments" hover :items="comments" :fields="commentColumns">
                            <template v-slot:cell(when)="row">
                                <span>
                                    {{row.item.when | momentDate}}
                                </span>
                            </template>
                        </b-table>
                        <div id="commentForm">
                            <b-form v-on:submit.prevent="addComment" v-if="addCommentFlag && !this.tempask.isClose">
                                <b-form-group label="" label-for="description">
                                    <b-form-textarea
                                    name="description"
                                    v-model="comment.description"
                                    rows="3"
                                    max-rows="6"
                                    v-validate="{ required: true, max:60 }"
                                    placeholder="Enter your comment">
                                </b-form-textarea>
                            </b-form-group>
                            <b-button type="submit" :disabled="!comment.description" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications'

export default {
    name: 'RequestDetail',
    data() {
        return {
            ask: {},
            community: {},
            createdByMember: {},
            comments: [],
            adviserColumns: [{key: 'name', label: 'Name', sortable: true}, {key: 'community', sortable: true}, {key: 'memberRank', label: 'Rank', sortable: true}, {key: 'last_request', label: "Request Rec\'d", sortable: true}, {key: 'last_response', label: 'Responded', sortable: true}, 'status', {key: 'action', sortable: true}],
            responseColumns: [{key: 'response_name', label: 'Name', sortable: true}, {key: 'response_email', label: 'Email', sortable: true}, 'community', {key: 'responded', sortable: true}, 'status', 'action', {key: 'sent', sortable: true}],
            feedbackColumns: [{key: 'feedback_name', label: 'Name', sortable: true}, 'type', , {key: 'received', sortable: true}, {key: 'testimonialConsent', label: 'Consent'}, 'rating', 'feedback'],
            commentColumns: [{key: 'memberName', label: 'Member', sortable: true}, {key: 'when', label: 'Date', sortable: true}, {key: 'comment', label: 'Comment', sortable: true}],
            adviceColumns: [{key: 'response_name', label: 'Name', sortable: true}, {key: 'when', label: 'Responded', sortable: true}, {key: 'insight', label: 'Advice', sortable: true}, {key: 'feedback', label: 'Feedback', sortable: true}],
            thankYouColumns: [{key: 'adviserName', label: 'Name', sortable: true}, {key: 'thankYouWhen', label: 'Date', sortable: true}, {key: 'responseComment', label: 'THANK YOU text', sortable: true}],
            referralColumns: [{key: 'advisor', label: 'Referred From', sortable: true}, {key: 'when', label: 'Date of Referral', sortable: true}, {key: 'referredTo', label: 'Referred To', sortable: true}, {key: 'action', label: 'Action', sortable: true}],
            token: '',
            commentCount: 0,
            authUser: {},
            comment: {},
            addCommentFlag: false,
            oldSubject: '',
            oldText: '',
            addAdvisorModal: false,
            addAdvisorEmail: '',
            addAdvisorErrMsg: '',
            advisorEmail: '',
            tempask: {}
        }
    },
    notifications: {
        showErrorMsg: {
            type: VueNotifications.types.error,
            message: 'This request cannot be shared.'
        },
        showSucesssMsg: {
            type: VueNotifications.types.success,
            message: 'New advisor added successfully.'
        }
    },
    created() {
        const dict = {
            custom: {
                advisorEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                }
            }
        }
        this.$validator.localize('en', dict);
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/incomplete-asks/${this.$route.params.request_id}`, {headers: {'x-access-token': this.token}}).then(request => {
                this.tempask = request.data;
                this.ask = this.tempask.request;
                this.oldSubject = this.ask.subject;
                this.oldText = this.ask.text;
                if (this.ask.comments) {
                    this.getComment(this.ask);
                }
                if (this.ask.bio) {
                    this.ask.text = `${this.ask.bio}\n\n`+this.ask.text;
                }
                if (this.ask.closeNicely) {
                    this.ask.text += `\n\n${this.ask.closeNicely}`;
                }
                this.createdByMember = this.tempask.memberId;
            });
            this.$helpers.setTabindexOnTableCells();
        },
        checkData: function(value) {
            if (value) { return value;}
            else {return 'N/A';}
        },
        goToProfile(Id){
            let route = this.$router.resolve({path: `/engagement/members/${Id}`});
            window.open(route.href, '_blank');
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        // scrollTo: function() {
        //     const elmnt = document.getElementById('commentView');
        //     elmnt.scrollIntoView();
        // },
        openComment: function() {
            this.addCommentFlag = true;
            setTimeout(function () {
                const elmnt = document.getElementById('commentForm');
                elmnt.scrollIntoView();
            }, 0);
        },
        closeRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/incomplete-asks/close`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(response => {
                this.loadData();
            });
        },
        addComment: async function() {
            if (this.comment.description && this.comment.description.length) {
                this.comment.askId = this.tempask._id;
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/incomplete-asks/comment`, this.comment, { headers: {'x-access-token': this.token}}).then(async response => {
                    this.comment = {};
                    if (response.data.request.comments) {
                        this.getComment(response.data.request);
                    }
                });
            }
        },
        getComment: function(data) {
            data.comments = this.$lodash.orderBy(data.comments, ['when'], ['desc'])
            if (this.authUser.role == 'Admin') {
                data.comments = this.$lodash.filter(data.comments, function(o) { return o.commentBy != 'SuperAdmin'; })
            }
            this.comments = data.comments;
            this.commentCount = data.comments.length;
        },
    }
}
</script>
