<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none ml-1" display="md" mobile />
      <SidebarToggler class="d-md-down-none ml-1" display="lg"/>
      <b-link class="navbar-brand" to="/">
        <h1><img class="navbar-brand-full" src="https://storage.googleapis.com/protopia/logos/logo_protopia_white_m.png" width="125" alt="Protopia Logo"></h1>
      </b-link>
      <b-navbar-nav class="d-md-down-none ml-auto">
          <div v-if="isPriority">
               <c-switch color="success" title="Priority Reports" v-on:change="changeStatus(),setAttributesToCswitch()" name="downloadReportPriority" v-model="userData.download_report_priority" label variant="pill" size="sm" class="float-right download-priority-report-switch"/>
          </div>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown/>
        </b-nav-item>
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="navItems" ref="sidebarNav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main dashboard-main">
          <div class="breadcrumb-top">
                <Breadcrumb :list="list"/>
                <!-- v-if="$route.name && ($route.name == 'Dashboard' || $route.name == 'Community Information')" -->
                <div class="form-search">
                    <!-- <router-link to='/dashboard/advanced-search' class="adv-search-label">
                    Advanced Search
                </router-link> -->
                    <b-form v-on:submit.prevent="searchData">
                        <b-form-group label-for="elementsTwoButtons" class="mb-0">
                            <b-input-group>
                                <b-form-input aria-label="Searchbar" v-model="searchTxt" name="search" type="text"></b-form-input>
                                <b-input-group-append>
                                    <b-button type="submit" aria-label="Search"><i class="fa fa-search"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-form>
                    <div tabindex="0" role="button" class="adv-search-label tab-focus" @click="openAdvancedSearchModal()">
                        Advanced Search
                    </div>
                </div>
          </div>
          <b-modal id="advanced-search-modal" class="advanced-search-modal" size="md" v-model="advancedSearchModal" ok-title="Close" @ok="advancedSearchModal = false" title="Advanced Search">
              <b-card-body>
                  <b-form>
                        <b-form-group label="Communties:" label-for="communities">
                            <li class="list-inline-item w-100">
                                <b-form-select v-model="search.community">
                                    <option :value="null" selected disabled>Community</option>
                                    <option value="all">All</option>
                                    <option v-for="community in allCommunities" :key="community._id" :value="community._id">{{community.name}}</option>
                                </b-form-select>
                            </li>
                        </b-form-group>
                      <b-form-group label="Organization:" label-for="organization">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="organization" v-model="search.organization" placeholder="Enter organization"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Title:" label-for="title">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="title" v-model="search.title" placeholder="Enter title"></b-form-input>
                      </b-form-group>
                      <b-form-group label="City:" label-for="city">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="city" v-model="search.city" placeholder="Enter city"></b-form-input>
                      </b-form-group>
                      <b-form-group label="State:" label-for="state">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="state" v-model="search.state" placeholder="Enter state"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Country:" label-for="country">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="country" v-model="search.country" placeholder="Enter country"></b-form-input>
                      </b-form-group>
                      <b-form-group label="School:" label-for="school">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="school" v-model="search.school" placeholder="Enter school"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Major:" label-for="major">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="major" v-model="search.major" placeholder="Enter major"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Year:" label-for="year">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="year" v-model="search.year" placeholder="Enter year"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Property ID:" label-for="propertyid">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="propertyid" v-model="search.propertyId" placeholder="Enter property ID"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Member Tags:" label-for="member_rank">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="member_rank" v-model="search.member_rank" placeholder="Enter member rank"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Member Type:" label-for="member_type">
                          <b-form-input type="text" v-on:keyup="handleBlur" name="member_type" v-model="search.member_type" placeholder="Enter member type"></b-form-input>
                      </b-form-group>
                      <div class="form-group">
                          <!-- class="d-block" -->
                          <label for="skill">Skill:</label>
                          <img tabindex="0" src="img/tooltip.png" v-b-tooltip.right title='To find members for multiple skills, enter values comma-separated (e.g., "marketing, design, communication" will return all members that match at least one of those values).' class="skill-info" height="12" alt="tooltip" v-bind:aria-live="isMozilla() ? 'polite' : null" />
                          <div class="bv-no-focus-ring">
                              <input id="skill" name="skill" type="text" v-on:keyup="handleBlur" v-model="search.skill" placeholder="Enter skill" class="form-control">
                          </div>
                      </div>
                      <div class="form-group">
                          <!-- class="d-block" -->
                          <label for="interests">Interests:</label>
                          <img tabindex="0" src="img/tooltip.png" v-b-tooltip.right title='To find members for multiple interests, entering comma-separated values (e.g. "marketing, design, communication") will return all members that match at least one of those values.' class="skill-info" height="12" alt="tooltip" />
                          <div class="bv-no-focus-ring">
                              <input id="interests" name="interests" type="text" v-on:keyup="handleBlur" v-model="search.interests" placeholder="Enter interests" class="form-control">
                          </div>
                      </div>
                      <ul class="list-inline">
                          <li class="list-inline-item">
                              <button type="button" class="btn custom-card-header-filter btn-dropbox tab-focus" @click="advancedSearch()" :disabled="isBtnDisabled">
                                  Search
                              </button>
                          </li>
                          <li class="list-inline-item">
                              <button type="button" class="btn custom-card-header-filter btn-cancel tab-focus" @click="clearSearch()">Clear</button>
                          </li>
                      </ul>
                  </b-form>
              </b-card-body>
                <template #modal-footer="{ cancel }">
                    <div>
                        <button class="btn custom-card-header-filter btn-primary tab-focus custom-close-button" @click="cancel()">Close</button>
                    </div>
                </template>
          </b-modal>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a :href="protopiaurl">Protopia</a>
      </div>
    </TheFooter>
  </div>
</template>
<script>
import nav from '@/_nav'
import { Header as AppHeader, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import axios from "axios";
import { Switch as cSwitch } from '@coreui/vue';
import VueNotifications from 'vue-notifications';
import SidebarToggler from '../components/Sidebar/SidebarToggler';

export default {
  name: 'DefaultContainer',
  components: {
    SidebarToggler,
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdown,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    cSwitch
  },
  data () {
    return {
      navItems: [],
      communityData: {},
      settingData: {},
    //   askAPeerBtn: {},
      reportData: {},
      userData: {},
      authUser: null,
      token: null,
      protopiaurl: process.env.VUE_APP_API_PROTOPIA_URL,
      isPriority: false,
      searchTxt: '',
      advancedSearchModal: false,
      search: {},
      isBtnDisabled: true,
      allCommunities: ''
    }
  },
  notifications: {
      showErrorMsg: {
          message: 'Search text should be greater than 2.',
          type: VueNotifications.types.error
      }
  },
  watch: {
    "$route": "updateCommunityForAdvancedSearch",
    $route(to, from) {
        this.handleUrlChange();
    }
  },
  async created() {
    this.token = localStorage.getItem('token');
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    await this.loadData();
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
        this.searchTxt = '';
        if (this.$route.params.slug == 'email_open') {
            this.$route.matched[1].name = 'Email Open Rate On Requests';
        } else if (this.$route.params.slug == 'engagement_rate') {
            this.$route.matched[1].name = 'Engagement Rate';
        } else if (this.$route.params.slug == 'first_response') {
            this.$route.matched[1].name = 'Time To First Response';
        }  else if (this.$route.params.slug == 'response_per_request') {
            this.$route.matched[1].name = 'Responses Per Request';
        } else if (this.$route.params.slug == 'satisfaction') {
            this.$route.matched[1].name = 'Average Satisfaction';
        } else if (this.$route.params.slug == 'profile_update') {
            this.$route.matched[1].name = 'Profile Updates';
        }
        return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
  mounted() {
    this.handleUrlChange();
    const targetNode = this.$el.querySelector('ul.nav');
    const config = { attributes: true, attributeFilter: ['class'], childList: true, subtree: true };
    const callback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            this.setNavItemsTabindex();
        }
        // } else if (mutation.type === 'childList') {
        // }
      }
    };
    this.observer = new MutationObserver(callback);
    this.observer.observe(targetNode, config);
    targetNode.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            const focusedElement = document.activeElement;
            focusedElement.click();
        }
    });
    document.getElementsByClassName('sidebar-minimizer')[0].setAttribute("tabindex", "0");
    const toastr_notification = document.getElementById("mini-toastr");
    toastr_notification.setAttribute('aria-live', 'assertive');
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadData() {
        this.search = this.$route.query;
        this.updateCommunityForAdvancedSearch();
        this.handleBlur();
        this.communityData = {};
        var fetchCommunity = '';
        // check navbar has a community data then skip this iteration
        var communityexists = nav.items.filter(item => item.name === 'Communities');
        if (communityexists.length) {
            var communityIndex = nav.items.findIndex(item => item.name === 'Communities');
            nav.items.splice(communityIndex, 1);
        }
        // display communities role wise
        if (this.authUser) {
            fetchCommunity = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
            .catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        }
        if (fetchCommunity.data && fetchCommunity.data.length) {
            this.allCommunities = fetchCommunity.data;
            this.communityData = { name: 'Communities', icon: 'icon-globe', children: [],url: '#'};
            fetchCommunity.data.map(community => {
                var navItem = {};
                navItem.name = community.name;
                navItem.url = `/dashboard/communities/${community._id}`;
                navItem.icon = '';
                this.communityData.children.push(navItem);
            });
            nav.items.push(this.communityData);
        }else{
            this.communityData = { name: 'Communities', icon: 'icon-globe', children: [],url: '#'};
            nav.items.push(this.communityData);
        }
        if (this.authUser) {
            // this.reportData = {
            //      name: 'Reports', icon: 'cui-dashboard',url: '/reports',
                //  children: [
                //     {
                //         name: 'Interaction', url: '/reports/engagement-detail', icon: ''
                //     },
                //     {
                //         name: 'Opt-out Report', url: '/reports/member-opt-out', icon: ''
                //     },
                //     {
                //         name: 'Overdue Requests', url: '/reports/overdue-request', icon: ''
                //     },
                //     {
                //         name: 'Invalid Emails', url: '/reports/invalid-emails', icon: ''
                //     },
                //     {
                //         name: 'No Follow Up', url: '/reports/no-follow-up', icon: ''
                //     },
                //     {
                //         name: 'Donor Engagement', url: '/reports/donor-engagement', icon: ''
                //     }
                // ]
            // };
        // this.navItems.push(this.reportData);
        this.settingData = { name: 'Settings', icon: 'cui-cog',url: '#', children: [{
            name: 'Member Rank', url: '/reports/member-rank', icon: ''
        }, {
            name: 'Scoring Model', url: '/dashboard/engagement-score',icon: ''
        }, {
            name: 'Users', url: '/dashboard/users',icon: ''
        }]};

        // this.askAPeerBtn = {name: "Ask A Peer", url: "https://ask.protopia.co/peers", class: "btn ask-a-peer-btn", target:  "_blank", attributes: { target: '_blank'}};
        // this.navItems.push(this.askAPeerBtn);
        this.navItems.push(this.settingData);
    }
      this.navItems = [...this.navItems].concat(nav.items);
        var navItem = [];
        navItem[0] = nav.items[0];//dashboard
        navItem[1] = this.communityData;
        navItem[2] = nav.items[3];//Engagement[children{request,response,feedback}]
        navItem[3] = nav.items[4];//Managament
        navItem[4] = nav.items[5]; // Members
        navItem[5] = nav.items[1];//stream
        navItem[6] = nav.items[2]; //reports
        navItem[7] = nav.items[8]; // Resources
        navItem[8] = nav.items[6];//Library
        navItem[9] = nav.items[7]; // uploads
        if (this.authUser.role && this.authUser.role === 'SuperAdmin') {
          navItem[10] = this.settingData; //settings[children{Scoring Model,Users}]
        //   navItem[8] = this.askAPeerBtn; // ask a peer button
        } else {
          delete navItem[10];// setting not visible for admins.
        //   navItem[7] = this.askAPeerBtn; // ask a peer button
        }
        this.navItems = navItem;
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.authUser._id}/edit-profile`, {headers: {'x-access-token': this.token}}).then(response => {
            if(response.data.user) {
                this.userData = response.data.user;
                if (this.userData.role == 'SuperAdmin') {
                    this.isPriority = true;
                }
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        this.setNavItemsTabindex();
    },
    isMozilla() {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    },
    displayLoading: function(value) {
    //   this.$isLoading = value;
      this.$nextTick(() => {
          var app = document.getElementsByClassName('app');
        //   app[0].classList.toggle('loader');
      });
    },
    changeStatus: function() {
        this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.authUser._id}/update-priority-status`, {status: this.userData.download_report_priority}, {headers: {'x-access-token': this.token}}).then(response => {
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    searchData: async function() {
        if (this.searchTxt) {
            if (this.searchTxt.length > 2) {
                let query = `q=${this.searchTxt}`;
                if (this.$route.params.community_id) {
                    query = `q=${this.searchTxt}&&community=${this.$route.params.community_id}`;
                }
                this.$router.push(`/dashboard/search?${query}`);
            } else {
                this.showErrorMsg();
            }
        }
    },
    handleBlur() {
        this.isBtnDisabled = true;
        for (const [key, value] of Object.entries(this.search)) {
            if (value && value.length >= 2 && key != 'community') {
                this.isBtnDisabled = false;
                break;
            }
        }
    },
    openAdvancedSearchModal: async function() {
        this.advancedSearchModal = true;
    },
    advancedSearch() {
        const qs = Object.keys(this.search).map(key => `${key}=${this.search[key]}`).join('&');
        if (this.$route.name == "Advanced Search Members") {
            this.$router.replace({query: this.search }).catch((err)=>{});
        } else {
            this.$router.push(`/engagement/members/advanced-search?${qs}`).catch((err)=>{});
        }
        this.advancedSearchModal = false;
    },
    clearSearch() {
        this.search = {};
        this.updateCommunityForAdvancedSearch();
        this.handleBlur();
    },
    updateCommunityForAdvancedSearch() {
        if (this.$route.params.community_id) {
            this.search.community = this.$route.params.community_id;
        } else {
            this.search.community = this.$route.query.community ? this.$route.query.community : null;
        }
    },
    handleUrlChange() {
      this.$nextTick(() => {
        const navDropdowns = document.querySelectorAll('.nav-item.nav-dropdown');
        navDropdowns.forEach(navDropdown => {
          const childElementWithClasses = navDropdown.querySelector('.router-link-exact-active.open');
          if (childElementWithClasses) {
            navDropdown.classList.add('open');
          }
            //   else {
            //     navDropdown.classList.remove('open');
            //   }
        });
        this.$helpers.setPaginationAnnouncement();
        this.$helpers.setLabelForSettingIcons();
        this.$helpers.setRoleButtonForSettingMenu();
      });
    },
    setNavItemsTabindex() {
        this.$nextTick(() => {
            const collapsibleNavItems = document.querySelectorAll(".nav-item.nav-dropdown");
            this.elements = collapsibleNavItems;
            const setTabIndex = (item) => {
                let firstChild = item.firstElementChild;
                firstChild.setAttribute("tabindex", "0");
                firstChild.setAttribute("role", "button");
                const childElements = item.querySelectorAll('a.nav-link');
                if (!item.classList.contains('open')) {
                    firstChild.setAttribute("aria-expanded", 'false');
                    childElements.forEach(child => {
                        child.setAttribute('tabindex', '-1');
                    });
                } else {
                    firstChild.setAttribute("aria-expanded", 'true');
                    childElements.forEach(child => {
                        child.setAttribute('tabindex', '0');
                    });
                }
            };
            collapsibleNavItems.forEach(item => setTabIndex(item));
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setTabIndex(mutation.target);
                }
                });
            });
            collapsibleNavItems.forEach(item => {
                observer.observe(item, {
                attributes: true,
                attributeFilter: ['class']
                });
            });
        });
    },
    setAttributesToCswitch() {
        const Cswitch = document.querySelectorAll('.download-priority-report-switch .switch-slider');
        const keypressHandler = function(event) {
            if (event.key === "Enter") {
                const switchElement = event.target;
                this.userData.download_report_priority = !this.userData.download_report_priority;
                if (this.userData.download_report_priority) {
                    switchElement.setAttribute('aria-checked', 'true');
                } else {
                    switchElement.setAttribute('aria-checked', 'false');
                }
            }
        };

        for (let i = 0; i < Cswitch.length; i++) {
            const switchElement = Cswitch[i];
            switchElement.removeEventListener('keypress', switchElement.keypressHandler);
            const boundKeypressHandler = keypressHandler.bind(this);
            switchElement.addEventListener('keypress', boundKeypressHandler);
            switchElement.keypressHandler = boundKeypressHandler;
            switchElement.setAttribute('role', 'switch');
            switchElement.setAttribute('tabindex', '0');
            switchElement.setAttribute('aria-live', 'polite');

            if (this.userData.download_report_priority) {
                switchElement.setAttribute('aria-checked', 'true');
            } else {
                switchElement.setAttribute('aria-checked', 'false');
            }
        }
    }

  }
}
</script>
