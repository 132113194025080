<template>
  <div>
    <b-card>
        <template slot="header">
            <h4 class="mt-2 custom-card-header-text">Tags by Occurrence</h4>
            <ul v-if="communities.length>1" class="list-inline float-right">
                <li class="list-inline-item">
                    <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                        <option :value="null" selected disabled>Community</option>
                        <option value="all">All</option>
                        <option v-for="community in communities" v-bind:value="community._id">{{community.name}}</option>
                    </b-form-select>
                </li>
            </ul>
        </template>
        <b-card-body>
            <div class="toverflow-unset">
                <v-client-table v-bind:data="topTags" v-bind:columns="columns" :options="options" id="dataTable" class="top_tag">
                    <span slot="tag" slot-scope="props" class="display-link" @click="tagRequest(props.row.tag)" v-if="props.row.tag">
                        {{props.row.tag}}
                    </span>
                    <span slot="tag" slot-scope="props" v-else>
                        {{'N/A'}}
                    </span>
                </v-client-table>
            </div>
        </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios'
import { exportCSV } from '@/assets/js/common';
import { setRequestStatus } from '@/assets/js/set-status';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';

export default {
    name: 'tags_by_occurrence',
    data() {
        return {
            communities: [],
            selected: null,
            topTags: [],
            columns: ['tag', 'count'],
            options: {
                sortable: ['tag', 'count'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            token: ''
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    async created() {
        if (this.$route.query.community_id) {
            this.selected = this.$route.query.community_id;
        }
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if(user) {
            this.authUser = JSON.parse(user);
        }
            await this.getCommunity();
            await this.loadData();
    },
    methods: {
        loadData() {
            let url = '';
            if (this.selected) {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/top-tags/?community_id=${this.selected}`;
            } else {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/top-tags`;
            }
            this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
                this.topTags = response.data;
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
			this.$helpers.setTabindexOnTableCells();
        },
        async getCommunity() {
            const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
            .catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.communities = data;
            if(this.$route.params.community_id) {
                var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
                if(selectedCommunity.length) {
                    this.selected = selectedCommunity[0]._id;
                }
            }
        },
        filterByCommunity: async function(value) {
            const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/top-tags/?community_id=${value}`, {headers: {'x-access-token': this.token}})
            .catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.topTags = data;
        },
        tagRequest: function(tag) {
            window.open((`/engagement/requests/?tag=${tag}`), '_blank');
        }
    }
}
</script>
