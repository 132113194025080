<template>
  <div>
  <b-card class="admins-list">
    <template slot="header" v-if="showAddBtn">
      <h3>Resources</h3>
      <ul class="list-inline float-right header_table ">
        <li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
        <li v-if="communities.length>1" class="list-inline-item">
          <b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
            <option :value="null" selected disabled>Community</option>
            <option value="all">All</option>
            <option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
          </b-form-select>
        </li>
        <li v-if="circles.length>=1" class="list-inline-item place-center"><label for="circleFilter" class="custom-card-header-filter label-bottom-0 ">Circle:</label></li>
        <li v-if="circles.length>=1" class="list-inline-item">
          <b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
            <option :value="null" selected disabled>Circle</option>
            <option value="all">All</option>
            <option value="none">No Circle</option>
            <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
          </b-form-select>
        </li>
        <b-button variant="dropbox tab-focus ml-10" v-on:click="$router.push('resources/create')">Add Resource</b-button>
      </ul>
    </template>
    <b-card-body>
      <v-client-table v-bind:data="resources" v-bind:columns="columns" :options="options" id="dataTable" class="resource-list capitalize">
          <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.url)"> {{props.row.name}} </span>
          <span slot="action" slot-scope="props">
            <b-dropdown variant="btn btn-dropbox btn-sm tab-focus" right>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item v-on:click="$router.push(`/dashboard/resources/${props.row._id}`)">Edit</b-dropdown-item>
              <b-dropdown-item v-on:click="deleteResource(props.row._id)">Remove</b-dropdown-item>
            </b-dropdown>
          </span>
      </v-client-table>
    </b-card-body>
  </b-card>
</div>
</template>

<script>
import VueNotifications from 'vue-notifications'

export default {
  name: 'Resources',
  data() {
      return {
          showAddBtn: true,
          resources: [],
          communities: [],
          circles: [],
          selected: "all",
          selectedCircle: "all",
          serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/resources`,
          token: localStorage.getItem('token'),
          columns: ['name', 'description', 'type', 'roles', 'community', 'circle', 'action'],
          options: {
            sortable: ['name', 'description'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
              chunk: 5,
              edge: false,
              nav: 'scroll'
            }
          },
          shortToNameMap: {}
      }
  },
  notifications: {
      showSuccessMsg: {
          type: VueNotifications.types.success
      }
  },
  async created() {
      let authUser = JSON.parse(localStorage.getItem('authUser'));
      if (authUser && authUser.role === 'Admin') {
          this.columns = ['name', 'description'];
          this.showAddBtn = false;
      }
      await this.getCommunities();
      await this.loadData();

  },
  methods: {
    async loadData() {
      this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/community/${this.selected}/resources`, {headers: {'x-access-token': this.token}}).then(async response => {
        if (response.data.resources) {
          this.resources = response.data.resources;
          this.resources = this.resources.map(resource => {
              resource.community = resource.community.name;
              return resource;
          });  
          this.communities.forEach(community => {
              if (community.additionalParams && community.additionalParams.length > 0) {
                  community.additionalParams.forEach(param => {
                      if (param != null && param.short && param.name) {
                          this.shortToNameMap[param.short] = param.name;
                      }
                  });
              }
          });
          this.resources = response.data.resources.map(resource => ({
              ...resource,
              circle: this.shortToNameMap[resource.circle] || resource.circle
          }));    
        }
      }).catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.$helpers.setTabindexOnTableCells();
    },
    async getCommunities() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
      this.communities = data;
      if (this.$route.params.community_id) {
        const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
        if (selectedCommunity.length <= 1) {
          this.selected = selectedCommunity[0]._id;
        }
      } else {
        if (this.communities.length <= 1) {
          this.selected = this.communities[0]._id;
        }
      }
      this.filterByCommunity();
      this.$helpers.setTabindexOnTableCells();
    },
    filterByCommunity: async function () {
      this.selectedCircle = "all";
      if (this.selected == "all") {
        this.circles = [];
        this.loadData();
      } else {
        this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
        this.circles = this.circles
            .filter(circle => circle != null && circle.name != undefined && circle.name != null && circle.name != '')
            .sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }
                return 0; 
          });
        this.loadData();
      }
    },
    filterByCircle: async function () {
      try {
        let url;
        if (this.selectedCircle === "all") {
          url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/community/${this.selected}/resources`;
        } else {
          url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/community/${this.selected}/resources?circle=${this.selectedCircle}`;
        }
        
        const response = await this.axios.get(url, { headers: { 'x-access-token': this.token } });
        if (response.data.resources) {
          this.resources = response.data.resources.map(resource => ({
            ...resource,
            community: resource.community.name,
            circle: this.shortToNameMap[resource.circle] || resource.circle
          }));
        }
      } catch (error) {
        console.error('Error fetching resources:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      }
    },

    deleteResource: function(id) {
      this.axios.delete(`${process.env.VUE_APP_API_BASE_URL}/dashboard/resource/${id}/remove`, {headers: {'x-access-token': this.token}}).then(async response => {
        if(response.data.success) {
          this.loadData();
          this.showSuccessMsg({message: 'Resource has been removed'})
          this.announceMessage("Resource has been removed");
        }

      }).catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
    },
    redirectUrls: function(link) {
      window.open(link, '_blank');
    },
    announceMessage(message) {
        const liveRegion = document.createElement('div');
        liveRegion.setAttribute('role', 'alert');
        liveRegion.setAttribute('aria-live', 'polite');
        liveRegion.innerText = message;
        document.body.appendChild(liveRegion);
        setTimeout(() => {
          liveRegion.remove();
        },1000)
    },
  }
}
</script>
