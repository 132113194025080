<template>
    <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header="Edit Library">
                        <b-card-body>
                            <b-form v-on:submit.prevent="UpdateLibrary">
                                <b-form-group
                                label="Name of library:"
                                label-for="library_name"
                                >
                                    <b-form-input
                                    type="text"
                                    name="library_name"
                                    v-model="library.name"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('library_name')"
                                    placeholder="Enter library name">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('library_name')">
                                    {{errors.first('library_name')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Description of library:"
                                label-for="description"
                                >
                                    <b-form-textarea
                                    name="description"
                                    v-model="library.description"
                                    rows="3"
                                    max-rows="6"
                                    v-validate="{ required: true, max:2000 }"
                                    :state="validateState('description')"
                                    placeholder="Enter library description">
                                    </b-form-textarea>
                                    <b-form-invalid-feedback v-if="errors.has('description')">
                                    {{errors.first('description')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="URL of library:"
                                label-for="email"
                                >
                                    <b-form-input
                                    type="text"
                                    name="url"
                                    v-model="library.url"
                                    v-validate="{ required: true, regex: /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/ }"
                                    :state="validateState('url')"
                                    placeholder="Enter URL of library"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('url')">
                                    {{errors.first('url')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-button type="submit" variant="primary tab-focus">Update</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'EditLibrary',
    data() {
        return {
            token: localStorage.getItem('token'),
            libraryId: '',
            library: {}
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        if (authUser && authUser.role === 'Admin') {
            this.$router.push('/');
        }
        if(!this.$route.params.library_id) {
            this.$router.push('/');
        }
        this.libraryId = this.$route.params.library_id;
        this.loadData();
        const dict = {
            custom: {
                library_name: {
                    required: "Please enter library name.",
                    max: "library name cannot be more than 60 characters.",
                },
                description: {
                    required: "Please enter library description.",
                    max: "library description cannot be more than 2000 characters.",
                },
                url: {
                    required: "Please enter URL.",
                    regex: "Please enter valid URL"
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        async loadData() {
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/library/${this.libraryId}`, {headers: {'x-access-token': this.token}}).then(response => {
                if(response.data.library) {
                    this.library = response.data.library;
                }
            }).catch(err => {
                console.log(err);
                // if (err.response.status === 401) {
                //     localStorage.removeItem('authUser');
                //     this.$router.push('/login');
                // }
            });
        },
        UpdateLibrary() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    let payload = {
                        name: this.library.name,
                        description: this.library.description,
                        url: this.library.url
                    }
                    this.axios.put(`${process.env.VUE_APP_API_BASE_URL}/dashboard/library/${this.libraryId}/update`, payload, {headers: {'x-access-token': this.token}}).then(async response => {
                        this.$router.push('/dashboard/library');
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        }
    }
}
</script>
