<template>
  <div>
    <b-card>
        <template slot="header">
            <h4 class="mt-2 custom-card-header-text">Recent requests with "{{$route.query.tag | capitalize}}"</h4>
            <ul class="list-inline float-right">
                <li v-if="communities.length>1" class="list-inline-item">
                    <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                        <option :value="null" selected disabled>Community</option>
                        <option value="all">All</option>
                        <option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{community.name}}</option>
                    </b-form-select>
                </li>
                <li class="list-inline-item">
                    <button type="button" aria-label="Export" aria-live="assertive" @click="exportData()" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                </li>
            </ul>
        </template>
    </b-card>
    <b-row>
        <b-col md="12">
            <div class="card">
                <div class="card-body">
                    <!-- <h3>Requests</h3> -->
                    <template v-if="recentRequests.length">
                        <div v-for="(recentRequest, key) in recentRequests" :key="recentRequest._id">
                            <label class="ask-header m-top-20">Subject{{key+1}}</label>
                            <input class="form-control" v-model.trim="recentRequest.subject"/>

                            <label class="ask-header m-top-20">Description{{key+1}}</label>
                            <textarea rows="14" class="form-control description remove-box-shadow font-16" v-model.trim="recentRequest.text"></textarea>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center">
                            No Records Found
                        </div>
                    </template>
                </div>
            </div>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios'
import { exportCSV } from '@/assets/js/common';
import { setRequestStatus } from '@/assets/js/set-status';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';

export default {
    name: 'requests',
    data() {
        return {
            requests: [],
            communities: [],
            selected: null,
            columns: [ 'community', 'date', 'member', 'status', 'Sent', 'Opened', 'Clicked', 'Accepted', 'Declined', 'Referred','Feedback', 'action'],
            token: '',
            recentRequests: {},
            isRequestBlank: false,
            options: {
                headings: {
                    Sent: function(h) { return <span data-title="Advisors that were sent the request">S</span>},
                    Opened: function(h) { return <span data-title="Advisors that opened the request">O</span>},
                    Clicked: function (h) {return <span data-title="Advisors that clicked within the request email">C</span>},
                    Accepted: function(h) { return <span data-title="Advisors that answered the request">A</span>},
                    Declined: function(h) { return <span data-title="Advisors that declined the request">D</span>},
                    Referred: function(h) { return <span data-title="Referrals made by advisors">R</span>},
                    Feedback: function(h) { return <span data-title="Feedback left on request and responses.">F</span>}
                },
                sortable: ['date', 'member', 'status','Sent','Opened', 'Clicked', 'Accepted', 'Declined', 'Referred', 'Feedback'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                },
                requestFunction: async function (data) {
                    if (this.$route.query.tag) {
                        data.tag = this.$route.query.tag;
                    }
                    return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},   params: data}).catch(function (e) {
                        if (e.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    }.bind(this));
                },
                responseAdapter({data}) {
                    return {
                        data : data.requestData,
                        count: data.askCount
                    }
                },
                filterable: true
            },
            serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/get-recent-request-with-tag`
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    notifications: {
        showWarnMsg: {
            type: VueNotifications.types.warn,
            message: "No data found for export file"
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        await this.getCommunity();

        this.getRecentData();
        document.title = 'Recent requests with ' + this.$route.query.tag
    },
    methods: {
        async getRecentData() {
            if (this.$route.query.tag && this.$route.query.tag != '') {
                await this.axios.get(`${this.serverURL}/?tag=${this.$route.query.tag}`,
                        { headers: {'x-access-token': this.token } }
                    )
                    .then(data => {
                        if (data.data) {
                            this.recentRequests = data.data
                        }
                    })
                    .catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
            } else {
                this.isRequestBlank = true;
            }
        },
        checkData(value) {
            if(value) { return value; } else { return 'N/A';}
        },
        async getCommunity() {
            const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
            .catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.communities = data;
            if(this.$route.params.community_id) {
                const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
                if(selectedCommunity.length) {
                    this.selected = selectedCommunity[0]._id;
                }
            }
        },
        filterByCommunity: async function(value) {
            this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${value}/request/get-recent-request-with-tag`;
            this.selected = value;
            this.getRecentData();
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        exportData: async function() {

            if (this.$route.query.tag) {
                if (this.recentRequests.length) {
                    await exportCSV(this.recentRequests.slice(), 'tagsRequested', [], ['date']);
                } else {
                    this.showWarnMsg();
                }
            } else {
                this.showWarnMsg();
            }
        },
        redirectUrls: function(path) {
            window.open((`/engagement/members/${path}`), '_blank');
        }
    }
}
</script>
