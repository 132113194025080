import auth0 from 'auth0-js'
import EventEmitter from 'eventemitter3'
import router from './../router'
import axios from 'axios'

export default class AuthService {
	authenticated = this.isAuthenticated()
	authNotifier = new EventEmitter()

	constructor() {
		this.login = this.login.bind(this)
		this.setSession = this.setSession.bind(this)
		this.logout = this.logout.bind(this)
		this.isAuthenticated = this.isAuthenticated.bind(this)
	}

	auth0 = new auth0.WebAuth({
		domain: process.env.VUE_APP_AUTH0_DOMAIN,
		clientID: process.env.VUE_APP_AUTH0_CLIENTID,
		redirectUri: process.env.VUE_APP_REDIRECT_URL,
		responseType: 'token id_token',
		scope: 'openid profile email'
	})

	login() {
		this.auth0.authorize()
	}

	handleAuthentication() {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult)
			} else if (err) {
				this.addGoogleBucket(false, err, err.error);
				console.log(err);
				localStorage.clear();
				router.replace('UhOh')
				alert(`Error: ${err.error}. Check the console for further details.`)
			}
		})
	}

	setSession(authResult) {
		// Set the time that the access token will expire at
		let expiresAt = JSON.stringify(
			authResult.expiresIn * 1000 + new Date().getTime()
		)
		localStorage.setItem('access_token', authResult.accessToken)
		localStorage.setItem('id_token', authResult.idToken)
		localStorage.setItem('expires_at', expiresAt)
		//Get the user info, then save that as authResult for access in components
		this.auth0.client.userInfo(authResult.accessToken, async (err, user) => {
			if (err) {
				this.addGoogleBucket(false, err, 'User not find using access token');
				localStorage.clear();
				console.log("Uh oh", err);
			} else {
				//Get a Protopia Member from the Auth0 user
				// console.log("Got user info", user);
				await axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/login`, {member: user}).then(response => {
					if (response.data.success) {
						this.addGoogleBucket(true, user, 'Login successful');
						this.authNotifier.emit('authChange', { authenticated: true })
						localStorage.setItem('token', response.data.token);
				        localStorage.setItem('authUser', JSON.stringify(response.data.user));
				        let redirectUrl = localStorage.afterLogin;
				        if (typeof localStorage.getItem('afterLogin') == 'string'){
				            localStorage.removeItem('afterLogin');
							router.replace(redirectUrl)
				        }else {
							router.replace('dashboard')
				        }
				    } else if (response.data.success == false) {
						this.addGoogleBucket(false, user, response.data.message);
						localStorage.clear();
						alert(response.data.message);
						router.replace('login');
				    }
				});
			}
		});
	}

	logout() {
		// Clear access token and ID token from local storage
		localStorage.removeItem('access_token')
		localStorage.removeItem('id_token')
		localStorage.removeItem('expires_at')
		localStorage.removeItem("authUser");
		this.userProfile = null
		this.authNotifier.emit('authChange', false)
		// navigate to the home route
		router.replace('home')
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// access token's expiry time
		let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
		return new Date().getTime() < expiresAt
	}

	addGoogleBucket(isUser, responseObj, message) {
		let data = {
			isUser: isUser,
			response: responseObj,
			message: message
		}
		axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/add-log-google-bucket`, data).then(response => {});
	}
}
