<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['chartData', 'options', 'counterData', 'field'],
  data() {
      return {
          chart1: this.chartData,
          counter: Number(this.counterData),
          checkDataChange: false
      }
  },
  watch: {
      "$route": {
          handler: function(newVal, oldVal) {
              this.counter = 0;
          }
      },
      "field": {
          handler: function(newVal, oldVal) {
              this.counter = 0;
          }
      },
      "chart1": {
          handler: function(newVal, oldVal) {
              if (this.counter == 0) {
                  this.counter = 1;
                  this.checkDataChange = this.getRandomInt();
              }
          },
          deep: true
      },
      "checkDataChange": {
          handler: function(newVal, oldVal) {
              if (this.counter == 1) {
                  this.updateChart();
              }
          }
      }
  },
  methods: {
      updateChart: function() {
          if (this.$data._chart) {
              this.$data._chart.destroy();
          }
          this.renderChart(this.chart1, this.options);
          this.checkDataChange = false;
      },
      getRandomInt () {
          return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
  }
}
</script>
