<template>
    <div>
        <b-card>
            <template slot="header">
                <h4 class="mt-2 custom-card-header-text">Email Engagement Report</h4>
            </template>
            <h4 class="text-center">Please wait...</h4>
        </b-card>
  </div>
</template>

<script>
import { exportCSV } from '@/assets/js/common';
import axios from 'axios'
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';

export default {
    name: 'email-engagement',
    data() {
        return {
            token: ''
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success,
            message: 'We are now preparing your export and will send you an email once it is available for download.',
            timeout: 3000
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        this.exportData();
    },
    methods: {
        exportData: function() {
            this.showSuccessMsg();
            this.$router.push('/dashboard');
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/report/email-engagement-report`, {headers: {'x-access-token': this.token}}).then(async response => {
                // if (response.data && response.data.length) {
                //     await exportCSV(response.data.slice(), 'email_engagement', [], [ 'date', 'email_sent', 'email_open', 'email_click', 'response_date', 'pass_date']);
                
                // }
                // this.$router.push('/dashboard');
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        }
    }
}
</script>
