<template>
    <div>
        <b-card>
            <template slot="header">
                <h4 class="mt-2 custom-card-header-text">Request Engagement <img src="img/tooltip.png" v-b-tooltip.hover.bottom title="Request Engagement shows you what percentage of advisors engage when a request is matched to them (30-day average)." alt="tooltip" height="12"/></h4>
                <ul class="list-inline float-right">
                    <li class="list-inline-item" aria-hidden="true">
                        <button type="button" aria-label="Export" aria-live="assertive" @click="exportData()" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                    </li>
                </ul>
            </template>
            <b-card-body>
                <v-client-table v-bind:data="requestEngagement" v-bind:columns="columns" :options="options" id="dataTable" class="request-engagement">
                </v-client-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { exportCSV } from '@/assets/js/common';

export default {
    name: 'request-engagement',
    data() {
        return {
            requestEngagement: [],
            columns: ['date', 'first_email_advisor', 'first_reminder_advisor', 'second_reminder_advisor'],
            token: localStorage.getItem('token'),
            options: {
                headings: {
                    first_email_advisor: 'Request Email',
                    first_reminder_advisor: '1st Reminder',
                    second_reminder_advisor: '2nd Reminder'
                },
                sortable: ['date', 'first_email_advisor', 'first_reminder_advisor', 'second_reminder_advisor'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                }
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request_engagement_by_date`;
            if (this.$route.query.community_id) {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request_engagement_by_date/?community_id=${this.$route.query.community_id}`
            }
            this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data.length) {
                    this.requestEngagement = response.data;
                }
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
			this.$helpers.setTabindexOnTableCells();
        },
        exportData: async function() {
            if (this.requestEngagement.length) {
                await exportCSV(this.requestEngagement.slice(), 'request_engagement', [], ['date']);
            }
        }
    }
}
</script>
