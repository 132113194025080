<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { random } from '@/shared/utils'
import EventBus from '@/assets/js/EventBus'
export default {
  extends: Line,
  // mixins: [reactiveProp],
  props: ['chartData', 'options', 'counterData', 'field'],
  data() {
    return {
      chart1: this.chartData,
      counter: Number(this.counterData),
      checkDataChange: false
    }
  },
  watch: {
    "$route": {
      handler: function(newVal, oldVal) {
        this.counter = 0;
      }
    },
    "field": {
      handler: function(newVal, oldVal) {
        this.counter = 0;
      }
    },
    "chart1": {
      handler: function(newVal, oldVal) {
        if (this.counter == 0) {
          this.counter = 1;
          this.checkDataChange = this.getRandomInt();
        }
      },
      deep: true
    },
    "checkDataChange": {
      handler: function(newVal, oldVal) {
        if (this.counter == 1) {
          this.updateChart();
        }
      }
    }
  },
  methods: {
    updateChart: function() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }
      this.renderChart(this.chart1, this.options);
      this.checkDataChange = false;
      const brandSuccess = getStyle('--success') || '#4dbd74'
      const brandInfo = getStyle('--info') || '#20a8d8'
      const brandDanger = getStyle('--danger') || '#f86c6b'
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>
