<template>
  <div>
    <b-card>
        <template slot="header">
            <h4 class="mt-2 custom-card-header-text">Incomplete Requests</h4>
            <ul class="list-inline float-right header_table">
              <li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
              <li v-if="communities.length>1" class="list-inline-item">
                  <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                      <option :value="null" selected disabled>Community</option>
                      <option value="all">All</option>
                      <option v-for="community in communities" v-bind:value="community._id">{{community.name}}</option>
                  </b-form-select>
              </li>
              <li v-if="circles.length>1" class="list-inline-item place-center"><label for="circleFilter" class="custom-card-header-filter label-bottom-0">Circle:</label></li>
              <li v-if="circles.length>1" class="list-inline-item">
                <b-form-select v-model="selectedCircle" @change="filterByCircle" class="custom-card-header-filter" id="circleFilter">
                  <option :value="null" selected disabled>Circle</option>
                  <option value="all">All</option>
							<option value="none">No Circle</option>
                  <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>1" >{{ circle.name}}</option>
                </b-form-select>
              </li>
              <li>
                <div class="row-bak d-flex-bak align-items-center-bak">
                  <datepicker @changed="dateFilter" 
                  style="padding-right: 10px;" label="Date Range"></datepicker>
                </div>
              </li>
              <li class="list-inline-item">
              <button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" title="Download incomplete request data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
              <button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
            </li>
            </ul>
        </template>
        <b-card-body>
        <v-client-table v-bind:data="requests" v-bind:columns="columns" :options="options" id="dataTable" class="requests-overdue-table">
          <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
            {{props.row.member}}
          </span>
          <span slot="member" slot-scope="props" v-else-if = "props.row.member">
            {{props.row.member}}
          </span>
          <span slot="subject" slot-scope="props">
            {{props.row.subject ? props.row.subject : 'N/A'}}
          </span>
          <span slot="date" slot-scope="props" v-if="props.row.date">
            {{ props.row.date | momentDate }}
          </span>
          <span slot="date" v-else>N/A</span>
          <span slot="action" slot-scope="props" class="action-width">
            <ul class="list-inline">
              <li class="list-inline-item">
                <b-button v-b-tooltip.hover.bottom aria-label="View the request" class="notification-btn tab-focus" variant="dropbox" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                    <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                    <i class="icon-eye"></i>
                </b-button>
              </li>
            </ul>
          </span>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios'
import { exportCSV } from '@/assets/js/common';
import { setRequestStatus } from '@/assets/js/set-status';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from './components/datePicker';

export default {
  name: 'requests',
  components: {
		datepicker,
	},
  data() {
    return {
      requests: [],
      communities: [],
			circles: [],
			selected: "all",
			selectedCircle: "all",
      selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
      columns: [ 'member', 'community', 'circleName', 'date', 'subject', 'intro', 'content', 'closing'],
      token: '',
      options: {
          headings: {
            Sent: 'S',
            Opened: 'O',
            Accepted: 'A',
            Declined: 'D',
            Feedback: 'F',
            circleName: 'Circle'
          },
          sortable: ['member', 'community', 'circleName', 'date', 'subject', 'intro', 'content', 'closing'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
              chunk: 10,
              nav: 'fixed'
          },
          customSorting: {
            date: function (ascending) {
                return function (a, b) {
                    var isValid = function(date) {
                      return !isNaN(isFinite(date))
                    }
                    var lastA = a.date;
                    var lastB = b.date;
                    if (ascending && isValid(lastA) && isValid(lastB)) {
                      return new Date(lastA) - new Date(lastB);
                    }
                    if (!ascending && isValid(lastA) && isValid(lastB)) {
                      return lastA <= lastB ? 1 : -1;
                    }
                }
            }
          }
      },
      incrementId: 0,
			exportBtn: true,
			processBtn: false
    }
  },
  notifications: {
		showDownloadingMsg: {
			type: VueNotifications.types.success,
			message: "Export request sent.  Data is now downloading."
		},
      showWarnMsg: {
          type: VueNotifications.types.warn,
          message: "No data found for export file"
      }
  },
  async created() {
    this.token = localStorage.getItem('token');
    await this.getCommunity();
    await this.loadData();
  },
  methods: {
    dateFilter(res) {
			console.log("dates [selectedDates]:", res);
			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			this.loadData();
		},
    loadData() {
      var url = '';
      if(this.selected) {
        url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.selected}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
      } else {
        url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
      }
      this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
        console.log('response', response)
        if (response.data.asks) {
          this.requests = response.data.asks;
        }
      }).catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.$helpers.setTabindexOnTableCells();
    },
    checkData(value) {
      if(value) { return value; } else { return 'N/A';}
    },
    async getCommunity() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
      .catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.communities = data;
      if (this.$route.params.community_id) {
				const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
				if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
    },
    filterByCommunity: async function(value) {
        this.selectedCircle = "all";
        if (this.selected == "all") {
          this.circles = [];
          const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}})
          .catch(err => {
              if (err.response.status  === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          if (data.asks) {
            this.requests = data.asks;
          }
        } else {
          this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
          this.circles.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.selected}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}})
          .catch(err => {
              if (err.response.status  === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          if (data.asks) {
            this.requests = data.asks;
          }
        }
    },
		filterByCircle: async function (value) {
			if (this.selectedCircle == "all") {
				const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.selected}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}})
          .catch(err => {
              if (err.response.status  === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          if (data.asks) {
            this.requests = data.asks;
          }
			} else {
				const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.selected}&circle=${value}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}})
          .catch(err => {
              if (err.response.status  === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          if (data.asks) {
            this.requests = data.asks;
          }
			}
		},
    setStatus: function(status) {
        return setRequestStatus(status);
    },
    exportData: function() {
				this.showDownloadingMsg();
				this.exportBtn = false;
				this.processBtn = true;
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.selected}&circle=${this.selectedCircle}&export=csv&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data.asks && response.data.asks.length) {
                exportCSV(response.data.asks.slice(), 'requests_incomplete', ['title','requestId','memberId', 'member'], ['date']);
                this.exportBtn = true;
                this.processBtn = false;
            } else {
                this.showWarnMsg();
                this.exportBtn = true;
                this.processBtn = false;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    openRequestInNewTab(path) {
      window.open((`/engagement/incomplete-requests/${path}`), '_blank');
    }
  }
}
</script>
