<template>
    <div>
    <b-card>
        <template slot="header">
            <h3 v-if="$route.query.q">Member Search "{{$route.query.q}}"</h3>
        </template>
        <b-card-body>
            <v-client-table v-bind:data="members" v-bind:columns="memberColumns" :options="memberOptions" id="dataTable" class="members-table">
                <span slot="community" slot-scope="props">
                    {{props.row.community ? props.row.community : 'N/A'}}
                </span>
                <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                    {{props.row.firstname}} {{props.row.lastname}}
                </span>
                <span slot="name" slot-scope="props" v-else>
                    {{props.row.firstname}} {{props.row.lastname}}
                </span>
                <span slot="email" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                    {{props.row.email}}
                </span>
                <span slot="email" slot-scope="props" v-else-if = "props.row.email">
                    {{props.row.email}}
                </span>
                <!-- <span slot="last_request" slot-scope="props" v-if="props.row.last_request_made">
                    {{ props.row.last_request_made | momentDate }}
                </span>
                <span slot="last_request" v-else>N/A</span> -->
                <span slot="action" slot-scope="props" class="res-req-action-width">
                    <ul class="list-inline mb-0 text-center">
                        <li class="list-inline-item">
                            <b-button v-b-tooltip.hover.bottom title="View the member" variant="dropbox tab-focus" size="sm" @click="$router.push(`/engagement/members/${props.row.memberId}`)"><i class="icon-eye"></i></b-button>
                        </li>
                        <li class="list-inline-item" v-if="props.row.status === 'Active' && props.row.email">
                            <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                <template slot="button-content">
                                    <i class="icon-settings"></i>
                                </template>
                                <b-dropdown-item @click="unsubscribeMember(props.row.email, props.row.communityId, props.row.memberId)">Unsubscribe Member</b-dropdown-item>
                                <b-dropdown-item @click="openEmailModal(props.row.email, props.row.memberId, props.row.communityId)" v-if="props.row.memberId && props.row.communityId && !props.row.email_address3">Edit Email</b-dropdown-item>
                            </b-dropdown>
                        </li>
                    </ul>
                </span>
            </v-client-table>
        </b-card-body>
    </b-card>
    <b-card>
        <template slot="header">
            <h3 v-if="$route.query.q">Request Search "{{$route.query.q}}"</h3>
        </template>
        <b-card-body>
            <v-client-table v-bind:data="requests" v-bind:columns="requestColumns" :options="requestOptions" id="dataTable" class="request-table">
                 <span slot="community" slot-scope="props">
                  {{props.row.community ? props.row.community : 'N/A'}}
                </span>
                <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                  {{props.row.member}}
                </span>
                <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                  {{props.row.member}}
                </span>
                <span slot="title" slot-scope="props">
                  {{props.row.title ? props.row.title : 'N/A'}}
                </span>
                <span slot="date" slot-scope="props" v-if="props.row.date">
                  {{ props.row.date | momentDate }}
                </span>
                <span slot="date" v-else>N/A</span>
                <span slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setStatus(props.row.status) == 'New'}, {'badge badge-primary': setStatus(props.row.status) == 'Active'}, {'badge-warning': setStatus(props.row.status) == 'In Review' || setStatus(props.row.status) == 'Pending'}, {'badge-primary-light': setStatus(props.row.status) == 'Closed'}, {'badge badge-primary': setStatus(props.row.status) == 'Tag' || setStatus(props.row.status) == 'Matching' || setStatus(props.row.status) == 'Reviewed'}]">
                  {{props.row.status ? setStatus(props.row.status) : 'N/A'}}
                </span>
                <span slot="action" slot-scope="props" class="action-width">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <b-button v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox" class="notification-btn tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                          <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                          <i class="icon-eye"></i>
                      </b-button>
                    </li>
                  </ul>
                </span>
            </v-client-table>
        </b-card-body>
    </b-card>
    <b-card>
        <template slot="header">
            <h3 v-if="$route.query.q">Response Search "{{$route.query.q}}"</h3>
        </template>
        <b-card-body>
            <v-client-table v-bind:data="responses" v-bind:columns="responseColumns" :options="responseOptions" id="dataTable" class="responses-table">
                <span slot="community" slot-scope="props">
                  {{props.row.community ? props.row.community : 'N/A'}}
                </span>
                <span slot="title" slot-scope="props">
                  {{props.row.title ? props.row.title : 'N/A'}}
                </span>
                <span slot="adviser" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                  {{props.row.adviser}}
                </span>
                <span slot="adviser" slot-scope="props" v-else-if="props.row.adviser">
                  {{props.row.adviser}}
                </span>
                <span slot="sent" slot-scope="props" v-if="props.row.sent">
                  {{ props.row.sent | momentDate }}
                </span>
                <span slot="sent" v-else>N/A</span>
                <span slot="status" v-if="props.row.isReferralGive == 'referred'" slot-scope="props" class="badge badge-warning">
                Referred
                </span>
                <span v-else slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setResponseStatus(props.row.status) == 'Accepted'}, {'badge-danger': setResponseStatus(props.row.status) == 'Declined'}, {'badge-warning': setResponseStatus(props.row.status) == 'In Review' || setResponseStatus(props.row.status, props.row) == 'Pending'}]">
                  {{props.row.status ? setResponseStatus(props.row.status) : 'N/A'}}
                </span>
                <span slot="followUp" slot-scope="props" class="capitalize" v-if="props.row.followUp">
                  {{ props.row.followUp }}
                </span>
                <span slot="action" slot-scope="props">
                  <b-button v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox" size="sm" class="notification-btn tab-focus" @click="openRequestInNewTab(props.row.requestId)">
                      <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                      <i class="icon-eye"></i>
                  </b-button>
                </span>
            </v-client-table>
        </b-card-body>
    </b-card>
    <b-card>
        <template slot="header">
            <h3 v-if="$route.query.q">Feedback Search "{{$route.query.q}}"</h3>
        </template>
        <b-card-body>
            <v-client-table v-bind:data="satisfactions" v-bind:columns="satisfactionColumns" :options="satisfactionOptions" id="dataTable" class="feedback-table">
                <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                    {{props.row.name}}
                </span>
                <span slot="name" slot-scope="props" v-else-if="props.row.name">
                    {{props.row.name}}
                </span>
                <span slot="received" slot-scope="props" v-if="props.row.received">
                    {{ props.row.received | momentDate }}
                </span>
                <span slot="received" v-else>N/A</span>
                <span slot="feedback" slot-scope="props" v-if="props.row.feedback">
                    {{props.row.feedback}}
                </span>
                <span slot="feedback" v-else>
                    N/A
                </span>
                <span slot="action" slot-scope="props">
                    <b-button v-b-tooltip.hover.bottom aria-label="View the request" class="notification-btn tab-focus" variant="dropbox" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                        <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                        <i class="icon-eye"></i>
                    </b-button>
                </span>
            </v-client-table>
        </b-card-body>
    </b-card>
    <b-modal id="email-modal" class="email-modal" size="md" v-model="emailModal" ok-title="Close" @ok="emailModal = false" title="Update Email">
        <b-card-body>
            <b-form v-on:submit.prevent="updateEmailSubmit">
                <b-form-group
                label="Email:"
                label-for="Email"
                >
                    <b-form-input
                    type="text"
                    name="email"
                    v-model="updateEmail"
                    v-validate="{ required: true, max:100, email: true }"
                    :state="validateState('email')"
                    placeholder="Enter email"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback v-if="errors.has('email')">
                    {{errors.first('email')}}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button type="submit" variant="primary">Update</b-button>
            </b-form>
        </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { setRequestStatus, setResponseStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications';

export default {
    name: 'Search',
    data() {
        return {
            token: localStorage.getItem('token'),
            members: [],
            memberColumns: ['community', 'name', 'email', 'engagementPoints','action'],
            memberOptions: {
                headings: {
                    engagementPoints: 'Engagement Score'
                },
                sortable: ['community', 'name', 'email', 'engagementPoints'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            emailModal: false,
            updateEmail: '',
            memberEmailOfId: '',
            communityId: '',
            requests: [],
            requestColumns: [ 'community', 'date', 'member', 'status', 'Sent', 'Opened', 'Clicked', 'Accepted',  'Declined', 'Referred','Feedback', 'action'],
            requestOptions: {
                headings: {
                    Sent: function(h) { return <span data-title="Advisors that were sent the request">S</span>},
                    Opened: function(h) { return <span data-title="Advisors that opened the request">O</span>},
                    Clicked: function (h) {return <span data-title="Advisors that clicked within the request email">C</span>},
                    Accepted: function(h) { return <span data-title="Advisors that answered the request">A</span>},
                    Declined: function(h) { return <span data-title="Advisors that declined the request">D</span>},
                    Referred: function(h) { return <span data-title="Feedback left on the request">R</span>},
                    Feedback: function(h) { return <span data-title="Members that followed up on responses">F</span>}
                },
                sortable: ['community', 'date', 'member', 'status','Sent','Opened', 'Clicked', 'Accepted', 'Declined', 'Referred', 'Feedback'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            responses: [],
            responseColumns: ['community', 'sent', 'adviser', 'request_Action', 'status', 'open', 'click', 'thankYou', 'followUp', 'action'],
            responseOptions: {
                headings: {
                    requestId: 'Request ID',
                    open: 'O',
                    click: 'C',
                    thankYou: 'TY',
                    followUp: 'FU'
                },
                sortable: ['sent', 'adviser', 'request_Action', 'status'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            satisfactions: [],
            satisfactionColumns: ['community', 'received', 'name', 'type', 'rating', 'feedback','action'],
            satisfactionOptions: {
                headings: {
                    requestId: 'Request ID'
                },
                sortable: [ 'community', 'received', 'name', 'type', 'rating', 'feedback'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            }
        }
    },
    notifications: {
        showErrorMsg: {
            type: VueNotifications.types.error
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        this.loadData();
    },
    watch: {
        "$route": "loadData"
    },
    methods: {
        loadData() {
            let query = `q=${this.$route.query.q}`;
            if (this.$route.query.community) {
                query = `q=${this.$route.query.q}&&community=${this.$route.query.community}`;
            }
            this.members = [];
            this.requests = [];
            this.responses = [];
            this.satisfactions = [];
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/search?${query}`, {headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data.member && response.data.member.length) {
                    this.members = response.data.member;
                }
                if (response.data.request && response.data.request.length) {
                    this.requests = response.data.request;
                }
                if (response.data.response && response.data.response.length) {
                    this.responses = response.data.response;
                }
                if (response.data.satisfaction && response.data.satisfaction.length) {
                    this.satisfactions = response.data.satisfaction;
                }
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        },
        redirectUrls: function(path) {
            window.open((`/engagement/members/${path}`), '_blank');
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        setResponseStatus: function(status) {
          return setResponseStatus(status);
        },
        unsubscribeMember: async function(email, communityId, memberId) {
            if (communityId) {
                const unsubscribe = {
                    unsubscribe:
                    {
                        email: email.toString(),
                        reason: '',
                        description: ''
                    },
                    community: communityId,
                    isMailSent: false
                }
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/unsubscribe`, unsubscribe).then(async response => {
                    let uniqueMembers = await _.remove(this.members, function(m) {
                        if (m && m.memberId == memberId) {
                            m.status = 'unsubscribed'
                        }
                        return m;
                    });
                    this.members = uniqueMembers;
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.showErrorMsg({message: 'Community not assign'});
            }
        },
        openEmailModal: function(email, memberId, communityId) {
            this.emailModal = true;
            this.updateEmail = '';
            this.memberEmailOfId = memberId;
            this.communityId = communityId;
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        updateEmailSubmit() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    let memberObj = {
                        email: this.updateEmail,
                        memberId: this.memberEmailOfId,
                        communityId: this.communityId
                    }
                    await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/update-email`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
                        this.emailModal = false;
                        this.loadData();
                        // this.$refs.membersTable.refresh();
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            })
        },
        openRequestInNewTab(path) {
            window.open((`/engagement/requests/${path}`), '_blank');
        }
    }
}
</script>
