export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary',
      }
    },
    {
      name: 'Stream',
      url: '/engagement/stream',
      icon: 'icon-puzzle',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Reports',
      url: '/dashboard/reports',
      icon: 'cui-dashboard',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Engagement',
      url: '#',
      icon: 'icon-speech',
      children: [
        {
          name: 'Requests',
          url: '/engagement/requests',
          icon: ''
        },
        {
          name: 'Responses',
          url: '/engagement/responses',
          icon: ''
        },
        {
          name: 'Feedback',
          url: '/engagement/feedback',
          icon: '',
        },
        {
          name: 'Referrals',
          url: '/engagement/referrals',
          icon: '',
        }
      ]
    },
    {
      name: 'Management',
      url: '#',
      icon: 'cil-book',
      children: [
        {
          name: 'Escalations',
          url: '/engagement/requests-overdue',
          icon: ''
        },
        {
          name: 'Request Approvals',
          url: '/engagement/requests-approval',
          icon: ''
        },
        {
          name: 'Response Approvals',
          url: '/engagement/responses-approval',
          icon: '',
        },
        {
          name: 'Incomplete Requests',
          url: '/engagement/incomplete-requests',
          icon: '',
        },
        {
          name: 'Rejected Requests',
          url: '/engagement/rejected-requests',
          icon: '',
        },
        {
          name: 'Rejected Responses',
          url: '/engagement/rejected-responses',
          icon: '',
        }
      ]
    },
    {
      name: 'Members',
      url: '/engagement/members',
      icon: 'icon-people',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Library',
      url: '/dashboard/library',
      icon: 'icon-people',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Uploads',
      url: '/dashboard/uploads',
      icon: 'icon-people',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Resources',
      url: '/dashboard/resources',
      icon: 'icon-people',
      wrapper: {
        element: '',
        attributes: {}
      }
    }
  ]
}
