<template>
  <div>
    <b-card>
      <template slot="header">
        <h4 class="mt-2 custom-card-header-text">Referral</h4>
        <ul class="list-inline float-right header_table">
            <li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
            <li v-if="communities.length>1" class="list-inline-item">
              <b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
                <option :value="null" selected disabled>Community</option>
                <option value="all">All</option>
                <option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
              </b-form-select>
            </li>
            <li v-if="circles.length>=1" class="list-inline-item place-center"><label for="circleFilter" class="custom-card-header-filter label-bottom-0">Circle:</label></li>
            <li v-if="circles.length>=1" class="list-inline-item">
              <b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
                <option :value="null" selected disabled>Circle</option>
                <option value="all">All</option>
							<option value="none">No Circle</option>
                <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
              </b-form-select>
            </li>
            <li>
              <div class="row-bak d-flex-bak align-items-center-bak">
                <datepicker @changed="dateFilter" 
                style="padding-right: 10px;" label="Date Range"></datepicker>
              </div>
            </li>
            <li class="list-inline-item">
                <button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()"  title="Download referral data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                <button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
            </li>
        </ul>
      </template>
      <b-card-body>
        <v-server-table :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" @loaded="onLoaded" class="referral-table">
          <span slot="date" slot-scope="props">
            {{ props.row.date | momentDate }}
          </span>
          <span slot="subject" slot-scope="props" v-if="props.row.requestId">
            {{ props.row.subject}}
          </span>
          <span slot="subject" v-else>N/A</span>
          <span slot="action" slot-scope="props">
            <b-button v-b-tooltip.hover.bottom :aria-label="'View' + (props.row.commentCount > 0 ? ' ' + props.row.commentCount:'') "  class="notification-btn" variant="dropbox tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                <i class="icon-eye"></i>
            </b-button>
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios';
import { exportCSV } from '@/assets/js/common';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from './components/datePicker';

export default {
  name: 'referrals',
  components: {
		datepicker,
	},
  data() {
    return {
      referrals: [],
      communities: [],
      circles: [],
      selected: "all",
      selectedCircle: "all",
      selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
      searchQuery: '',
      referralCount: 0,
      columns: ['community', 'circle', 'referral_from', 'date', 'referral_to', 'subject', 'action'],
      perPage: 25,
      token: '',
      options: {
        headings: {
          requestId: 'Request ID',
          referral_to: 'Referred To',
          referral_from: 'Referred From',
          date: 'Date of Referral'
        },
        sortable: [ 'community', 'circle', 'referral_from', 'date', 'referral_to', 'subject'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
            chunk: 10,
            nav: 'fixed'
        },
        requestFunction: async function (data) {
            this.$parent.searchQuery = data.query;
            return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},  params: data}).catch(function (e) {
                if (e.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            }.bind(this));
        },
        responseAdapter({data}) {
            this.$parent.referralCount = data.referralCount;
            this.$parent.referrals = data.referral;
            return {
                data : data.referral,
                count: data.referralCount
            }
        },
        filterable: true
      },
      serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals`,
      exportBtn: true,
      processBtn: false
    }
  },
  notifications: {
      showDownloadingMsg: {
          type: VueNotifications.types.success,
          message: "Export request sent.  Data is now downloading."
      },
      showWarnMsg: {
          type: VueNotifications.types.warn,
          message: "No data found for export file"
      }
  },
  async created() {
    this.token = localStorage.getItem('token');
    await this.getCommunities();
    await this.loadData();
  },
  methods: {
    dateFilter(res) {
			// console.log("dates [selectedDates]:", res);
			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			this.onLoaded();
		},
    onLoaded() {
          if (this.selected) {
            if (this.selectedCircle){
              this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community_id=${this.selected ? this.selected: ''}&circle=${this.selectedCircle ? this.selectedCircle: ''}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
            } else{
              this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community_id=${this.selected ? this.selected: ''}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
            }
          }
      this.$helpers.setTabindexOnTableCells();
      },
    loadData() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community_id=${this.selected ? this.selected: ''}&circle=${this.selectedCircle ? this.selectedCircle: ''}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;

      // this.axios.get(url, {headers: {'x-access-token': this.token}}).then(response => {
      //   this.referrals = response.data.referral;

      // }).catch(err => {

      //   if (err.response.status  === 401) {
      //     localStorage.removeItem('authUser');
      //     this.$router.push('/login');
      //   }
      // });
    },
    async getCommunities() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
      .catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.communities = data;
      if(this.$route.params.community_id) {
        var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
        if (selectedCommunity.length <= 1) {
          this.selected = selectedCommunity[0]._id;
        }
      } else {
        if (this.communities.length <= 1) {
          this.selected = this.communities[0]._id;
        }
      }
			this.filterByCommunity();
    },
    filterByCommunity: async function(value) {
      this.selectedCircle = "all";
      if(this.selected =="all"){
        this.circles = [];
        this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
      } else {
        this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
        this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				});
        this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community_id=${this.selected ? this.selected: ''}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
      }
    },
		filterByCircle: async function () {
			if (this.selectedCircle == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community=${this.selected}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community=${this.selected}&circle=${this.selectedCircle}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
			}
		},
    exportData: async function() {
        // let pastDate = (new Date()).setMinutes((new Date()).getMinutes() - 64800);
        if (this.referralCount > 0) {
          this.showDownloadingMsg();
          this.exportBtn = false;
          this.processBtn = true;
          var queryParams = {
              headers: {'x-access-token': this.token}
          }
          this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/?community_id=${this.selected ? this.selected : ''}&circle=${this.selectedCircle ? this.selectedCircle : ''}&query=${this.searchQuery}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, queryParams).then(async response => {
            if(response.data.referral && response.data.referral.length) {
              await exportCSV(response.data.referral.slice(), 'referrals', ['requestId', 'commentCount'], ['date']);
              this.exportBtn = true;
              this.processBtn = false;
            } else {
              this.showWarnMsg();
              this.exportBtn = true;
              this.processBtn = false;
            }
          }).catch(err => {
              if (err.response.status  === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
        } else {
          this.showWarnMsg();
        }
    },
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    openRequestInNewTab(path) {
      window.open((`/engagement/requests/${path}`), '_blank');
    }
  }
}
</script>
