<template>
    <div>
        <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card no-body class="p-4">
                        <b-card-body>
                            <b-form v-on:submit.prevent="submit">
                                <b-alert :variant="alertvariant"
                                         dismissible
                                         :show="showDismissibleAlert"
                                         v-if="showDismissibleAlert"
                                         @dismissed="showDismissibleAlert=false">
                                  {{this.responses.message}}
                                </b-alert>
                                <!-- <p>{{this.responses.message}}</p> -->
                                <h1>Forgot Password</h1>
                                <p class="text-muted">Reset password to your account</p>
                                <b-input-group
                                label="Email address:"
                                label-for="email"
                                :class="{ 'form-group--error': errors.collect('email') }"
                                class="mb-3"
                                >
                                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                                    <b-form-input
                                    type="text"
                                    name="email"
                                    v-model="email"
                                    aria-describedby="input-1-live-feedback"
                                    v-validate="{ required: true, email: email, max:60 }"
                                    :state="validateState('email')"
                                    placeholder="Enter email">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('email')">
                                    {{errors.first('email')}}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                                <b-row>
                                    <b-col cols="6">
                                        <b-button type="submit" variant="primary" class="float-left">Submit</b-button>
                                    </b-col>
                                    <b-col cols="6">
                                        <router-link to="/login" class="float-right">Login</router-link>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "ForgotPassword",
    data() {
        return {
            email: '',
            responses: '',
            showDismissibleAlert:false  ,
            alertvariant: ''
        }
    },
    created() {
        const dict = {
            custom: {
                email: {
                    required: "Please enter email.",
                    max: "email cannot be more than 60 characters.",
                    email: 'Please enter valid email address.'
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        submit: function() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/forgot-password`, {email: this.email}).then(response => {
                        if(response.data.success) {
                            this.email = '';
                            this.$validator.errors.clear();
                            this.$validator.reset();
                            this.responses = response.data;
                            this.alertvariant = 'success';
                            this.showDismissibleAlert=true;
                            // this.$router.push('/login');
                        }else if(response.data.success == false){
                            this.responses = response.data;
                            this.alertvariant = 'danger';
                            this.showDismissibleAlert=true;
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                //console.log('this.errors.has(ref)',this.errors);
              return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        goToProfile(){
            let route = this.$router.push({path:`/login`});
        }
    }
}
</script>