<template>
  <div class="animated fadeIn mb-70">
    <div id="Dashboard">
        <b-row>
            <b-col sm="6" lg="3">
              <router-link :to="requestLink" class="card-text-black">
                <b-card no-body  class="bg-success">
                  <b-card-body class="pb-0" :to="requestLink">
                    <h4 class="mb-0" role="presentation">{{requestsCount}}</h4>
                    <p>Requests</p>
                  </b-card-body>
                  <card-line1-chart-example chartId="card-chart-01" class="chart-wrapper px-3" style="height:70px;" :height="70"/>
                </b-card>
              </router-link>
            </b-col>
            <b-col sm="6" lg="3">
              <router-link :to="responseLink" class="card-text-black">
                <b-card no-body class="bg-warning">
                  <b-card-body class="pb-0">
                    <h4 class="mb-0" role="presentation">{{responsesCount}}</h4>
                    <p>Responses</p>
                  </b-card-body>
                  <card-line2-chart-example chartId="card-chart-02" class="chart-wrapper px-3" style="height:70px;" :height="70"/>
                </b-card>
                </router-link>
            </b-col>
            <b-col sm="6" lg="3">
              <router-link :to="feedbackLink" class="card-text-black">
                <b-card no-body class="bg-primary">
                  <b-card-body class="pb-0">
                    <h4 class="mb-0" role="presentation">{{ Math.round(satisfactionsAvg * 10) / 10}}</h4>
                    <p>Feedback</p>
                  </b-card-body>
                  <card-line3-chart-example chartId="card-chart-03" class="chart-wrapper" style="height:70px;" height="70"/>
                </b-card>
                </router-link>
            </b-col>
            <b-col sm="6" lg="3">
              <router-link :to="memberLink" class="card-text-black">
                <b-card no-body class="bg-info">
                  <b-card-body class="pb-0">
                    <h4 class="mb-0" role="presentation">{{membersCount}}</h4>
                    <p>Members</p>
                  </b-card-body>
                  <card-bar-chart-example chartId="card-chart-04" class="chart-wrapper px-3" style="height:70px;" height="70"/>
                </b-card>
              </router-link>
            </b-col>
        </b-row>
        <!-- <b-card>
            <b-row>
                <b-col sm="5"> -->
                    <!-- <h4 id="engagement" class="mb-0 custom-card-header-text"><router-link to='/engagement/stream' class="header_title">Engagement </router-link><img src="img/tooltip.png" v-b-tooltip.hover.bottom title="Daily engagement of advisors and members." height="12" alt="tooltip" /></h4> -->
                    <!-- <h4 id="engagement" class="mb-0 custom-card-header-text">Engagement <img src="img/tooltip.png" v-b-tooltip.hover.bottom title="Daily engagement of advisors and members." height="12" alt="tooltip" /></h4>
                </b-col>
                <b-col sm="7" class="d-none d-md-block">
                    <b-button-toolbar class="float-right" aria-label="Toolbar with buttons group">
                        <b-form-radio-group class="mr-3" id="radiosBtn" buttons button-variant="outline-secondary" v-model="selected" name="radiosBtn">
                            <b-form-radio class="mx-0" value="month">Month</b-form-radio>
                            <b-form-radio class="mx-0" value="year">Year</b-form-radio>
                        </b-form-radio-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <router-link to='/engagement/stream' class="header_title">
                <main-chart-example chartId="main-chart-01" class="chart-wrapper" style="height:300px;margin-top:40px;" :bind="true" :chartData="chartData" :options="chartOptions" counter-data="0" :field="selected"></main-chart-example>

            </router-link>
        </b-card> -->
        <b-row class="widgets">
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-envelope-open"></i>
                    </div>
                    <!-- <router-link :to='emailOpenLink'>
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-envelope-open"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{emailOpen}}%</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The average email open rate for requests we are sending to advisors (30 days delayed).">Email Open on Requests</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="info" :value="emailOpen"/>
                </b-card>
            </b-col>
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-happy"></i>
                    </div>
                    <!-- <router-link :to="engagementRateLink">
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-happy"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{engagementRate}}%</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The average percent of advisors that accept, decline or refer a request (30 days delayed).">Engagement Rate</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="success" :value="engagementRate"/>
                </b-card>
            </b-col>
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-clock"></i>
                    </div>
                    <!-- <router-link :to="firstResponseLink">
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-clock"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{firstResponse}}</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The average time (in hours) to receive a response from an advisor.">Hours To First Response</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="warning" :value="firstResponse"/>
                </b-card>
            </b-col>
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-gift"></i>
                    </div>
                    <!-- <router-link :to="responsePerRequestLink">
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-gift"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{responsePerRequest ? responsePerRequest : 0}}</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The average number of responses received divided by number of requests (30 days delayed).">Responses per request</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="primary" :value="responsePerRequestPercentage"/>
                </b-card>
            </b-col>
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-star"></i>
                    </div>
                    <!-- <router-link :to="satisfactionLink">
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-star"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{satisfactionData ? satisfactionData : 0}}</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The average, combined satisfaction score of advisors and members (out of 10).">Average Satisfaction</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="success" :value="satisfactionDataPercentage"/>
                </b-card>
            </b-col>
            <b-col sm="6" md="2">
                <b-card>
                    <div class="h1 text-right mb-4">
                        <i class="cil-people"></i>
                    </div>
                    <!-- <router-link :to="profileUpdateLink">
                        <div class="h1 text-muted text-right mb-4">
                            <i class="cil-people"></i>
                        </div>
                    </router-link> -->
                    <div class="h4 mb-0">{{profileUpdate ? profileUpdate : 0}}</div>
                    <small class="text-uppercase font-weight-bold" v-b-tooltip.hover.bottom title="The number of profile updates (or referrals to your directory).">Profile Data Updates</small>
                    <b-progress height={} class="progress-sm mt-3 mb-0" variant="info" :value="profileUpdate"/>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="4" lg="4">
                <b-card>
                <template slot="header">
                    <h2 class="mt-2 custom-card-header-text">Top Members <img src="img/tooltip.png" tabindex="0" v-b-tooltip.bottom title="The top 10 requesters for help ranked by engagement score." alt="tooltip" height="12"/></h2>
                    <router-link class="float-right mt-12 view-more" :to="memberLink" aria-label="View more Top Members">View more</router-link>
                </template>
                    <b-card-body>
                        <v-client-table v-bind:data="topMembers" v-bind:columns="topMembersColumns" :options="topMembersOptions" id="dataTable" class="top_member">
                            <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                                {{props.row.name}}
                            </span>
                            <span slot="name" slot-scope="props" v-else-if="props.row.name">
                                {{props.row.name}}
                            </span>
                        </v-client-table>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col sm="4" lg="4">
                <b-card>
                <template slot="header">
                    <h2 class="mt-2 custom-card-header-text">Top Advisors <img src="img/tooltip.png" tabindex="0" v-b-tooltip.bottom title="The top 10 advisors ranked by engagement score." height="12" alt="tooltip" /></h2>
                    <router-link class="float-right mt-12 view-more" :to="memberLink" aria-label="View more Top Advisors">View more</router-link>
                </template>
                    <b-card-body>
                        <v-client-table v-bind:data="topAdvisers" v-bind:columns="topAdvisersColumns" :options="topAdvisersOptions" id="dataTable" class="top_advisor">
                            <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                                {{props.row.name}}
                            </span>
                            <span slot="name" slot-scope="props" v-else-if="props.row.name">
                                {{props.row.name}}
                            </span>
                        </v-client-table>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col sm="4" lg="4">
                <b-card>
                <template slot="header">
                    <h2 class="mt-2 custom-card-header-text">Top Tags <img src="img/tooltip.png" tabindex="0" v-b-tooltip.bottom title="The top 10 tags by count" alt="tooltip" height="12"/></h2>
                    <router-link class="float-right mt-12 view-more" :to="tagLink" aria-label="View more Top Tags">View more</router-link>
                </template>
                    <b-card-body>
                        <v-client-table v-bind:data="topTags" v-bind:columns="topTagsColumns" :options="topTagsOptions" id="dataTable" class="top_tag">
                            <span slot="tag" slot-scope="props" class="display-link" @click="tagRequest(props.row.tag)" v-if="props.row.tag">
                                {{props.row.tag}}
                            </span>
                            <span slot="tag" slot-scope="props" v-else>
                                {{'N/A'}}
                            </span>
                        </v-client-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="6" lg="6">
                <b-card>
                    <template slot="header">
                        <h2 class="mt-2 custom-card-header-text">Request Engagement <img src="img/tooltip.png" tabindex="0" v-b-tooltip.bottom title="Request Engagement shows you what percentage of advisors engage when a request is matched to them (30-day average)." alt="tooltip" height="12"/></h2>
                        <router-link class="float-right mt-12 view-more" :to="requestEngagementLink" aria-label="View more Request Engagement">View more</router-link>
                    </template>
                    <div>
                        <pie-example tabindex="0" class="pie-chart" id="request-pie-chart" aria-label="Request Engagement Pie chart" chartId="chart-doughnut-01" :bind="true" :chartData="piaChartData1" :options="piaChartOptions1" counter-data="0" :field="selected"></pie-example>
                    </div>
                </b-card>
            </b-col>
            <b-col sm="6" lg="6">
                <b-card>
                    <template slot="header">
                        <h2 class="mt-2 custom-card-header-text">Response Type <img src="img/tooltip.png" tabindex="0" v-b-tooltip.bottom title="How your advisors help by type of response (30-day average)." alt="tooltip" height="12"/></h2>
                        <router-link class="float-right mt-12 view-more" :to="responseTypeLink" aria-label="View more Response Type">View more</router-link>
                    </template>
                    <div>
                        <pie-example tabindex="0" class="pie-chart" id="response-pie-chart" aria-label="Response Type Pie chart" chartId="chart-doughnut-02" :bind="true" :chartData="piaChartData2" :options="piaChartOptions2" counter-data="0" :field="selected"></pie-example>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-card no-body class="management-card-header">
        <template>
            <router-link to='/engagement/requests-overdue' class="router-link-color"><h4 v-b-tooltip.hover.right title="Requests that have not received an answer in 7+ days." class="mt-2 custom-card-header-text">Escalations</h4></router-link>
            <router-link to='/engagement/requests-overdue' class="float-right mt-12 view-more" aria-label="View more Escalations">View more</router-link>
        </template>
          <!-- <b-card-body>
          <v-client-table v-bind:data="requests" v-bind:columns="columns" :options="requestOptions" id="dataTable" class="requests-overdue-table">
            <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
              {{props.row.member}}
            </span>
            <span slot="member" slot-scope="props" v-else-if = "props.row.member">
              {{props.row.member}}
            </span>
            <span slot="subject" slot-scope="props">
              {{props.row.subject ? props.row.subject : 'N/A'}}
            </span>
            <span slot="date" slot-scope="props" v-if="props.row.date">
              {{ props.row.date | momentDate }}
            </span>
            <span slot="date" v-else>N/A</span>
            <span slot="action" slot-scope="props" class="action-width">
              <ul class="list-inline">
                <li class="list-inline-item">
                    <b-button v-b-tooltip.hover.bottom aria-label="View the request" class="notification-btn tab-focus" variant="dropbox" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                        <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                        <i class="icon-eye"></i>
                    </b-button>
                </li>
              </ul>
            </span>
          </v-client-table>
        </b-card-body> -->
        </b-card>
        <b-card no-body class="management-card-header">
            <template>
                <router-link to='/engagement/requests-approval' class="router-link-color"><h4 class="mt-2 custom-card-header-text">Request Approvals</h4></router-link>
                <router-link to='/engagement/requests-approval' class="float-right mt-12 view-more" aria-label="View more Request Approvals">View more</router-link>
            </template>
            <!-- <b-card-body>
                <div class="toverflow-unset">
                    <v-client-table v-bind:data="requestApproval" v-bind:columns="requestApprovalColumns" :options="requestApprovalOptions" id="dataTable" class="request-approval-table">
                      <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{props.row.member}}
                      </span>
                      <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                        {{props.row.member}}
                      </span>
                      <span slot="subject" slot-scope="props">
                        {{props.row.subject ? props.row.subject : 'N/A'}}
                      </span>
                      <span slot="date" slot-scope="props" v-if="props.row.date">
                        {{ props.row.date | momentDate }}
                      </span>
                      <span slot="date" v-else>N/A</span>
                      <span slot="action" slot-scope="props" class="res-req-action-width">
                        <ul class="list-inline mb-0 text-center">
                          <li class="list-inline-item">
                              <b-button class="tab-focus" v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox" size="sm" @click="openRequestInNewTab(props.row.requestId)"><i class="icon-eye"></i></b-button>
                          </li>
                          <li class="list-inline-item" v-if="authUser.memberID || authUser.role =='SuperAdmin'">
                              <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                <template slot="button-content">
                                  <i class="icon-settings"></i>
                                </template>
                                <b-dropdown-item @click="approveRequest(props.row.requestId, 'approve', 'no')">Approve request</b-dropdown-item>
                                <b-dropdown-item @click="approveRequest(props.row.requestId, 'approve', 'yes')">Approve member</b-dropdown-item>
                                <!-- <b-dropdown-item @click="approveRequest(props.row.requestId, 'reject', 'coach')">Reject and coach</b-dropdown-item> --//comment this line
                                <b-dropdown-item @click="$router.push(`/engagement/requests-coach/${props.row.requestId}`)">Reject and coach</b-dropdown-item>
                                <b-dropdown-item @click="approveRequest(props.row.requestId, 'reject', 'no')">Reject and delete</b-dropdown-item>
                              </b-dropdown>
                          </li>
                        </ul>
                      </span>
                    </v-client-table>
                </div>
            </b-card-body> -->
        </b-card>
        <b-card no-body class="management-card-header">
            <template>
                <router-link to='/engagement/responses-approval' class="router-link-color"><h4 class="mt-2 custom-card-header-text">Response Approvals</h4></router-link>
                <router-link to='/engagement/responses-approval' class="float-right mt-12 view-more" aria-label="View more Response Approvals">View more</router-link>
            </template>
            <!-- <b-card-body>
              <div class="toverflow-unset">
                  <v-client-table v-bind:data="responseApproval" v-bind:columns="responseApprovalColumns" :options="responseApprovalOptions" id="dataTable" class="response-approval-table">
                      <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                          {{props.row.member}}
                      </span>
                      <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                          {{props.row.member}}
                      </span>
                      <span slot="advice" slot-scope="props">
                          <span v-b-tooltip.hover.bottom :title="props.row.advice" v-if="props.row.advice">{{getAdvice(props.row.advice)}}
                          </span>
                          <span v-else>N/A</span>
                      </span>
                      <span slot="date" slot-scope="props" v-if="props.row.date">
                          {{ props.row.date | momentDate }}
                      </span>
                      <span slot="date" v-else>N/A</span>
                      <span slot="action" slot-scope="props" class="res-req-action-width">
                          <ul class="list-inline mb-0 text-center">
                              <li class="list-inline-item">
                                  <b-button class="tab-focus" v-b-tooltip.hover.bottom title="View response" variant="dropbox" size="sm" @click="openResponseModal(props.row.advice)"><i class="icon-eye"></i></b-button>
                              </li>
                              <li class="list-inline-item" v-if="authUser.memberID || authUser.role =='SuperAdmin'">
                                  <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                      <template slot="button-content">
                                          <i class="icon-settings"></i>
                                      </template>
                                      <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'no')">Approve response</b-dropdown-item>
                                      <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'yes')">Approve member</b-dropdown-item>
                                      <!-- <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'reject', 'coach')">Reject and coach</b-dropdown-item> --//comment this line
                                       <b-dropdown-item @click="$router.push(`/engagement/requests-response-coach/${props.row.requestId}/${props.row.giveId}`)">Reject and coach</b-dropdown-item>
                                      <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'reject', 'no')">Reject and delete</b-dropdown-item>
                                  </b-dropdown>
                              </li>
                          </ul>
                      </span>
                  </v-client-table>
              </div>
            </b-card-body> -->
            <b-modal id="response-modal" class="response-modal" size="lg" ok-title="Close" v-model="responseModal" @ok="responseModal = false">
              {{giveAdvice}}
            </b-modal>
        </b-card>
        <!-- Incomplete Requests card start -->
        <b-card no-body class="management-card-header">
            <template>
                <router-link to='/engagement/incomplete-requests' class="router-link-color"><h4 class="mt-2 custom-card-header-text">Incomplete Requests</h4></router-link>
                <router-link to='/engagement/incomplete-requests' class="float-right mt-12 view-more" aria-label="View more Incomplete Requests">View more</router-link>
            </template>
            <!-- <b-card-body>
                <v-client-table v-bind:data="incompletedRequests" v-bind:columns="incompletedRequestColumns" :options="incompletedRequestOptions" id="dataTable" class="requests-overdue-table">
                    <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                    {{props.row.member}}
                    </span>
                    <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                    {{props.row.member}}
                    </span>
                    <span slot="subject" slot-scope="props">
                    {{props.row.subject ? props.row.subject : 'N/A'}}
                    </span>
                    <span slot="date" slot-scope="props" v-if="props.row.date">
                    {{ props.row.date | momentDate }}
                    </span>
                    <span slot="date" v-else>N/A</span>
                    <span slot="action" slot-scope="props" class="action-width">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <b-button v-b-tooltip.hover.bottom aria-label="View the request" class="notification-btn" variant="dropbox tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId,'incomplete')">
                                <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                                <i class="icon-eye"></i>
                            </b-button>
                        </li>
                    </ul>
                    </span>
                </v-client-table>
            </b-card-body> -->
        </b-card>
        <!-- Incomplete Requests card end -->
        <!-- Rejected Request card start -->
        <b-card no-body class="management-card-header">
            <template>
                <router-link to='/engagement/rejected-requests' class="router-link-color"><h4 class="mt-2 custom-card-header-text">Rejected Requests</h4></router-link>
                <router-link to='/engagement/rejected-requests' class="float-right mt-12 view-more" aria-label="View more Rejected Requests">View more</router-link>
            </template>
            <!-- <b-card-body>
                <div class="toverflow-unset">
                    <v-client-table v-bind:data="requestReject" v-bind:columns="requestRejectColumns" :options="requestRejectOptions" id="dataTable" class="request-approval-table">
                      <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{props.row.member}}
                      </span>
                      <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                        {{props.row.member}}
                      </span>
                      <span slot="subject" slot-scope="props">
                        {{props.row.subject ? props.row.subject : 'N/A'}}
                      </span>
                      <span slot="date" slot-scope="props" v-if="props.row.date">
                        {{ props.row.date | momentDate }}
                      </span>
                      <span slot="date" v-else>N/A</span>
                      <span slot="action" slot-scope="props" class="res-req-action-width">
                        <ul class="list-inline mb-0 text-center">
                          <li class="list-inline-item">
                              <b-button v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId)"><i class="icon-eye"></i></b-button>
                          </li>
                          <li class="list-inline-item" v-if="authUser.memberID || authUser.role =='SuperAdmin'">
                                  <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                      <template slot="button-content">
                                          <i class="icon-settings"></i>
                                      </template>
                                      <b-dropdown-item  v-if="setStatus(props.row.status) == 'Closed' || setStatus(props.row.status) == 'Rejected'" @click="reOpenRequest(props.row.requestId)">Reopen the request</b-dropdown-item>
                                  </b-dropdown>
                              </li>
                        </ul>
                      </span>
                    </v-client-table>
                </div>
            </b-card-body> -->
        </b-card>
        <!-- Rejected Request card end -->
        <!-- Rejected Response card start -->
        <b-card no-body class="management-card-header">
            <template   >
                <router-link to='/engagement/rejected-responses' class="router-link-color"><h4 class="mt-2 custom-card-header-text">Rejected Responses</h4></router-link>
                <router-link to='/engagement/rejected-responses' class="float-right mt-12 view-more" aria-label="View more Rejected Responses">View more</router-link>
            </template>
            <!-- <b-card-body>
              <div class="toverflow-unset">
                  <v-client-table v-bind:data="responseReject" v-bind:columns="responseRejectColumns" :options="responseRejectOptions" id="dataTable" class="response-approval-table">
                      <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                          {{props.row.member}}
                      </span>
                      <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                          {{props.row.member}}
                      </span>
                      <span slot="response_Action" slot-scope="props">
                          <span v-b-tooltip.hover.bottom :title="showResponse(props.row)" v-if="props.row.response_Action">{{getAdvice(props.row.response_Action)}}
                          </span>
                          <span v-else>N/A</span>
                      </span>
                      <span slot="date" slot-scope="props" v-if="props.row.date">
                          {{ props.row.date | momentDate }}
                      </span>
                      <span slot="date" v-else>N/A</span>
                      <span slot="action" slot-scope="props" class="res-req-action-width">
                          <ul class="list-inline mb-0 text-center">
                              <li class="list-inline-item">
                                  <b-button v-b-tooltip.hover.bottom title="View response" variant="dropbox tab-focus" size="sm" @click="openResponseModal(showResponse(props.row))"><i class="icon-eye"></i></b-button>
                              </li>
                          </ul>
                      </span>
                  </v-client-table>
              </div>
            </b-card-body> -->
            <b-modal id="response-modal" class="response-modal" size="lg" ok-title="Close" v-model="responseModal" @ok="responseModal = false">
              {{giveAdvice}}
            </b-modal>
        </b-card>
        <!-- Rejected Response card end -->
    </div>
        <div class="pie-content">
            <div class="pie-chart-table1" tabindex="0" aria-label="Pie Chart 1"></div>
                <table class="request-table">
                    <thead>
                        <tr>
                        <th scope="col">Advisor</th>
                        <th scope="col">Count</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr v-for="(count, index) in dataPieChart1" :key="index">
                    <td scope="row">{{ piaChartData1.labels[index] }}</td>
                    <td scope="row">{{ count }}</td>
                    </tr>
                </tbody>
                </table>
            <div id="announcement" aria-live="assertive"></div>
        </div>
        <div class="pie-content">
            <div class="pie-chart-table2 pie-content-table" tabindex="0" aria-label="Pie Chart 2"></div>
                <table class="response-table">
                    <thead>
                        <tr>
                        <th scope="col">Advisor</th>
                        <th scope="col">Count</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr v-for="(count, index) in dataPieChart2" :key="index">
                    <td scope="row">{{ piaChartData2.labels[index] }}</td>
                    <td scope="row">{{ count }}</td>
                    </tr>
                </tbody>
                </table>
        </div>
  </div>
</template>

<script>
import CardLine1ChartExample from './dashboard/CardLine1ChartExample'
import CardLine2ChartExample from './dashboard/CardLine2ChartExample'
import CardLine3ChartExample from './dashboard/CardLine3ChartExample'
import CardBarChartExample from './dashboard/CardBarChartExample'
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications';
// import MainChartExample from './dashboard/MainChartExample'
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { random } from '@/shared/utils'
import PieExample from './dashboard/PieExample'

export default {
    name: 'dashboard',
    components: {
        CardLine1ChartExample,
        CardLine2ChartExample,
        CardLine3ChartExample,
        CardBarChartExample,
        // MainChartExample,
        PieExample
    },
    data() {
        return {
            requestsCount: 0,
            responsesCount: 0,
            membersCount: 0,
            satisfactionsAvg: 0,
            token: '',
            selected: 'month',
            chartScale: 10,
            chartData: {
                labels: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                datasets: [{
                    backgroundColor: hexToRgba('#20a8d8', 10),
                    borderColor: '#20a8d8',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: []
                }]
            },
            chartOptions: {
                tooltips: {
                    enabled: false,
                    custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        labelColor: function (tooltipItem, chart) {
                            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: "#fff"
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            stepSize: Math.ceil(10 / 5),
                            max: 10,
                            fontColor: "#fff"
                        },
                        gridLines: {
                            display: true
                        }
                    }]
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3
                    }
                }
            },
            requestEngagementLink: '',
            responseTypeLink: '',
            piaChartData1: {
                labels: ['Request Email', '1st Reminder', '2nd Reminder'],
                datasets: [
                    {
                        borderColor: '#A9A9A9',
                        backgroundColor: [
                            '#c69500',
                            '#2eadd3',
                            '#187da0'
                        ],
                        data: [],
                        borderWidth: '0'
                    }
                ]
            },
            piaChartOptions1: {
                tooltips: {
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                        },
                        label: function(tooltipItem, data) {
                            let sum = data['datasets'][0].data.reduce(function(a, b){
                                return a + b;
                            }, 0);
                            let dataset = data['datasets'][0];
                            let percent = Math.round((dataset['data'][tooltipItem['index']] / sum) * 100)
                            return data['datasets'][0]['data'][tooltipItem['index']] + ' / ' + percent + '%';
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    borderWidth: '0',
                    labels: {
                        fontColor: "#fff",
                    }
                }
            },
            piaChartData2: {
                labels: ['Advice', 'Call', 'Meeting'],
                datasets: [
                    {
                        borderColor: '#A9A9A9',
                        backgroundColor: [
                            '#c69500',
                            '#2eadd3',
                            '#187da0'
                        ],
                        data: [],
                        borderWidth: '0',
                    }
                ]
            },
            piaChartOptions2: {
                tooltips: {
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                        },
                        label: function(tooltipItem, data) {
                            let sum = data['datasets'][0].data.reduce(function(a, b){
                                return a + b;
                            }, 0);
                            let dataset = data['datasets'][0];
                            let percent = Math.round((dataset['data'][tooltipItem['index']] / sum) * 100)
                            return data['datasets'][0]['data'][tooltipItem['index']] + ' / ' + percent + '%';
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    borderWidth: '0',
                    labels: {
                        fontColor: "#fff",
                    }
                }
            },
            response: [],
            requestLink: '',
            responseLink: '',
            feedbackLink: '',
            memberLink: '',
            tagLink: '',
            requests: [],
            columns: ['member', 'community', 'circle', 'date', 'subject', 'Sent','Opened','Accepted','Declined', 'Referred', 'Feedback', 'action'],
            requestOptions: {
                headings: {
                    Sent: 'S',
                    Opened: 'O',
                    Accepted: 'A',
                    Declined: 'D',
                    Referred: 'R',
                    Feedback: 'F',
                },
                sortable: ['member', 'community', 'circle', 'date', 'subject', 'Sent','Opened','Accepted','Declined', 'Referred','Feedback'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' }
            },
            incompletedRequests: [],
            incompletedRequestColumns: ['member', 'community', 'circle', 'date', 'subject', 'Sent','Opened','Accepted','Declined', 'Referred', 'Feedback', 'action'],
            incompletedRequestOptions: {
                headings: {
                    Sent: 'S',
                    Opened: 'O',
                    Accepted: 'A',
                    Declined: 'D',
                    Referred: 'R',
                    Feedback: 'F',
                },
                sortable: ['member', 'community', 'circle', 'date', 'subject', 'Sent','Opened','Accepted','Declined', 'Referred','Feedback'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' }
            },
            requestApproval: [],
            requestApprovalColumns: ['date', 'member', 'circle', 'subject', 'action'],
            requestApprovalOptions: {
                headings: {
                    member: 'Name'
                },
                sortable: ['date', 'member', 'subject'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            },
            responseApproval: [],
            responseApprovalColumns: ['date', 'member', 'circle', 'advice', 'action']   ,
            responseApprovalOptions: {
                headings: {
                    member: 'Name'
                },
                sortable: ['date', 'member', 'circle', 'advice'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            },
            authUser: {},
            responseModal: false,
            giveAdvice: '',
            emailOpen: '0.0',
            engagementRate: 0,
            firstResponse: 0,
            responsePerRequest: 0,
            satisfactionData: 0,
            profileUpdate: 0,
            responsePerRequestPercentage : 0,
            satisfactionDataPercentage: 0,
            emailOpenLink: '',
            engagementRateLink: '',
            firstResponseLink: '',
            responsePerRequestLink: '',
            satisfactionLink: '',
            profileUpdateLink: '',
            topMembers: [],
            topMembersColumns: ['name', 'engagementPoints'],
            topMembersOptions: {
                headings: {
                    engagementPoints: 'Engagement',
                    name: "Member"
                },
                sortable: ['name', 'engagementPoints'],
                filterable: false
            },
            topAdvisers: [],
            topAdvisersColumns: ['name', 'engagementPoints'],
            topAdvisersOptions: {
                headings: {
                    engagementPoints: 'Engagement',
                    name: "Member"
                },
                sortable: ['name', 'engagementPoints'],
                filterable: false
            },
            topTags: [],
            topTagsColumns: ['tag', 'count'],
            topTagsOptions: {
                sortable: ['tag', 'count'],
                filterable: false
            },
            requestReject: [],
            requestRejectColumns: ['date', 'member', 'circle', 'subject', 'action'],
            requestRejectOptions: {
                headings: {
                    member: 'Name'
                },
                sortable: ['date', 'member', 'circle', 'subject'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            },
            responseReject: [],
            responseRejectColumns: ['date', 'member', 'circle','response_Action', 'action']   ,
            responseRejectOptions: {
                headings: {
                    member: 'Name'
                },
                sortable: ['date', 'member', 'circle', 'response_Action'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            },
            dataPieChart1: [0, 0, 0],
            dataPieChart2: [0, 0, 0],
        }
    },
    watch: {
        "$route": "loadData",
        // "selected": "loadEngagementChart"
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    created() {
        this.loadData();
        const user = localStorage.getItem('authUser');
        if(user) {
            this.authUser = JSON.parse(user);
        }
    },
    methods: {
        async loadData() {
            this.token = localStorage.getItem('token');
            let satisfactionUri = `${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction/count`;
            let requestUri = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/count`;
            let memberUri = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/count`;
            let responseUri = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/count`;

            if (this.$route.params.community_id) {
                await this.axios.get(satisfactionUri, {params: {community: this.$route.params.community_id}, headers: {'x-access-token': this.token}}).then(response => {
                    this.satisfactionsAvg = response.data || 0;
                });
                await this.axios.get(requestUri, {params: {community: this.$route.params.community_id}, headers: {'x-access-token': this.token}}).then(response => {
                    this.requestsCount = response.data || 0;
                });
                await this.axios.get(memberUri, {params: {community: this.$route.params.community_id}, headers: {'x-access-token': this.token}}).then(response => {
                    this.membersCount = response.data || 0;
                });
                await this.axios.get(responseUri, {params: {community: this.$route.params.community_id}, headers: {'x-access-token': this.token}}).then(response => {
                    this.responsesCount = response.data || 0;
                });
                this.requestLink = { name: 'CommunityRequests', params: { community_id: this.$route.params.community_id }};
                this.responseLink = { name: 'CommunityResponses', params: { community_id: this.$route.params.community_id }};
                this.feedbackLink = { name: 'CommunityFeedback', params: { community_id: this.$route.params.community_id }};
                this.memberLink = { name: 'CommunityMembers', params: { community_id: this.$route.params.community_id }};
                this.emailOpenLink = `/analytics/email_open/?community_id=${this.$route.params.community_id}`;
                this.engagementRateLink = `/analytics/engagement_rate/?community_id=${this.$route.params.community_id}`;
                this.firstResponseLink = `/analytics/first_response/?community_id=${this.$route.params.community_id}`;
                this.responsePerRequestLink = `/analytics/response_per_request/?community_id=${this.$route.params.community_id}`;
                this.satisfactionLink = `/analytics/satisfaction/?community_id=${this.$route.params.community_id}`;
                this.profileUpdateLink = `/analytics/profile_update/?community_id=${this.$route.params.community_id}`;
                this.tagLink = `/engagement/tags_by_occurrence/?community_id=${this.$route.params.community_id}`;
                this.requestEngagementLink = `/engagement/request-engagement/?community_id=${this.$route.params.community_id}`;
                this.responseTypeLink = `/engagement/response-type/?community_id=${this.$route.params.community_id}`;
            } else if (!this.$route.params.community_id) {
                await this.axios.get(satisfactionUri, {headers: {'x-access-token': this.token}}).then(response => {
                    this.satisfactionsAvg = response.data || 0;
                });
                await this.axios.get(requestUri, {headers: {'x-access-token': this.token}}).then(response => {
                    this.requestsCount = response.data || 0;
                });
                await this.axios.get(memberUri, {headers: {'x-access-token': this.token}}).then(response => {
                    this.membersCount = response.data || 0;
                });
                await this.axios.get(responseUri, {headers: {'x-access-token': this.token}}).then(response => {
                    this.responsesCount = response.data || 0;
                });
                this.requestLink = '/engagement/requests';
                this.responseLink = '/engagement/responses';
                this.feedbackLink = '/engagement/feedback';
                this.memberLink = '/engagement/members';
                this.tagLink = '/engagement/tags_by_occurrence';
                this.emailOpenLink = `/analytics/email_open`;
                this.engagementRateLink = `/analytics/engagement_rate`;
                this.firstResponseLink = `/analytics/first_response`;
                this.responsePerRequestLink = `/analytics/response_per_request`;
                this.satisfactionLink = `/analytics/satisfaction`;
                this.profileUpdateLink = `/analytics/profile_update`;
                this.requestEngagementLink = `/engagement/request-engagement`;
                this.responseTypeLink = `/engagement/response-type`;
            }
            this.getWidgetData();
            this.requestResponseChart();
            this.topMemberAdvisor();
            this.topRequestTags();
            // this.requestOverDue();
            // this.requestIncomplete();
            // this.reqResApproval();
            // this.getRejectRequestResponse();
            this.$helpers.setTabindexOnTableCells();
    },
    reOpenRequest: async function (askId) {
        await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/reopen`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(response => {
            this.loadData();
        });
    },
    // engagementChart: async function() {
    //     let labels = [];
    //     let data = [];
    //     let chartScale = 0;
    //     await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/engagement-point/?date=${this.selected}&community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, { headers: {'x-access-token': this.token}}).then(response => {
    //         if (this.selected == 'month') {
    //             // response.data = this.$lodash.orderBy(response.data, ['date'], ['asc']);
    //             // sort date in desc order (old to new)
    //             response.data = this.$lodash.orderBy(response.data, function(data) {return new Date(data.date);});
    //         }
    //         for (let i = 0; i < response.data.length; i++) {
    //             labels.push(response.data[i].date);
    //             data.push(response.data[i].points);
    //             if (response.data[i].points > chartScale) {
    //                 chartScale = response.data[i].points;
    //             }
    //         }
    //         this.chartScale = chartScale;
    //         this.chartOptions.scales.yAxes[0].ticks.max = chartScale;
    //         if (this.selected == 'year') {
    //             labels.reverse();
    //             data.reverse();
    //         }
    //         this.chartData.labels = labels;
    //         this.chartData.datasets[0].data = data;
    //     });
    // },
    // loadEngagementChart: async function() {
    //     await this.engagementChart();
    // },
    setStatus: function(status) {
        return setRequestStatus(status);
    },
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    topMemberAdvisor: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/topMemberAdvisor`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/topMemberAdvisor/?community_id=${this.$route.params.community_id}`;
        }
        this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data) {
                this.topAdvisers = response.data.advisors;
                this.topMembers = response.data.members;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    topRequestTags: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/top-tags/?&limit=10`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/top-tags/?community_id=${this.$route.params.community_id}&limit=10`;
        }
        this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            this.topTags = response.data;
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    requestOverDue: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestOverDue/?limit=10`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestOverDue/?community_id=${this.$route.params.community_id}&limit=10`;
        }
        this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data.asks) {
                this.requests = response.data.asks;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    requestIncomplete: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?limit=10`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestIncomplete/?community_id=${this.$route.params.community_id}&limit=10`;
        }
        this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data.asks) {
                this.incompletedRequests = response.data.asks;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    reqResApproval: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/reqResApproval/?limit=5`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/reqResApproval/?community_id=${this.$route.params.community_id}&limit=5`;
        }
        await this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data.reqApproval) {
                this.requestApproval = response.data.reqApproval
            }
            if (response.data.resApproval) {
                this.responseApproval = response.data.resApproval;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    getRejectRequestResponse: async function() {
        let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/get-reject-request-response/?limit=5`;
        if (this.$route.params.community_id) {
            url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/get-reject-request-response/?community_id=${this.$route.params.community_id}&limit=5`;
        }
        await this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data.reqReject) {
                this.requestReject = response.data.reqReject
            }
            if (response.data.resReject) {
                this.responseReject = response.data.resReject;
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    getAdvice: function(advice) {
        if(advice.length > 88) {
            return advice.substr(0, 88) + '...';
        } else {
            return advice;
        }
    },
    approveRequest: function(askId, action, status) {
        let params = {
            askId: askId,
            action: action,
            status: status,
            approvedBy: 'dashboard'
        }
        if (this.authUser.memberID) {
            params.adminId = this.authUser.memberID;
        }
        this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
            await this.reqResApproval();
            if (action == 'approve') {
                this.showSuccessMsg({message: 'Thank you for approving the request.'})
            } else if (action == 'reject') {
                this.showSuccessMsg({message: 'Thank you for your response'})
            }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    approveResponse: function(askId, giveId, action, status) {
        let params = {
            askId: askId,
            giveId: giveId,
            action: action,
            status: status,
            approvedBy: 'dashboard'
        }
        if (this.authUser.memberID) {
            params.adminId = this.authUser.memberID;
        }
        this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
            setTimeout(async () => {
                await this.reqResApproval();
                if (action == 'approve') {
                    this.showSuccessMsg({message: 'Thank you for approving the response.'})
                } else if (action == 'reject') {
                    this.showSuccessMsg({message: 'Thank you for your response'})
                }
            }, 2000);
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    openResponseModal: function(advice) {
        this.responseModal = true;
        this.giveAdvice = advice;
    },
    getWidgetData: async function() {
        this.emailOpen = '0.0';
        this.engagementRate = 0;
        this.firstResponse = 0;
        this.satisfactionData = 0;
        this.responsePerRequest = 0;
        this.profileUpdate = 0;
        this.responsePerRequestPercentage = 0;
        this.satisfactionDataPercentage = 0;
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/email_open_rate/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(response => {
            if (response.data) {
                this.emailOpen = response.data.open;
            }
        });
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/engagement_rate/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(response => {
            if (response.data) {
                this.engagementRate = response.data.engagementRate;
            }
        });
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/first_response/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data) {
                this.firstResponse = response.data.time;
            }
        });
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response_per_request/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data) {
                this.responsePerRequest = response.data.count ? response.data.count : 0;
                this.responsePerRequestPercentage = response.data.percentage;
            }
        });
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction_avg/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data) {
                this.satisfactionData = response.data;
                this.satisfactionDataPercentage = Math.round(this.satisfactionData * 100 / 10);
            }
        });
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/update_profile/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, {headers: {'x-access-token': this.token}}).then(async response => {
            if (response.data) {
                this.profileUpdate = response.data.count;
            }
        });
    },
    tagRequest: function(tag) {
        window.open((`/engagement/requests/?tag=${tag}`), '_blank');
    },
    requestResponseChart: async function() {
        let data = [];
        let arr = [];
        await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response_type/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, { headers: {'x-access-token': this.token}}).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i]._id == 'Share Advice') {
                    arr[0] = response.data[i].count;
                    // count += response.data[i].count;
                } else if (response.data[i]._id == 'Call Me') {
                    arr[1] = response.data[i].count;
                    // count += response.data[i].count;
                } else if (response.data[i]._id == "Let's Connect") {
                    arr[2] = response.data[i].count;
                    // count += response.data[i].count;
                }
            }
            this.dataPieChart2 = [...arr];
            this.piaChartData2.datasets[0].data = arr;
        });
        await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request_engagement/?community_id=${this.$route.params.community_id ? this.$route.params.community_id: ''}`, { headers: {'x-access-token': this.token}}).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i]._id == 'first_email_advisor') {
                    data[0] = response.data[i].count;
                } else if (response.data[i]._id == 'first_reminder_advisor') {
                    data[1] = response.data[i].count;
                } else if (response.data[i]._id == "second_reminder_advisor") {
                    data[2] = response.data[i].count;
                }
            }
            this.dataPieChart1 = [...data];            
            this.piaChartData1.datasets[0].data = data;
            this.addListenerToPieChart();
        });
    },
    openRequestInNewTab(path,requestType = '') {
        if (requestType == 'incomplete') {
            window.open((`/engagement/incomplete-requests/${path}`), '_blank');
        } else {
            window.open((`/engagement/requests/${path}`), '_blank');
        }
    },
    showResponse: function(response) {
      let returnResponse = response.response_Action;
      if(response.advice) {
        returnResponse += `\n\n${response.advice}`;
      }
      return returnResponse;
    },
    addListenerToPieChart: function() {
        const pieChart = document.querySelectorAll(".pie-chart");
        pieChart.forEach(element => {
            element.addEventListener("focus",(event) => {
                var targetID = event.target.id;
                this.makeAnnouncement(targetID);
            });
        });
    },
    makeAnnouncement: function(targetID) {
        var tableContent;
        if(targetID == 'request-pie-chart'){
            tableContent = document.getElementsByClassName("request-table")[0]; 
        }else if(targetID == 'response-pie-chart'){
            tableContent = document.getElementsByClassName("response-table")[0];
        }
      const liveRegion = document.createElement('div');
      liveRegion.setAttribute('role', 'alert');
      liveRegion.setAttribute('aria-live', 'polite');
      liveRegion.innerText = tableContent.innerText;
      document.body.appendChild(liveRegion);
      setTimeout(() => {
        liveRegion.remove();
      },1000)
    }
  }
}
</script>
<style>
.chart-wrapper {
    height: 300px;
}
</style>
