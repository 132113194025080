<template>
    <div>
    <b-card class="admins-list">
      <template slot="header" v-if="showAddBtn">
        <h3>Library</h3>
        <b-button variant="dropbox tab-focus" v-on:click="$router.push('library/create')">Add Library</b-button>
      </template>
      <b-card-body>
        <v-client-table v-bind:data="libraries" v-bind:columns="columns" :options="options" id="dataTable" class="library-list">
            <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.url)"> {{props.row.name}} </span>
            <span slot="action" slot-scope="props">
                <b-button tabindex="0" aria-live="assertive" aria-label="Edit" class="btn-space tab-focus" variant="dropbox tab-focus" size="sm" v-on:click="$router.push(`/dashboard/library/${props.row._id}`)"><i class="fa fa-edit"></i></b-button>
                <b-button tabindex="0" aria-live="assertive" aria-label="Remove" class="btn-space tab-focus" variant="dropbox tab-focus" size="sm" v-on:click="deleteLibrary(props.row._id)"><i class="fa fa-remove"></i></b-button>
              </span>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications'

export default {
    name: 'Library',
    data() {
        return {
            showAddBtn: true,
            libraries: [],
            columns: ['name', 'description', 'action'],
            token: localStorage.getItem('token'),
            options: {
              sortable: ['name', 'description'],
              sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
              pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
              }
            }
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        if (authUser && authUser.role === 'Admin') {
            this.columns = ['name', 'description'];
            this.showAddBtn = false;
        }
        this.loadData();
    },
    methods: {
      loadData() {
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/library`, {headers: {'x-access-token': this.token}}).then(async response => {
          if (response.data.libraries) {
            this.libraries = response.data.libraries;
          }
        }).catch(err => {
          if (err.response.status  === 401) {
            localStorage.removeItem('authUser');
            this.$router.push('/login');
          }
        });
        this.$helpers.setTabindexOnTableCells();
      },
      deleteLibrary: function(id) {
        this.axios.delete(`${process.env.VUE_APP_API_BASE_URL}/dashboard/library/${id}/remove`, {headers: {'x-access-token': this.token}}).then(async response => {
          if(response.data.success) {
            this.loadData();
            this.showSuccessMsg({message: 'Library has been removed'})
            this.announceMessage("Library has been removed");
          }

        }).catch(err => {
          if (err.response.status  === 401) {
            localStorage.removeItem('authUser');
            this.$router.push('/login');
          }
        });
      },
      redirectUrls: function(link) {
        window.open(link, '_blank');
      },
      announceMessage(message) {
          const liveRegion = document.createElement('div');
          liveRegion.setAttribute('role', 'alert');
          liveRegion.setAttribute('aria-live', 'polite');
          liveRegion.innerText = message;
          document.body.appendChild(liveRegion);
          setTimeout(() => {
            liveRegion.remove();
          },1000)
      },
    }
}
</script>
