<template>
	<div class="app flex-row align-items-center">
		<b-container>
			<b-row class="justify-content-center">
				<b-col md="6">
					<b-card no-body class="p-4">
						<b-card-body>
							<div>Verifying details.</div>
						</b-card-body>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
  	name: "callback",
  	props: ["auth"],
  	data() {
    	return {};
	},
	mounted() {
		this.auth.handleAuthentication();
    	// this.auth.handleAuthentication().then((data) => {
      	// 	this.$router.push({ name: 'home' })
    	// })
  	}
};
</script>
