<template>
  <div>
    <h1>Edit Satisfaction</h1>
    <form @submit.prevent="updateSatisfaction">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Satisfaction Customer:</label>
            <input type="text" class="form-control" v-model="satisfaction.customer">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Satisfaction Member:</label>
            <input type="text" class="form-control" v-model="satisfaction.member">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Satisfaction Title:</label>
            <input type="number" class="form-control" v-model="satisfaction.marks">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Satisfaction Date:</label>
            <input type="text" class="form-control" v-model="satisfaction.date">
          </div>
        </div>
      </div>
        <br />
        <div class="form-group">
          <button class="btn btn-primary">Update</button>
        </div>
    </form>
  </div>
</template>

<script>
    export default {
      name: 'satisfactionsedit',
      data() {
        return {
          satisfaction: {}
        }
      },
      created() {
        let uri = `${process.env.VUE_APP_API_BASE_URL}/satisfactions/edit/${this.$route.params.id}`;
        this.axios.get(uri).then((response) => {
            this.satisfaction = response.data;
        });
      },
      methods: {
        updateSatisfaction() {
          let uri = `${process.env.VUE_APP_API_BASE_URL}/satisfactions/update/${this.$route.params.id}`;
          this.axios.post(uri, this.satisfaction).then(() => {
            this.$router.push({name: 'Satisfactions'});
          });
        }
      }
    }
</script>