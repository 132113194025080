<template>
    <div class="mb-50">
        <div class="request-status-block">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <h1> Coach</h1>
                </li>
            </ul>
        </div>
        <b-row>
            <b-col md="12">
                <div class="card">
                    <div class="card-body">
                        <h3>Request</h3>
                        <label class="ask-header">Subject</label>
                        <p v-text="ask.subject"></p>
                        <label class="ask-header">Description</label>
                        <p class="md-justify" v-text="ask.text"></p>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card">
                    <div class="card-body">
                        <h3>Response</h3>
                        <p class="md-justify" v-html="giveInsight"></p>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card">
                    <div class="card-body">
                        <b-form-textarea name="text" placeholder="Enter description" rows="14" v-model.trim="coach" class="form-control md-justify"></b-form-textarea>
                    </div>
                    <div class="card-body" v-if="giveInsight">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <button type="button" class="btn custom-card-header-filter btn-dropbox tab-focus" @click="coachResponse()" :disabled="!coach">
                                    Reject and Send Coaching Message
                                </button>
                            </li>
                            <li class="list-inline-item">
                                <button type="button" class="btn custom-card-header-filter btn-cancel tab-focus" @click="$router.push(`/`)"  >Cancel</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications'

export default {
    name: 'RequestCoachView',
    data() {
        return {
            ask: {},
            token: '',
            authUser: {},
            giveInsight: '',
            coach: ''
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/asks/${this.$route.params.request_id}`, {headers: {'x-access-token': this.token}}).then(async request => {
                this.ask = request.data;
                let give = await this.ask.gives.filter(give => give._id.toString() == this.$route.params.give_id.toString());
                if (give && give.length && give[0].responseState.currentState == "In Review") {
                    if (give[0].insight) {
                		this.giveInsight = give[0].insight.replace(/\n{1,}/g, '\n\n');
                	} else if (give[0].phoneNo) {
                		let callMessage = give[0].callMessage ? `${give[0].callMessage}`: ''
                		this.giveInsight = give[0].calltext ? `${callMessage} \n Please call or text me at: ${give[0].phoneNo}`:  `${callMessage} \n My phone number is: ${give[0].phoneNo}`;
                		this.giveInsight = this.giveInsight.replace(/\n{1,}/g, '\n\n')
                	}
                    if (this.ask && this.ask.community && this.ask.community.content && this.ask.community.content.responseCoach) {
                        this.coach =  this.ask.community.content.responseCoach;
                    }
                } else {
                    this.$router.push('/');
                }
            });
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        coachResponse: function() {
            let params = {
                askId: this.ask._id,
                giveId: this.$route.params.give_id,
                action: 'reject',
                status: 'coach',
                approvedBy: 'dashboard',
                coachMsg: this.coach
            }
            if (this.authUser.memberID) {
                params.adminId = this.authUser.memberID;
            }
            this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
                this.showSuccessMsg({message: 'Thank you for your response'})
                this.$router.push('/');
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        }
    }
}
</script>
