/*
    request status
*/
export function setRequestStatus(status) {
    if(status === '(0) New') {
      return 'New';
    } else if(status === '(1) Tagged') {
      return 'Tag';
    } else if(status === '(2) Matched') {
      return 'Matching';
    } else if(status === '(3) Dispatched' || status === '(5) Responded' || status === '(6) Feedback requested' || status === '(6) Feedback added') {
      return 'Active';
    } else if(status === '(7) Closed') {
      return 'Closed';
    } else if(status === '(8) Rejected') {
      return 'Rejected';
    } else if(status === 'In Review') {
      return 'In Review';
    } else if(status === 'Reviewed') {
      return 'Reviewed';
    } else {
      return status;
    }
}

/*
    Response status
*/
export function setResponseStatus(status) {
    if (status === 'Insight') {
        return 'In Review';
    } else if (status === 'Approved') {
        return 'Accepted';
    } else if (status === 'Rejected') {
        return 'Declined';
    } else {
        return status;
    }
}
