<template>
    <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header="Update Profile">
                        <b-card-body>
                            <b-form v-on:submit.prevent="submit">
                                <b-form-group
                                label="Full Name:"
                                label-for="Full Name"
                                >
                                    <b-form-input
                                    type="text"
                                    name="name"
                                    v-model="user.name"
                                    placeholder="Enter full name"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('name')"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('name')">
                                    {{errors.first('name')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Email:"
                                label-for="email"
                                >
                                    <b-form-input
                                    type="text"
                                    name="email"
                                    v-model="user.email"
                                    placeholder="Enter email"
                                    readonly
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('email')">
                                    {{errors.first('email')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Department:"
                                label-for="department"
                                >
                                    <b-form-input
                                    type="text"
                                    name="department"
                                    v-model="user.department"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('department')"
                                    placeholder="Enter department">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('department')">
                                    {{errors.first('department')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Company:"
                                label-for="company"
                                >
                                    <b-form-input
                                    type="text"
                                    name="company"
                                    v-model="user.company"
                                    aria-describedby="input-1-live-feedback"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('company')"
                                    placeholder="Enter company">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('company')">
                                    {{errors.first('company')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group label="Select Photo:" label-for="photo" >
                                    <b-row>
                                        <b-col md="8">
                                            <b-form-file
                                            v-model="file"
                                            :state="validateState('file')"
                                            ref="file"
                                            placeholder="Choose a profile photo..."
                                            drop-placeholder="Drop file here..."
                                            accept=".jpg, .png, .gif"
                                            v-validate="'image'"
                                            name="profile"
                                            @change="onFileChange"
                                            ></b-form-file>
                                            <div class="error" v-if="errors.has('profile')">
                                            {{errors.first('profile')}}
                                            </div>
                                        </b-col>
                                        <b-col md="3" class="profile-image">
                                            <img id="profile-image-display" class="img-responsive"></img>
                                        </b-col>
                                        <b-col md="1">
                                            <b-button variant="primary" v-if="authUser && this.authUser.profilePhoto && !displayImage" @click.stop="removeProfile"><i class="fa fa-remove"></i></b-button>
                                            <b-button variant="primary" v-else-if="displayImage" @click.stop="removePreview"><i class="fa fa-remove"></i></b-button>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                                <b-form-group v-if="user.role != 'SuperAdmin'">
                                    <label class="mr-3 align-middle">Subscribe Weekly Newletter: </label>
                                    <input type="checkbox" v-model="newsletter.subscribed" name="newsletterSubscribed">
                                </b-form-group>
                                <b-button type="submit" variant="primary">Update Profile</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
import { arrayBufferToBase64 } from '@/assets/js/common';
import EventBus from '@/assets/js/EventBus';
export default {
    name: 'UserProfile',
    data() {
        return {
            userId: '',
            value: [],
            options: [],
            token: '',
            user: {},
            file: null,
            authUser: {},
            displayImage: false,
            newsletter: {
                subscribed: false
            }
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        var user = localStorage.getItem('authUser');
        if(user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
        const dict = {
            custom: {
                name: {
                    required: "Please enter name.",
                    max: "name cannot be more than 60 characters.",
                },
                department: {
                    required: "Please enter department."
                },
                company: {
                    required: "Please enter company."
                },
                profile: {
                    image: "Profile photo must be an image."
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    mounted() {
        this.getImageFromBuffer();
    },
    methods: {
        async loadData() {
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.authUser._id}/edit-profile`, {headers: {'x-access-token': this.token}}).then(response => {
                if(response.data.user) {
                    this.user = response.data.user;
                    if (this.user.newsletter && this.user.newsletter.subscribed) {
                        this.newsletter = {
                            subscribed: true
                        }
                    } else {
                        this.newsletter = {
                            subscribed: false
                        }
                    }
                    this.authUser = response.data.user;
                    this.getImageFromBuffer();
                    localStorage.setItem('authUser', JSON.stringify(response.data.user));
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        },
        submit: function() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    var formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('name', this.user.name);
                    formData.append('company', this.user.company);
                    formData.append('department', this.user.department);
                    formData.append('isNewsletterSubscribed', this.newsletter.subscribed);

                    this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.user._id}/update-profile`, formData, {headers: {'Content-Type': 'multipart/form-data', 'x-access-token': this.token}}).then(response => {
                        if(response.data.success) {
                            EventBus.$emit('change-profile', response.data.user);
                            this.$router.push({name: 'Dashboard'});
                        }
                    }).catch(err => {
                        if (err.response.status === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
              return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        getImageFromBuffer: function() {
            var value = this.authUser && this.authUser.profilePhoto ? this.authUser.profilePhoto : '';
            if(value) {
                var b64encoded = arrayBufferToBase64(value.data.data);
                var datajpg = "data:image/jpg;base64," + b64encoded;
                var img = document.getElementById('profile-image-display');
                img.src = datajpg;
                img.title = "Profile Image";
            }
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.displayImage = true;
            var img = document.getElementById('profile-image-display');
            img.src = URL.createObjectURL(file);
            img.title = "Profile Image";
            // this.url = URL.createObjectURL(file);
        },
        removeProfile: function() {
            this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.user._id}/remove-profile`, {headers: {'Content-Type': 'multipart/form-data', 'x-access-token': this.token}}).then(response => {
                if(response.data.success) {
                    this.user = response.data.user;
                    this.authUser = response.data.user;
                    this.displayImage = false;
                    var img = document.getElementById('profile-image-display');
                    img.removeAttribute("src");
                    img.removeAttribute("title");
                    EventBus.$emit('remove-profile', response.data.user);
                    localStorage.setItem('authUser', JSON.stringify(response.data.user));
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        },
        removePreview: function() {
            this.displayImage = false;
            var img = document.getElementById('profile-image-display');
            img.removeAttribute("src");
            this.file = null;
            this.getImageFromBuffer();
        }
    }
}
</script>
