<template>
    <div>
        <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card no-body class="p-4">
                        <b-card-body>
                            <div class="login" @click="login()">
                                <!-- <div>Click to Log In</div>
                                <div>Authorize with Auth0...</div> -->
                                <div>Please wait...</div>
                            </div>
                            <!-- <b-form v-on:submit.prevent="submit">
                                <b-alert :variant="alertvariant"
                                         dismissible
                                         :show="showDismissibleAlert"
                                         v-if="this.responses.success == false"
                                         @dismissed="showDismissibleAlert=false">
                                  {{this.responses.message}}
                                </b-alert>
                                <h1>Login</h1>
                                <p class="text-muted">Sign In to your account</p>
                                <b-input-group
                                label="Email address:"
                                label-for="email"
                                :class="{ 'form-group--error': errors.collect('email') }"
                                class="mb-3"
                                >
                                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                                    <b-form-input
                                    type="text"
                                    name="email"
                                    v-model="email"
                                    aria-describedby="input-1-live-feedback"
                                    v-validate="{ required: true, email: email, max:60 }"
                                    :state="validateState('email')"
                                    placeholder="Enter email">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('email')">
                                    {{errors.first('email')}}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                                <b-input-group
                                class="mb-4"
                                label="Password:"
                                label-for="password"
                                >
                                <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                                    <b-form-input
                                    type="password"
                                    name="password"
                                    v-model="password"
                                    v-validate="{ required: true, min:6 }"
                                    :state="validateState('password')"
                                    placeholder="Enter password">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('password')">
                                    {{errors.first('password')}}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                                <b-row>
                                    <b-col cols="6">
                                        <b-button type="submit" variant="primary" class="float-left">Submit</b-button>
                                    </b-col>
                                    <b-col cols="6">
                                        <router-link to="/forgot-password" class="float-right">Forgot Password?</router-link>
                                    </b-col>
                                </b-row>
                            </b-form> -->
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "Login",
    props: ["auth", "authenticated"],
    data() {
        return {
            email: '',
            password: '',
            responses: '',
            showDismissibleAlert:false,
            alertvariant: '',
            hasMessages: false
        }
    },
    created() {
        this.login();
    },
    computed: {
        messageClass() {
            return {
                "md-invalid": this.hasMessages
            };
        }
    },
    methods: {
        login() {
            this.auth.login();
        }
        /*
        submit: function() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/login`, {email: this.email, password: this.password}).then(response => {
                        if(response.data.user && response.data.token) {
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('authUser', JSON.stringify(response.data.user));
                            var redirectUrl = localStorage.afterLogin;
                            if(typeof localStorage.getItem('afterLogin') == 'string'){
                                localStorage.removeItem('afterLogin');
                                this.$router.push(redirectUrl);
                            }else {
                                this.$router.push('/dashboard');
                            }

                        }else if(response.data.success == false){
                            this.responses = response.data;
                            this.alertvariant = 'danger';
                            this.showDismissibleAlert=true;
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                //console.log('this.errors.has(ref)',this.errors);
              return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        */
    }
}
</script>
