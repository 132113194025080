<template>
<div class="animated fadeIn">
    <div id="Dashboard">
     <b-row>
        <b-col sm="6" lg="3">
          <a href="/engagement/requests">
            <b-card no-body  class="bg-primary">
              <b-card-body class="pb-0" to="/engagement/requests">
                <h4 class="mb-0">{{requestsCount}}</h4>
                <p>Requests</p>
              </b-card-body>
              <card-line1-chart-example chartId="card-chart-01" class="chart-wrapper px-3" style="height:70px;" :height="70"/>
            </b-card>
          </a>
        </b-col>
        <b-col sm="6" lg="3">
          <a href="/engagement/responses">
            <b-card no-body class="bg-info">
              <b-card-body class="pb-0">
                <h4 class="mb-0">{{responsesCount}}</h4>
                <p>Responses</p>
              </b-card-body>
              <card-line2-chart-example chartId="card-chart-02" class="chart-wrapper px-3" style="height:70px;" :height="70"/>
            </b-card>
          </a>
        </b-col>
        <b-col sm="6" lg="3">
          <a href="/engagement/satisfaction">
            <b-card no-body class="bg-warning">
              <b-card-body class="pb-0">
                <h4 class="mb-0">{{ Math.round(satisfactionsAvg * 10) / 10}}</h4>
                <p>Satisfaction</p>
              </b-card-body>
              <card-line3-chart-example chartId="card-chart-03" class="chart-wrapper" style="height:70px;" height="70"/>
            </b-card>
          </a>
        </b-col>
        <b-col sm="6" lg="3">
          <a href="/engagement/members">
            <b-card no-body class="bg-danger">
              <b-card-body class="pb-0">
                <h4 class="mb-0">{{membersCount}}</h4>
                <p>Members</p>
              </b-card-body>
              <card-bar-chart-example chartId="card-chart-04" class="chart-wrapper px-3" style="height:70px;" height="70"/>
            </b-card>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CardLine1ChartExample from '../dashboard/CardLine1ChartExample'
import CardLine2ChartExample from '../dashboard/CardLine2ChartExample'
import CardLine3ChartExample from '../dashboard/CardLine3ChartExample'
import CardBarChartExample from '../dashboard/CardBarChartExample'
export default {
    name: 'CommunityInformation',
    components: {
        CardLine1ChartExample,
        CardLine2ChartExample,
        CardLine3ChartExample,
        CardBarChartExample
    },
    data() {
        return {
            community: {},
            token: '',
            administrators: [],
            requestsCount: String,
            responsesCount: String,
            membersCount: String,
            satisfactionsAvg: String
        }
    },
    watch: {
        "$route": "loadData"
    },
    created() {
        if(!this.$route.params.community_id) {
            this.$router.push('/');
        }
        this.token = localStorage.getItem('token');
        this.loadData();
    },
    methods: {
        loadData() {
            this.administrators = [];
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/communities/${this.$route.params.community_id}`, {headers: {'x-access-token': this.token}}).then(async response => {
              if (response.data) {
                this.community = response.data;
                if(this.community.administrators && this.community.administrators.length) {
                    this.community.administrators.map(id => {
                        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/members/${id}`, {headers: {'x-access-token': this.token}}).then(response => {
                            if(response.data) {
                                this.administrators.push(response.data);
                            }
                        });
                    })
                }
              }
            }).catch(err => {
              if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
              }
            });
        }
    }
}
</script>
