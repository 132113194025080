<template>
  <!-- <router-view></router-view> -->
    <router-view :auth="auth" :authenticated="authenticated"></router-view>
</template>

<script>
import AuthService from "./services/AuthService";
const auth = new AuthService();
const { login, logout, authenticated, authNotifier } = auth;

export default {
    name: 'app',
    data() {
        authNotifier.on("authChange", authState => {
            this.authenticated = authState.authenticated;
        });
        return {
            auth,
            authenticated
        };
    },
    methods: {
        login,
        logout
    }
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  // global css file
  @import 'assets/css/main';
</style>
