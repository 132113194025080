export default{
    setTabindexOnTableCells() {
        setTimeout(()=> {
            const tables = document.querySelectorAll('table');
            tables.forEach(table => {
            const cells = table.querySelectorAll('td');
                cells.forEach(cell => {
                    cell.setAttribute('tabindex', '-1');
                });
            });
        }, 3000);
    },
    setPaginationAnnouncement(){
        setTimeout(()=>{
            const paginationButtons = document.querySelectorAll('.page-link');
            paginationButtons.forEach(button => {
                let innerHTML = button.innerHTML;
                if(innerHTML == '&lt;' || innerHTML == '&lt;&lt;'){
                    button.setAttribute('aria-label', innerHTML == '&lt;' ? 'Previous page':'Previous chunk')
                    button.setAttribute('role','button');
                } else if(innerHTML == '&gt;' || innerHTML == '&gt;&gt;'){
                    button.setAttribute('aria-label', innerHTML == '&gt;' ? 'Next page':'Next chunk')
                    button.setAttribute('role','button');
                } else {
                    if(button.classList.contains('active')){
                        button.setAttribute('aria-label', `Page ${innerHTML}, pressed`);
                    }else{
                        button.setAttribute('aria-label', `Page ${innerHTML}`);
                    }
                }
            })
        },3000);
    },
    setLabelForSettingIcons(){
        setTimeout(() => {
            const settingIcons = document.querySelectorAll('.icon-settings');
            settingIcons.forEach(icon => {
                icon.parentNode.setAttribute('role','button');
                icon.parentNode.setAttribute('aria-label','setting');
            })
        }, 3000);
    },
    setRoleButtonForSettingMenu(){
        setTimeout(() => {
            const settingMenuItems = document.querySelectorAll('.dropdown-item');
            settingMenuItems.forEach(item => {
                item.setAttribute('role','button');
            })
        }, 3000);
    }
} 