<template>
  <div>
    <form @submit.prevent="addSatisfaction">
        <b-row>
            <b-col md="6">
                <b-card>
                    <div slot="header">
                        <strong>Create A Satisfaction</strong> Elements
                    </div>
                    
                        <b-form-group
                            label="Satisfaction Customer:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="satisfactioncustomer" type="text" v-model="satisfaction.customer"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Satisfaction Member:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="satisfactionmember" type="text" v-model="satisfaction.member"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Feedback:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="satisfactionmark" type="number" v-model="satisfaction.marks"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Date" label-for="date"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input type="date" id="satisfactiondate"  v-model="satisfaction.date"></b-form-input>
                        </b-form-group>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-10"></div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary">Create</button>
                                </div>
                            </div>
                        </div>  
                </b-card>
            </b-col>
        </b-row>
    </form>
  </div>
</template>

<script>
export default {
    name: 'satisfactionsadd',
    data(){
        return {
        satisfaction:{}
        }
    },
    methods: {
        addSatisfaction(){
            let uri = `${process.env.VUE_APP_API_BASE_URL}/satisfactions/add`;
            this.axios.post(uri, this.satisfaction).then(() => {
                this.$router.push({name: 'Satisfactions'});
            });
        }   
    }
}
</script>