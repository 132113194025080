<template>
    <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header="Add User">
                        <b-card-body>
                            <b-form v-on:submit.prevent="addUser">
                                <b-form-group
                                label="Name:"
                                label-for="name"
                                >
                                    <b-form-input
                                    type="text"
                                    name="name"
                                    v-model="user.name"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('name')"
                                    placeholder="Enter name">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('name')">
                                    {{errors.first('name')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Email:"
                                label-for="email"
                                >
                                    <b-form-input
                                    type="text"
                                    name="email"
                                    v-model="user.email"
                                    v-validate="{ required: true, max:60, email: true, unique: true }"
                                    :state="validateState('email')"
                                    placeholder="Enter email"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('email')">
                                    {{errors.first('email')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <!-- <b-form-group
                                label="Password:"
                                label-for="password"
                                >
                                    <b-form-input
                                    type="password"
                                    name="password"
                                    v-model="user.password"
                                    v-validate="{ required: true, min:6 }"
                                    :state="validateState('password')"
                                    placeholder="Enter password">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('password')">
                                    {{errors.first('password')}}
                                    </b-form-invalid-feedback>
                                </b-form-group> -->
                                <b-form-group
                                label="Department:"
                                label-for="department"
                                >
                                    <b-form-input
                                    type="text"
                                    name="department"
                                    v-model="user.department"
                                    v-validate="{ required: true }"
                                    :state="validateState('department')"
                                    placeholder="Enter department">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('department')">
                                    {{errors.first('department')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Company:"
                                label-for="company"
                                >
                                    <b-form-input
                                    type="text"
                                    name="company"
                                    v-model="user.company"
                                    aria-describedby="input-1-live-feedback"
                                    v-validate="{ required: true }"
                                    :state="validateState('company')"
                                    placeholder="Enter company">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('company')">
                                    {{errors.first('company')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group label="Select Community:">
                                    <multiselect v-model="communityValues" name="community" v-validate="'required'" :options="communityOptions" :multiple="true" label="name" track-by="name" placeholder="Assign a community" @select="updateCircleSelect" @remove="removeCircles"></multiselect>
                                    <div class="selection-required" v-if="errors.has('community')">
                                        {{errors.first('community')}}
                                    </div>
                                </b-form-group>
                                <b-form-group label="Select Circle:">
                                    <multiselect v-model="circleValues" name="circle" :options="circleOptions" :multiple="true" label="name" track-by="name" placeholder="Assign a circle" :allow-empty="false"></multiselect>
                                    <!-- <div class="selection-required" v-if="errors.has('circle')">
                                        {{errors.first('circle')}}
                                    </div> -->
                                </b-form-group>
                                <b-form-group>
                                    <label class="mr-3 align-middle">Subscribe Weekly Newletter: </label>
                                    <input type="checkbox" v-model="newsletter.subscribed" name="newsletterSubscribed">
                                </b-form-group>
                                <b-button type="submit" variant="primary tab-focus">Add</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
export default {
    name: 'AddUser',
    components: { Multiselect },
    data() {
        return {
            communityValues: [],
            circleValues:[],
            communityOptions: [],
            circleOptions:[],
            token: '',
            user: {},
            communities: [],
            newsletter: {
                subscribed: false
            }
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        this.loadData();
        const isUnique = (value) => {
            return axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/check-email`, { userEmail: value }).then((response) => {
              // Notice that we return an object containing both a valid property and a data property.
              return {
                valid: response.data.success,
                data: {
                  message: response.data.message
                }
              };
            });
        };
        this.$validator.extend('unique', {
            validate: isUnique,
            getMessage: (field, params, data) => {
              return data.message;
            }
        });
        const dict = {
            custom: {
                name: {
                    required: "Please enter name.",
                    max: "name cannot be more than 60 characters.",
                },
                email: {
                    required: "Please enter email.",
                    max: "email cannot be more than 60 characters.",
                    email: 'Please enter valid email address.'
                },
                // password: {
                //     required: "Please enter password.",
                //     min: "password must be atleast 6 characters.",
                // },
                department: {
                    required: "Please enter department."
                },
                company: {
                    required: "Please enter company."
                },
                community: {
                    required: "Please select community."
                }//,
                // circle: {
                //     required: "Please select circle."
                // }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        loadData() {
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}}).then(response => {
                if(response.status === 200) {
                    if(Array.isArray(response.data)) {
                        this.communities = response.data;
                    }
                    if(this.communities.length) {
                        this.communityOptions = this.communities.map(community =>  {
                            var data = {};
                            data.name = community.name;
                            data.id = community._id;
                            data.additionalParams = community.additionalParams;
                            return data;
                        });
                    }
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });

        },
        addUser() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    var communities = this.communityValues.reduce((communities, communityValues) => {
                        communities.push(communityValues.id);
                        console.log('communities', communities)
                        return communities;
                    }, []);
                    var circles = this.circleValues.reduce((circles, circleValues) => {
                        circles.push(circleValues.short);
                        console.log('circles', circles)
                        return circles;
                    }, []);
                    this.user.newsletter = this.newsletter;
                    this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/create`, this.$lodash.merge(this.user, {communities: communities, circles: circles}), {headers: {'x-access-token': this.token}}).then(async response => {
                        this.$router.push('/dashboard/users');
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        updateCircleSelect(community) {
            let circles = [];
            circles = community.additionalParams;
            circles.forEach(element => this.circleOptions.push(element));
        },
        removeCircles(community) {
            let circle = [];
            circle = community.additionalParams;
            for (let i = 0; i < circle.length; i++){
                var index = this.circleOptions.indexOf(circle[i]);
                this.circleOptions.splice(index, 1);
                if (this.circleValues.length){
                    let code = [];
                    code = circle[i].short;
                    for (let j = 0; j < this.circleValues.length; j++){
                        var index2 = this.circleValues.indexOf(circle[i]);
                        if(index2 !== -1){
                            this.circleValues.splice(index2, 1);
                        }
                    }
                }
            }

        }
    }
}
</script>
