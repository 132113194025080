<template>
    <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header="Change Password">
                        <b-card-body>
                            <b-form v-on:submit.prevent="submit">
                                <b-alert :variant="alertvariant"
                                         dismissible
                                         :show="showDismissibleAlert"
                                         v-if="showDismissibleAlert"
                                         @dismissed="showDismissibleAlert=false">
                                  {{this.responses.message}}
                                </b-alert>
                                <b-form-group
                                label="Enter Current Password:"
                                label-for="password"
                                >
                                    <b-form-input
                                    type="password"
                                    name="password"
                                    v-model="password"
                                    v-validate="{ required: true, min:6 }"
                                    :state="validateState('password')"
                                    placeholder="Enter current password">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('password')">
                                    {{errors.first('password')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Enter New Password:"
                                label-for="password"
                                >
                                    <b-form-input
                                    type="password"
                                    name="new_password"
                                    v-model="new_password"
                                    v-validate="{ required: true, min:8 }"
                                    :state="validateState('new_password')"
                                    placeholder="Enter New password">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('new_password')">
                                    {{errors.first('new_password')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Confirm Password:"
                                label-for="password"
                                >
                                    <b-form-input
                                    type="password"
                                    name="confirm_password"
                                    v-model="confirm_password"
                                    v-validate="{ required: true, min:8, confirmed: new_password }"
                                    :state="validateState('confirm_password')"
                                    placeholder="Enter confirm password"
                                    data-vv-as="new_password">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('confirm_password')">
                                    {{errors.first('confirm_password')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-row>
                                    <b-col cols="6">
                                        <b-button type="submit" variant="primary" class="float-left">Submit</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "ChangePassword",
    data() {
        return {
            password: '',
            new_password: '',
            confirm_password: '',
            responses: '',
            showDismissibleAlert:false,
            token: '',
            alertvariant: ''
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        const dict = {
            custom: {
                password: {
                    required: "Please enter your current password.",
                    min: "password must be 6 characters long."
                },
                new_password: {
                    required: "Please enter your new password.",
                    min: "password must be 8 characters long."
                },
                confirm_password: {
                    required: "Please enter confirm password.",
                    min: "password must be 8 characters long.",
                    confirmed: 'password does not match'
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        submit: function() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/change-password`, {password: this.password, new_password: this.new_password }, {headers: {'x-access-token': this.token}}).then(response => {
                        if(response.data.success) {
                            this.responses = response.data;
                            this.alertvariant = 'success';
                            this.showDismissibleAlert=true;
                            setTimeout(() => {
                                this.$router.push('/dashboard');
                            }, 1000);
                        }else if(response.data.success == false){
                            this.responses = response.data;
                            this.alertvariant = 'danger';
                            this.showDismissibleAlert=true;
                        }
                    }).catch(err => {
                        if (err.response.status === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
              return !this.errors.has(ref);
            } else {
                return null;
            }
        }
    }
}
</script>
