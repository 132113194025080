<template>
    <div class="app flex-row align-items-center">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header="Edit User">
                        <b-card-body>
                            <b-form v-on:submit.prevent="submit">
                                <b-form-group
                                label="Name:"
                                label-for="name"
                                >
                                    <b-form-input
                                    type="text"
                                    name="name"
                                    v-model="user.name"
                                    placeholder="Enter name"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('name')"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('name')">
                                    {{errors.first('name')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Email:"
                                label-for="email"
                                >
                                    <b-form-input
                                    type="text"
                                    name="email"
                                    v-model="user.email"
                                    placeholder="Enter email"
                                    readonly
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('email')">
                                    {{errors.first('email')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Department:"
                                label-for="department"
                                >
                                    <b-form-input
                                    type="text"
                                    name="department"
                                    v-model="user.department"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('department')"
                                    placeholder="Enter department">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('department')">
                                    {{errors.first('department')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Company:"
                                label-for="company"
                                >
                                    <b-form-input
                                    type="text"
                                    name="company"
                                    v-model="user.company"
                                    aria-describedby="input-1-live-feedback"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('company')"
                                    placeholder="Enter company">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback" v-if="errors.has('company')">
                                    {{errors.first('company')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group label="Select Community:">
                                    <multiselect v-model="communityValues" name="community" v-validate="'required'" :options="communityOptions" :multiple="true" label="name" track-by="name" placeholder="Assign a community" @select="updateCircleSelect" @remove="removeCircles"></multiselect>
                                    <div class="selection-required" v-if="errors.has('community')">
                                        {{errors.first('community')}}
                                    </div>
                                </b-form-group>
                                <b-form-group label="Select Circle:">
                                    <multiselect v-model="circleValues" name="circle" :options="circleOptions" :multiple="true" label="name" track-by="name" placeholder="Assign a circle"></multiselect>
                                    <!-- <div class="selection-required" v-if="errors.has('circle')">
                                        {{errors.first('circle')}}
                                    </div> -->
                                </b-form-group>
                                <b-form-group
                                label="Member Id:"
                                label-for="memberID"
                                >
                                    <b-form-input
                                    type="text"
                                    name="memberID"
                                    v-model="user.memberID"
                                    v-validate="{ required: true, regex: /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i }"
                                    :state="validateState('memberID')"
                                    placeholder="Enter Member Id">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('memberID')">
                                    {{errors.first('memberID')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-button type="submit" variant="primary tab-focus">Update</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
export default {
    name: 'EditUser',
    components: { Multiselect },
    data() {
        return {
            userId: '',
            communityValues: [],
            circleValues: [],
            communityOptions: [],
            circleOptions: [],
            token: '',
            user: {},
            password: '',
            communities: [],
            circles: [],
            executeOnce: true,
            newsletter: {
                subscribed: false
            }
        }
    },
    created() {
        if(!this.$route.params.user_id) {
            this.$router.push('/');
        }
        this.userId = this.$route.params.user_id;
        this.token = localStorage.getItem('token');
        this.loadData();

        const dict = {
            custom: {
                name: {
                    required: "Please enter name.",
                    max: "name cannot be more than 60 characters.",
                },
                password: {
                    min: "password must be atleast 6 characters.",
                },
                department: {
                    required: "Please enter department."
                },
                company: {
                    required: "Please enter company."
                },
                community: {
                    required: "Please select community."
                },
                // circle: {
                //     required: "Please select circle."
                // },
                memberID: {
                    required: "Please enter member id.",
                    regex: "Please enter valid member id"
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    mounted() {
        const observeChanges = (targetNode) => {
            const config = { attributes: true, childList: true, subtree: true };
            const callback = (mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.setTabIndex(mutation);                        
                    }        
                }
            };
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        };
        const targetNodes = document.querySelectorAll('.multiselect__tags-wrap');
        targetNodes.forEach((node) => {
            observeChanges(node);
        });
    },
    methods: {
        async loadData() {
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}}).then(response => {
                if(Array.isArray(response.data)) {
                    this.communities = response.data;
                }
                if(this.communities.length) {
                    this.communityOptions = this.communities.map(community =>  {
                        var data = {};
                        data.name = community.name;
                        data.id = community._id;
                        data.additionalParams = community.additionalParams;
                        community.additionalParams.forEach(element => this.circles.push(element)); // THIS IS NEW CODE
                        return data;
                    });
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.userId}`, {headers: {'x-access-token': this.token}}).then(response => {
                if(response.data.user) {
                    this.user = response.data.user;
                    if (this.user.newsletter && this.user.newsletter.subscribed) {
                        this.newsletter = {
                            subscribed: true
                        }
                    } else {
                        this.newsletter = {
                            subscribed: false
                        }
                    }
                    if(this.user && this.user.communities && this.user.communities.length) {
                        // display selected community to a user
                        this.user.communities.map(userComm => {
                            var community = this.communities.filter(community => community._id === userComm);
                            if(community.length) {
                                this.communityValues.push({name: community[0].name, id: community[0]._id, additionalParams: community[0].additionalParams});
                                this.updateCircleSelect(community[0]); // THIS IS NEW CODE
                            }
                        });
                    }
                    if(this.user && this.user.circles && this.user.circles.length) {
                        // display selected circle to a user. THIS IS NEW CODE
                        this.user.circles.map(userCircles => {
                            var circles = this.circles.filter(circ => circ.short === userCircles);
                            if(circles.length) {
                                this.circleValues.push(circles[0]);
                            }
                        });
                    }
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        },
        submit: function() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    var communities = this.communityValues.map(communityValues => communityValues.id);
                    var circles = this.circleValues.map(circleValues => circleValues.short);
                    var payload = {
                        name: this.user.name,
                        department: this.user.department,
                        company: this.user.company,
                        communities: communities,
                        circles: circles,
                        password: this.password ? this.password : '',
                        memberID: this.user.memberID,
                        newsletter: this.newsletter
                    };
                    this.axios.put(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${this.userId}/update`, payload, {headers: {'x-access-token': this.token}}).then(response => {
                        if(response.data.success) {
                            this.$router.push({name: 'Dashboard Users'});
                        }
                    }).catch(err => {
                        if (err.response.status === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
              return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        updateCircleSelect(community) { // THIS IS NEW CODE
            let circle = [];
            circle = community.additionalParams;
            circle.forEach(element => this.circleOptions.push(element));
        },
        removeCircles(community) { // THIS IS NEW CODE
            let circle = [];
            circle = community.additionalParams;
            for (let i = 0; i < circle.length; i++){
                var index = this.circleOptions.indexOf(circle[i]);
                this.circleOptions.splice(index, 1);
                if (this.circleValues.length){
                    let code = [];
                    code = circle[i].short;
                    for (let j = 0; j < this.circleValues.length; j++){
                        var index2 = this.circleValues.indexOf(circle[i]);
                        if(index2 !== -1){
                            this.circleValues.splice(index2, 1);
                        }
                    }
                }
            }

        },
        setTabIndex(mutation) {
            let selectedTags = document.querySelectorAll('i.multiselect__tag-icon');
            selectedTags.forEach(tag => {
                tag.removeEventListener('mousedown', this.handleTagMouseDown);
                tag.removeEventListener('keypress', this.handleTagKeyPress);
                tag.setAttribute('tabindex', '0');                
                tag.setAttribute('role', 'button');
                tag.setAttribute('aria-label', 'Remove ' + tag.previousElementSibling.innerHTML);
                let message = 'Removed';

                this.handleTagMouseDown = () => {
                    this.removeTagAnnouncement(message);
                };
                this.handleTagKeyPress = (event) => {
                    if (event.key === 'Enter' && this.executeOnce) {
                        this.executeOnce = false;
                        this.removeTagAnnouncement(message);
                        setTimeout(() => { this.executeOnce = true; }, 1000);
                    }
                };
                tag.addEventListener('mousedown', this.handleTagMouseDown);
                tag.addEventListener('keypress', this.handleTagKeyPress);
            });
        },
        removeTagAnnouncement(message){
            const liveRegion = document.createElement('div');
            liveRegion.setAttribute('aria-live', 'polite');
            liveRegion.setAttribute('role', 'alert');
            liveRegion.innerText = message;
            document.body.appendChild(liveRegion);
            setTimeout(() => {
                liveRegion.remove();
            },1000);
        }
    }
}
</script>
