<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        class="img-avatar"
        id="blob-image"
        alt="admin@protopia.co" />
    </template>
    <!--<template slot="header" v-else>
      <img
        src="img/avatars/6.jpg"
        class="img-avatar"
        alt="admin@protopia.co" />
    </template>-->
    <template slot="dropdown">
      <div>
        <!-- <b-dropdown-item @click="edit">Edit User Profile</b-dropdown-item> -->
        <b-dropdown-item @click="$router.push('/dashboard/users/edit-profile')">Edit User Profile</b-dropdown-item>
        <!-- <b-dropdown-item @click="$router.push('/change-password')">Change Password</b-dropdown-item> -->
        <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
      </div>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { arrayBufferToBase64 } from '@/assets/js/common';
import EventBus from '@/assets/js/EventBus';
export default {
  name: 'DefaultHeaderDropdown',
  props: ["auth", "authenticated"],
  data() {
    return {
      authUser: {}
    }
  },
  components: {
    AppHeaderDropdown
  },
  created() {
    var user = localStorage.getItem('authUser');
    if(user) {
        this.authUser = JSON.parse(user);
    }
    EventBus.$on('change-profile', function(payload) {
      this.authUser = payload;
    }.bind(this));
    EventBus.$on('remove-profile', function(payload) {
      this.authUser = payload;
    }.bind(this));
  },
  watch: {
    "authUser": "getImageFromBuffer"
  },
  mounted() {
    this.getImageFromBuffer();
    EventBus.$on('change-profile', function(payload) {
      this.authUser = payload;
    }.bind(this));
  },
  methods: {
    logout: function() {
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
        localStorage.removeItem('access_token')
		localStorage.removeItem('id_token')
		localStorage.removeItem('expires_at')
		localStorage.removeItem("authUser");
        return this.$router.push('/login');
    },
    edit(){
      this.$router.push('/users/:user_id');
    },
    getImageFromBuffer: function() {
      var value = this.authUser && this.authUser.profilePhoto ? this.authUser.profilePhoto : '';
      if(value) {
        var b64encoded = arrayBufferToBase64(value.data.data);
        var datajpg = "data:image/jpg;base64," + b64encoded;
        var img = document.getElementById('blob-image');
        img.src = datajpg;
      } else {
        var img = document.getElementById('blob-image');
        img.src = "img/avatars/img_avatar.png";
      }
    }
  }
}
</script>
