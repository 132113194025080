import Vue from 'vue'
import Router from 'vue-router'

// Containers
import DefaultContainer from '../containers/DefaultContainer'

// Views
import Dashboard from '../views/Dashboard'
import Requests from '../views/engagement/requests'
import Responses from '../views/engagement/responses'
import Satisfactions from '../views/engagement/satisfactions'
import Members from '../views/engagement/members'
import Requestsadd from '../views/components/requests/CreateRequestComponent'
import Requestsedit from '../views/components/requests/EditRequestComponent'
import Responsesadd from '../views/components/responses/CreateResponseComponent'
import Responsesedit from '../views/components/responses/EditResponseComponent'
import Satisfactionsadd from '../views/components/satisfactions/CreateSatisfactionComponent'
import Satisfactionsedit from '../views/components/satisfactions/EditSatisfactionComponent'
import Membersadd from '../views/components/members/CreateMemberComponent'
import Membersedit from '../views/components/members/EditMemberComponent'
import Login from '../views/login/Login'
import DashboardUsers from '../views/components/UserManagement/DashboardUsers'
import EditUser from '../views/components/UserManagement/EditUser'
import CommunityInfo from '../views/Community/CommunityInfo'
import EngagementScoringModel from '../views/engagement/EngagementScoringModel'
import EngagementStream from '../views/engagement/EngagementStream'

import MemberDetails from '../views/members/MemberDetails'
import RequestDetail from '../views/components/requests/RequestDetail'
import IncompleteRequestDetail from '../views/components/requests/IncompleteRequestDetail'
import AddUser from '../views/components/UserManagement/AddUser'
import UserProfile from '../views/components/UserManagement/UserProfile'
import ResetPassword from '../views/password/ResetPassword'
import ForgotPassword from '../views/password/ForgotPassword'
import ChangePassword from '../views/password/ChangePassword'
import EmailEngagement from '../views/reports/emailEngagement'
// import ExtraAttentionList from '../views/members/ExtraAttentionList';
import requestsOverDue from '../views/engagement/requestsOverDue'
import requestsIncomplete from '../views/engagement/requestsIncomplete'
import Library from '../views/components/library/List'
import AddLibrary from '../views/components/library/AddLibrary'
import EditLibrary from '../views/components/library/EditLibrary'
import Resources from '../views/components/resources/List'
import AddResource from '../views/components/resources/AddResource'
import EditResource from '../views/components/resources/EditResource'
import SftpFileStatusUploads from '../views/components/uploads-sftp-files/SftpFileStatusUploads'
import requestsApproval from '../views/engagement/requestsApproval'
import responsesApproval from '../views/engagement/responsesApproval'
import requestsReject from '../views/engagement/requestsReject'
import responsesReject from '../views/engagement/responsesReject'
import RequestStory from '../views/components/requests/RequestStory'
import RequestsWithTag from '../views/engagement/RequestsWithTag'
import RespondersMatchedToTag from '../views/engagement/RespondersMatchedToTag'
import Referrals from '../views/engagement/referrals'
import Search from '../views/components/search'
import Analytics from '../views/components/analytics'
import TagsByOccurrence from '../views/components/requests/TagsByOccurrence'
import RequestEngagement from '../views/components/responses/requestEngagement'
import ResponseType from '../views/components/responses/responseType'
import Callback from '../views/login/Callback'
import RequestCoach from '../views/components/requests/RequestCoach'
import RequestResponseCoach from '../views/components/requests/RequestResponseCoach'
import MemberRank from '../views/reports/MemberRank';
import Reports from '../views/reports/Reports';

import AdvancedSearchMembers from '../views/engagement/advancedSearch'

Vue.use(Router)

export default new Router({
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'open active',
  //scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: 'Protopia Engagement Dashboard'
          }
        },
        {
          path: '/engagement/requests',
          name: 'Requests',
          component: Requests,
          meta: {
            requiresAuth: true,
            title: 'Requests'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/requests/recent-requests-with-tag',
          name: 'Recent Requests with Tag',
          component: RequestsWithTag,
          meta: {
            requiresAuth: true,
            title: 'Recent requests with '
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/responses/responders-matched-to-tag',
          name: 'Responders matched to Tag',
          component: RespondersMatchedToTag,
          meta: {
            requiresAuth: true,
            title: 'Accepted Responses with '
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/requests-overdue',
          name: 'Requests Overdue',
          component: requestsOverDue,
          meta: {
            requiresAuth: true,
            title: 'Requests Overdue'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/incomplete-requests',
          name: 'Incomplete Requests',
          component: requestsIncomplete,
          meta: {
            requiresAuth: true,
            title: 'Incomplete Requests'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/requests-approval',
          name: 'Requests Approval',
          component: requestsApproval,
          meta: {
            requiresAuth: true,
            title: 'Requests Approval'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },{
          path: '/engagement/responses-approval',
          name: 'Responses Approval',
          component: responsesApproval,
          meta: {
            requiresAuth: true,
            title: 'Responses Approval'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/rejected-requests',
          name: 'Rejected Requests',
          component: requestsReject,
          meta: {
            requiresAuth: true,
            title: 'Rejected Requests'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },{
          path: '/engagement/rejected-responses',
          name: 'Rejected Responses',
          component: responsesReject,
          meta: {
            requiresAuth: true,
            title: 'Rejected Responses'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/responses',
          name: 'Responses',
          component: Responses,
          meta: {
            requiresAuth: true,
            title: 'Responses'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/feedback',
          name: 'Feedback',
          component: Satisfactions,
          meta: {
            requiresAuth: true,
            title: 'Feedback'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/referrals',
          name: 'Referrals',
          component: Referrals,
          meta: {
            requiresAuth: true,
            title: 'Referral'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/members',
          name: 'Members',
          component: Members,
          meta: {
            requiresAuth: true,
            title: 'Members'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/members/advanced-search',
          name: 'Advanced Search Members',
          component: AdvancedSearchMembers,
          meta: {
            requiresAuth: true,
            title: 'Advanced Search Members'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/library',
          name: 'Library',
          component: Library,
          meta: {
            requiresAuth: true,
            title: 'Library'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/uploads',
          name: 'Uploads',
          component: SftpFileStatusUploads,
          meta: {
            requiresAuth: true,
            title: 'Uploads'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/library/create',
          name: 'Add Library',
          component: AddLibrary,
          meta: {
            requiresAuth: true,
            title: 'Add Library'
          },
          beforeEnter: (to, from, next) => {
           isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/library/:library_id',
          name: 'Edit Library',
          component: EditLibrary,
          meta: {
            requiresAuth: true,
            title: 'Edit Library'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/resources',
          name: 'Resources',
          component: Resources,
          meta: {
            requiresAuth: true,
            title: 'Resources'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/resources/create',
          name: 'Add Resource',
          component: AddResource,
          meta: {
            requiresAuth: true,
            title: 'Add Resource'
          },
          beforeEnter: (to, from, next) => {
           isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/resources/:resource_id',
          name: 'Edit Resource',
          component: EditResource,
          meta: {
            requiresAuth: true,
            title: 'Edit Resource'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/dashboard/search',
          name: 'Search',
          component: Search,
          meta: {
            requiresAuth: true,
            title: 'Search'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/analytics/:slug',
          name: 'Analytics',
          component: Analytics,
          meta: {
            requiresAuth: true,
            title: 'Analytics'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/requests/add',
          name: 'Requestsadd',
          component: Requestsadd,
          meta: {
            requiresAuth: true,
            title: 'Add requests'
          }
        },
        {
          path: '/requests/edit',
          name: 'Requestsedit',
          component: Requestsedit,
          meta: {
            requiresAuth: true,
            title: 'Edit Requests'
          }
        },
        {
          path: '/responses/add',
          name: 'Responsesadd',
          component: Responsesadd,
          meta: {
            requiresAuth: true,
            title: 'Add Responses'
          }
        },
        {
          path: '/responses/edit',
          name: 'Responsesedit',
          component: Responsesedit,
          meta: {
            requiresAuth: true,
            title: 'Edit Responses'
          }
        },
        {
          path: '/satisfactions/add',
          name: 'Satisfactionsadd',
          component: Satisfactionsadd,
          meta: {
            requiresAuth: true,
            title: 'Add Satisfaction'
          }
        },
        {
          path: '/satisfactions/edit',
          name: 'Satisfactionsedit',
          component: Satisfactionsedit,
          meta: {
            requiresAuth: true,
            title: 'Edit Satisfaction'
          }
        },
        {
          path: '/reports/email-engagement',
          name: 'Email Engagement',
          component: EmailEngagement,
          meta: {
            requiresAuth: true,
            title: 'Email Engagement'
          }
        },
        // {
        //   path: '/reports/engagement-detail',
        //   name: 'Interaction',
        //   component: EngagementDetail,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Interaction'
        //   }
        // },
        // {
        //   path: '/reports/member-opt-out',
        //   name: 'Member Opt-out Report',
        //   component: MemberOptOut,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Member Opt-out Report'
        //   }
        // },
        // {
        //   path: '/reports/overdue-request',
        //   name: 'Overdue Request Report',
        //   component: OverdueRequest,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Overdue Request Report'
        //   }
        // },
        // {
        //   path: '/reports/no-follow-up',
        //   name: 'No Follow Up Report',
        //   component: NoFollowUp,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'No Follow Up Report'
        //   }
        // },
        // {
        //   path: '/reports/donor-engagement',
        //   name: 'Donor Engagement Report',
        //   component: DonorEngagement,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Donor Engagement Report'
        //   }
        // },
        // {
        //   path: '/reports/invalid-emails',
        //   name: 'Invalid Emails Report',
        //   component: InvalidEmail,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Invalid Emails Report'
        //   }
        // }, 
        {
          path: '/reports/member-rank',
          name: 'Member Rank Report',
          component: MemberRank,
          meta: {
            requiresAuth: true,
            title: 'Member Rank Report'
          }
        }, {
          path: '/members/add',
          name: 'Members add',
          component: Membersadd,
          meta: {
            requiresAuth: true,
            title: 'Add Members'
          }
        },
        // {
        //   path: '/members/extra-attention-list',
        //   name: 'Extra Attention List',
        //   component: ExtraAttentionList,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Extra Attention List'
        //   }
        // },
        {
          path: '/members/edit',
          name: 'Members edit',
          component: Membersedit,
          meta: {
            requiresAuth: true,
            title: 'Edit Members'
          }
        },
        {
          path: '/dashboard/users',
          name: 'Dashboard Users',
          component: DashboardUsers,
          meta: {
            requiresAuth: true,
            title: 'Dashboard Users'
          },
          beforeEnter: (to, from, next) => {
            checkRole(to,from,next);
          }
        },
        {
          path: '/dashboard/users/create',
          name: 'Add User',
          component: AddUser,
          meta: {
            requiresAuth: true,
            title: 'Add Users'
          }
        },
        {
          path: '/dashboard/users/edit-profile',
          name: 'User Profile',
          component: UserProfile,
          meta: {
            requiresAuth: true,
            title: 'User Profile'
          }
        },
        {
          path: '/dashboard/users/:user_id',
          name: 'Edit User',
          component: EditUser,
          meta: {
            requiresAuth: true,
            title: 'Edit Users'
          }
        },
        {
          path: '/dashboard/communities/:community_id',
          name: 'Community Information',
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: 'Community Information'
          }
        },
        {
            path: '/engagement/tags_by_occurrence',
            name: 'Tags by Occurrence',
            component: TagsByOccurrence,
            meta: {
                requiresAuth: true,
                title: 'Tags by Occurrence'
            },
            beforeEnter: (to, from, next) => {
                isLoggedIn(to,from,next);
            }
        },
        {
            path: '/engagement/request-engagement',
            name: 'Request Engagement',
            component: RequestEngagement,
            meta: {
                requiresAuth: true,
                title: 'Request Engagement'
            },
            beforeEnter: (to, from, next) => {
                isLoggedIn(to,from,next);
            }
        },
        {
            path: '/engagement/response-type',
            name: 'Response Type',
            component: ResponseType,
            meta: {
                requiresAuth: true,
                title: 'Response Type'
            },
            beforeEnter: (to, from, next) => {
                isLoggedIn(to,from,next);
            }
        },
        {
          path: '/dashboard/engagement-score',
          name: 'Engagement Scoring Model',
          component: EngagementScoringModel,
          meta: {
            requiresAuth: true,
            title: 'Engagement Scoring Model'
        },beforeEnter: (to, from, next) => {
          checkRole(to,from,next);
        }
        },
        {
          path: '/engagement/stream',
          name: 'Stream',
          component: EngagementStream,
          meta: {
            requiresAuth: true,
            title: 'Engagement Stream'
          }
        },
        {
          path: '/dashboard/reports',
          name: 'Reports',
          component: Reports,
          meta: {
            requiresAuth: true,
            title: 'Reports'
          }
        },
        {
          path: '/engagement/members/:member_id',
          name: 'MemberDetails',
          component: MemberDetails,
          meta: {
            requiresAuth: true,
            title: 'Member Details'
          }
        },
        {
          path: '/engagement/requests/:request_id',
          name: 'RequestDetail',
          component: RequestDetail,
          meta: {
            requiresAuth: true,
            title: 'Request Detail'
          }
        },
        {
          path: '/engagement/incomplete-requests/:request_id',
          name: 'IncompleteRequestDetail',
          component: IncompleteRequestDetail,
          meta: {
            requiresAuth: true,
            title: 'Incomplete Request Detail'
          }
        },
        {
            path: '/engagement/requests-coach/:request_id',
            name: 'Request Coach',
            component: RequestCoach,
            meta: {
                requiresAuth: true,
                title: 'Request Coaching'
            }
        },
        {
            path: '/engagement/requests-response-coach/:request_id/:give_id',
            name: 'Response Coach',
            component: RequestResponseCoach,
            meta: {
                requiresAuth: true,
                title: 'Response Coaching'
            }
        },
        {
          path: '/engagement/requests/:request_id/story',
          name: 'Request Story',
          component: RequestStory,
          meta: {
            requiresAuth: true,
            title: 'Request Story'
          }
        },
        {
          path: '/engagement/requests/community/:community_id',
          name: 'CommunityRequests',
          component: Requests,
          meta: {
            requiresAuth: true,
            title: 'Community Requests'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/responses/community/:community_id',
          name: 'CommunityResponses',
          component: Responses,
          meta: {
            requiresAuth: true,
            title: 'Community Responses'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/feedback/community/:community_id',
          name: 'CommunityFeedback',
          component: Satisfactions,
          meta: {
            requiresAuth: true,
            title: 'Community Feedback'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/engagement/members/community/:community_id',
          name: 'CommunityMembers',
          component: Members,
          meta: {
            requiresAuth: true,
            title: 'Community Members'
          },
          beforeEnter: (to, from, next) => {
            isLoggedIn(to,from,next);
          }
        },
        {
          path: '/change-password',
          name: 'ChangePassword',
          component: ChangePassword,
          meta: {
            requiresAuth: true,
            title: 'Change Password'
          }
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Dashboard Login'
      }
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
      meta: {
        title: 'Dashboard Callback'
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        title: 'Forgot Password'
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        title: 'Reset Password'
      }
    }
  ]
})

export const isLoggedIn = function(to, from, next) {
	if(localStorage.getItem('authUser') != null)// && localStorage.getItem('token') != null
	{
    // if(to.matched.some(record => record.name  == 'Login')) {
    //   next({name: 'Dashboard'});
    // } else {
    //   next();
    // }
        next();
	} else {
    	next({name: 'Login'});
	}
};

function checkRole(to, from, next) {
  if(localStorage.getItem('authUser')) {
    var user = JSON.parse(localStorage.getItem('authUser'));
    if(user.role && user.role == 'SuperAdmin') {
      next();
    } else {
      next({name: 'Dashboard'});
    }
  } else {
    next({name: 'Login'});
  }
}
