<template>
  <button
    :display="display"
    :mobile="mobile"
    :class="classList"
    type="button"
    :aria-label="sidebarVisible ? 'Show sidebar' : 'Hide sidebar'"
    aria-live="polite"
    @click="sidebarToggle">
    <span class="navbar-toggler-icon"/>
  </button>
</template>

<script>
import { sidebarCssClasses, validBreakpoints, checkBreakpoint } from '../../shared/classes'
import toggleClasses from '../../shared/toggle-classes'

export default {
  name: 'SidebarToggler',
  props: {
    defaultOpen: {
      type: Boolean,
      default: true
    },
    display: {
      type: String,
      default: 'lg'
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sidebarVisible: this.mobile ? this.defaultOpen : this.mobile
    }
  },
  computed: {
    classList () {
      return [
        'navbar-toggler'
      ]
    }
  },
  mounted: function () {
    this.toggle(this.defaultOpen)
  },
  methods: {
    toggle (force) {
      const [display, mobile] = [this.display, this.mobile]
      let cssClass = sidebarCssClasses[0]
      if (!mobile && display && checkBreakpoint(display, validBreakpoints)) {
        cssClass = `sidebar-${display}-show`
      }
      toggleClasses(cssClass, sidebarCssClasses, force)
    },
    sidebarToggle (e) {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
      this.sidebarVisible = !this.sidebarVisible;
      this.sidebarToggleAnnouncement(this.sidebarVisible);
    },
    sidebarToggleAnnouncement(sidebarVisible) {
      const message = sidebarVisible ? 'Sidebar Hidden' : 'Sidebar Visible';
      const liveRegion = document.createElement('div');
      liveRegion.setAttribute('aria-live', 'polite');
      liveRegion.setAttribute('role', 'alert');
      liveRegion.innerText = message;
      document.body.appendChild(liveRegion);
      setTimeout(() => {
        liveRegion.remove();
      },1000);
    }
  }
}
</script>
