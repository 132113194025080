<template>
  <div>
    <form @submit.prevent="addMember">
        <b-row>
            <b-col md="6">
                <b-card>
                    <div slot="header">
                        <strong>Create A Member</strong> Elements
                    </div>
                    
                        <b-form-group
                            label="Member Customer:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="membercustomer" type="text" v-model="member.customer"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Member Member:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="membermember" type="text" v-model="member.member"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Member title:"
                            label-for="basicName"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input id="membermark" type="text" v-model="member.title"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Date" label-for="date"
                            :label-cols="3"
                            :horizontal="true">
                            <b-form-input type="date" id="memberdate"  v-model="member.date"></b-form-input>
                        </b-form-group>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-10"></div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary">Create</button>
                                </div>
                            </div>
                        </div>  
                </b-card>
            </b-col>
        </b-row>
    </form>
  </div>
</template>

<script>
export default {
    name: 'membersadd',
    data(){
        return {
        member:{}
        }
    },
    methods: {
        addMember(){
            let uri = `${process.env.VUE_APP_API_BASE_URL}/newmembers/add`;
            this.axios.post(uri, this.member).then(() => {
                this.$router.push({name: 'Members'});
            });
        }   
    }
}
</script>