<template>
    <div class="mb-50">
        <div class="row">
            <div class="col-12">
                <div class="card-body">
                    <div class="request-block mb-5">
                        <div class="row">
                            <div class="col-md-7 col-lg-8">
                               <h1 v-if="activityData.subject">Request: {{activityData.subject}}</h1>
                            </div>
                            <div class="col-md-5 col-lg-4 text-right">
                                <ul class="mb-0 list-inline">
                                    <!-- <li class="list-inline-item">
                                        <b-form-select v-model="selectedAction" v-on:change="filterByAction" class="custom-card-header-filter story-cil-list">
                                           <option value="accepted" selected>Accepted Responses</option>
                                           <option value="declined">Declined Responses</option>
                                           <option value="no">No Responses</option>
                                           <option value="all">All Responses</option>
                                        </b-form-select>
                                    </li> -->
                                    <li class="list-inline-item">
                                        <button type="button" class="btn custom-card-header-filter btn-dropbox tab-focus"  size="sm" @click="$router.push(`/engagement/requests/${$route.params.request_id}`)" v-b-tooltip.hover.bottom aria-label="View the request" >
                                            <i class="cil-list"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="story-block">
                        <div class="main-media media">
                            <img src="story_icon/story_question.png" v-if="activityData.createdByMember" class="media-img light-pink" alt="user" />
                            <div class="media-body">
                                <h6 class="media-title">
                                    <a href="javascript:void(0)" class="member-link" @click="goToProfile(activityData.createdByMemberID)"> {{activityData.createdByMember}} </a> <span v-if="activityData.createdAt"> {{checkDate(activityData.createdAt)}}</span></h6>
                                <p class="white-space">{{activityData.text}}</p>
                            </div>
                        </div>

                        <div class="main-media media" v-for="(activity,index) in activityData.adviserData" :key="index" v-if="activity.isDisplay">
                            <img src="story_icon/story_advice.png" class="media-img green" v-if="activity.helpaction == 'yes' && activity.actionFrom =='advice'" alt="user" />
                            <img src="story_icon/story_call.png" class="media-img green" v-if="activity.helpaction == 'yes' && activity.actionFrom =='call'" alt="user" />
                            <img src="story_icon/story_meeting.png" class="media-img green" v-if="activity.helpaction == 'yes' && activity.actionFrom =='meeting'" alt="user" />
                            <img src="story_icon/story_decline.png" class="media-img pink" v-if="activity.giveType == 'declined' && !activity.isReferralGive" alt="user" />
                            <img src="story_icon/story_matching.png" class="media-img green" v-if="(activity.helpaction == 'no' && activity.isReferralGive) || activity.matchAdviserBy" alt="user" />
                            <img src="story_icon/story_noresponse.png" class="media-img gray" v-if="activity.noResponse" alt="user" />
                            <img src="story_icon/snooze.png" class="media-img green" v-if="activity.adviserSnooze" alt="user" />
                            <div class="media-body">
                                <h6 class="media-title" :class="{ 'pb-15': activity.helpaction == 'no' && activity.isReferralGive }">
                                    <a href="javascript:void(0)" class="member-link" @click="goToProfile(activity.memberId)" v-if="activity.memberId"> {{activity.name}} </a>
                                    <a href="javascript:void(0)" v-if="!activity.memberId && activity.name"> {{activity.name}} </a>
                                    <a href="javascript:void(0)" v-if="activity.advisorEmail"> {{activity.advisorEmail}} </a>
                                    <!-- <span class="percentage" v-if="typeof activity.score == 'number'">{{activity.score}}%</span> -->
                                    <span class="new" v-if="activity.newAdvisor">New</span>
                                    <span v-if="activity.when">{{checkDate(activity.when)}}</span></h6>
                                <p v-if="activity.insight" class="white-space">{{activity.insight}}</p>
                                <p v-if="activity.helpaction == 'no' && !activity.isReferralGive">"{{activity.action}}"</p>
                                <p v-if="activity.helpaction == 'no' && activity.isReferralGive">
                                    <span class="media-img gray" v-if="activity.isReferralGive">
                                        <img src="story_icon/story_referral.png" alt="user" />
                                    </span>
                                    Referred   {{activity.referralRecipientEmail}}
                                </p>
                                <p v-if="activity.phoneNo">Call me: {{activity.phoneNo}} (text is okay)</p>
                                <p v-if="activity.callMessage" class="white-space">{{activity.callMessage}}</p>
                                <p v-if="activity.tagData && activity.tagData.status" class="white-space">Tagg edited: {{activity.tagData.tag}}</p>
                                <p v-if="activity.matchAdviserBy" class="white-space">Adviser {{activity.matchAdviserBy.status == 'Hermes' ? 'Match' : activity.matchAdviserBy.status}} by: <span class="display-link" @click="goToProfile(activity.matchAdviserBy.id)" v-if="activity.matchAdviserBy.id"> {{activity.matchAdviserBy.name}}</span><span v-else> {{activity.matchAdviserBy.name}}</span>
                                </p>
                                <p v-if="activity.sendRequestToAdviser" class="white-space">Send request to adviser</p>
                                <p v-if="activity.approvalStatus" class="white-space">{{activity.approvalStatus}}</p>
                                <div v-for="(innerData,indexInner) in activity.innerData" :key="indexInner">
                                    <p class="blockquote" v-if="innerData.type == 'adviserFeedback'">
                                        <i>
                                            <span class="display-link" v-if="innerData.memberId" @click="goToProfile(innerData.memberId)">{{innerData.displayName}}</span><span v-else>{{innerData.email}}
                                            </span> rated the request a {{innerData.feedbackScore +'/10'}} <span v-if="innerData.feedbackComment" class="white-space">"{{innerData.feedbackComment}}"</span></i>{{checkDate(innerData.when)}}
                                    </p>
                                    <p class="blockquote" v-if="innerData.type == 'requesterFeedback'">
                                        <i>
                                            <span class="display-link" v-if="innerData.memberId" @click="goToProfile(innerData.memberId)">{{innerData.displayName}}</span><span v-else>{{innerData.email}}</span> rated the response a {{innerData.feedbackScore +'/10'}}<span v-if="innerData.feedbackComment" class="white-space"> "{{innerData.feedbackComment}}"</span></i>{{checkDate(innerData.when)}}
                                    </p>
                                    <div class="sub-media media pb-15" v-if="innerData.type == 'updateProfile'">
                                        <img src="story_icon/story_profileupdate.png" class="media-img lightblue" alt="user" />
                                        <div class="media-body">
                                            <span>Updated profile </span> <span class="fs-12 ml-7"> {{checkDate(innerData.when)}}</span>
                                        </div>
                                    </div>
                                    <p class="blockquote" v-if="!activity.thankYou.responseComment && innerData.type =='followUp'">
                                        <i v-if="innerData.action === 'yes'">
                                            <span class="display-link" @click="goToProfile(activityData.createdByMemberID)">{{activityData.createdByMember}}</span> confirmed that followed up with <span class="display-link" @click="goToProfile(activity.memberId)" v-if="activity.memberId">{{activity.name}}</span><span else>{{activity.advisorEmail}}</span>
                                        </i><i v-if="innerData.action === 'no'">
                                            <span class="display-link" @click="goToProfile(activityData.createdByMemberID)">{{activityData.createdByMember}}</span> has not yet followed up: "{{innerData.reason}}"
                                        </i><span v-if="innerData.when">{{checkDate(innerData.when)}}</span>
                                    </p>

                                    <div class="sub-media media mt-2" v-if="innerData.type == 'thankYou'">
                                        <img src="story_icon/story_thankyou.png" class="media-img tyIcon" alt="user"/>
                                        <div class="media-body">
                                            <h6 class="media-title"><a href="javascript:void(0)" class="member-link" @click="goToProfile(activityData.createdByMemberID)"> {{activityData.createdByMember}} </a><span class="fs-12" v-if="innerData.when"> {{checkDate(innerData.when)}}</span></h6>
                                            <p class="white-space">{{innerData.responseComment}}</p>
                                            <p class="blockquote" v-if="activity.followUp.action">
                                                <i v-if="activity.followUp.action === 'yes'">
                                                    <span class="display-link" @click="goToProfile(activityData.createdByMemberID)">{{activityData.createdByMember}}</span> confirmed that followed up with <span class="display-link" @click="goToProfile(activity.name)">{{activity.name}}</span>
                                                </i><i v-if="activity.followUp.action === 'no'">
                                                    <span class="display-link" @click="goToProfile(activityData.createdByMemberID)">{{activityData.createdByMember}}</span> has not yet followed up: "{{activity.followUp.reason}}"
                                                </i><span v-if="activity.followUp.when">{{checkDate(activity.followUp.when)}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="story-block text-center" v-if="activityData.reqCloseDate">
                        <h6 class="footer-text">Request was closed on {{closeRequestDate(activityData.reqCloseDate)}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications'

export default {
    name: 'RequestStory',
    data() {
        return {
            activityData: {},
            selectedAction: 'all'
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/${this.$route.params.request_id}/request-story/?action=${this.selectedAction}`, {headers: {'x-access-token': this.token}}).then(async request => {
                if (request.data) {
                    this.activityData = request.data;
                }
            });
        },
        checkDate(value) {
            return this.$moment(value).tz("America/New_York").format('MMMM Do, h:mm A');
        },
        closeRequestDate(value) {
            return this.$moment(value).tz("America/New_York").format('MMMM D YYYY, h:mm A');
        },
        filterByAction() {
            this.loadData();
        },
        goToProfile(Id){
            let route = this.$router.resolve({path: `/engagement/members/${Id}`});
            window.open(route.href, '_blank');
        }
    }
}
</script>
