<template>
  <div>
    <h1>Edit Request</h1>
    <form @submit.prevent="updateRequest">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Request Customer:</label>
            <input type="text" class="form-control" v-model="request.customer">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Request Member:</label>
            <input type="text" class="form-control" v-model="request.member">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Request Title:</label>
            <input type="text" class="form-control" v-model="request.title">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Request Date:</label>
            <input type="text" class="form-control" v-model="request.date">
          </div>
        </div>
      </div>
        <br />
        <div class="form-group">
          <button class="btn btn-primary">Update</button>
        </div>
    </form>
  </div>
</template>

<script>
    export default {
      name: 'requestsedit',
      data() {
        return {
          request: {}
        }
      },
      created() {
        let uri = `${process.env.VUE_APP_API_BASE_URL}/requests/edit/${this.$route.params.id}`;
        this.axios.get(uri).then((response) => {
            this.request = response.data;
        });
      },
      methods: {
        updateRequest() {
          let uri = `${process.env.VUE_APP_API_BASE_URL}/requests/update/${this.$route.params.id}`;
          this.axios.post(uri, this.request).then(() => {
            this.$router.push({name: 'Requests'});
          });
        }
      }
    }
</script>