<template>
    <div>
    <b-card class="admins-list">
      <template slot="header">
        <h3>Users</h3>
        <b-button variant="dropbox tab-focus" v-on:click="$router.push('users/create')">Add User</b-button>
      </template>
      <b-card-body>
        <v-client-table v-bind:data="users" v-bind:columns="columns" :options="options" id="dataTable" class="user-list">
            <span slot="action" slot-scope="props">
                <b-button class="btn-space tab-focus" aria-label="Edit User" variant="dropbox" size="sm" v-on:click="$router.push(`/dashboard/users/${props.row._id}`)"><i class="fa fa-edit"></i></b-button>
                <b-button class="btn-space tab-focus" aria-label="Remove User" variant="dropbox" size="sm" v-on:click="deleteUser(props.row._id)"><i class="fa fa-remove"></i></b-button>
            </span>
            <span slot="cron priority" slot-scope="props" class="d-table-center">
                <c-switch color="success" title="Priority Reports" @change.native="changeStatus(props.row.download_report_priority, props.row._id)" name="downloadReportPriority" v-model="props.row.download_report_priority" label :aria-checked="props.row.download_report_priority ? 'true':'false'" variant="pill" size="sm" class="float-right cron-priority-switch"/>
            </span>
            <span slot="newsletter" slot-scope="props" class="d-table-center">
              <c-switch color="success" title="Newsletter" @change.native="changeNewsletterStatus(props.row.newsletter.subscribed, props.row._id)" name="subscribeNewsletter" v-model="props.row.newsletter.subscribed" label :aria-checked="props.row.newsletter.subscribed ? 'true':'false'" variant="pill" size="sm" class="float-right newsletter-switch"/>
            </span>

        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue';
import VueNotifications from 'vue-notifications'

export default {
    name: 'DashboardUsers',
    components: {
      cSwitch
    },
    data() {
        return {
            users: [],
            columns: ['name', 'email', 'role', 'cron priority', 'newsletter', 'action'],
            token: '',
            options: {
              sortable: ['name', 'email', 'role'],
              sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
              pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
              }
          },
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        this.loadData();
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success
        }
    },
    methods: {
      async loadData() {
        await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users`, {headers: {'x-access-token': this.token}}).then(async response => {
          if (response.data.users) {
            this.users = response.data.users;
          }
        }).catch(err => {
          if (err.response.status  === 401) {
            localStorage.removeItem('authUser');
            this.$router.push('/login');
          }
        });
        this.$helpers.setTabindexOnTableCells();
        this.setAttributesToCswitch();
      },
      deleteUser: function(id) {
        this.axios.delete(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${id}/remove`, {headers: {'x-access-token': this.token}}).then(async response => {
          if(response.data.success) {
            this.loadData();
            this.showSuccessMsg({message: 'User has been removed'})
            this.announceMessage("User has been removed");
          }

        }).catch(err => {
          if (err.response.status  === 401) {
            localStorage.removeItem('authUser');
            this.$router.push('/login');
          }
        });
      },
      announceMessage(message) {
          const liveRegion = document.createElement('div');
          liveRegion.setAttribute('aria-live', 'assertive');
          liveRegion.setAttribute('role', 'alert');
          liveRegion.innerText = message;
          document.body.appendChild(liveRegion);
          setTimeout(() => {
            liveRegion.remove();
          },1000)
      },
      changeStatus: function(status, id) {
          this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${id}/update-priority-status`, {status: status}, {headers: {'x-access-token': this.token}}).then(response => {
          }).catch(err => {
              if (err.response.status === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          this.setAttributesToCswitch();
      },
      changeNewsletterStatus: function(status, id) {
        console.log(status, id);
          this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${id}/update-newsletter-status`, {status: status}, {headers: {'x-access-token': this.token}}).then(response => {
          }).catch(err => {
              if (err.response.status === 401) {
                  localStorage.removeItem('authUser');
                  this.$router.push('/login');
              }
          });
          this.setAttributesToCswitch();
      },
      setAttributesToCswitch() {
            const Cswitch = document.querySelectorAll('.user-list .switch-slider');
            const keypressHandler = function(event) {
            if (event.key === 'Enter') {
                const parentNodeClasslist = event.target.parentNode.classList;
                  if(parentNodeClasslist.contains('cron-priority-switch')){
                    event.target.click();
                  } else if(parentNodeClasslist.contains('newsletter-switch')){
                    event.target.click();
                  }               
                }
            };
            function handleClick(event) {
              const switchElement = event.currentTarget;
              const parentNode = switchElement.parentNode;
              const ariaCheckedAttribute = parentNode.getAttribute('aria-checked');
  
              if (ariaCheckedAttribute === 'true') {
                parentNode.setAttribute('aria-checked', 'false');
                switchElement.setAttribute('aria-checked', 'false');
                switchElement.setAttribute('aria-live', 'assertive');
              } else {
                parentNode.setAttribute('aria-checked', 'true');
                switchElement.setAttribute('aria-checked', 'true');
                switchElement.setAttribute('aria-live', 'assertive');
              }
            };
            Cswitch.forEach(switchElement => {
                const parentNode = switchElement.parentNode;
                if(!this.isLinux()){
                  parentNode.setAttribute('aria-hidden', 'true');
                }
                const isFocused = document.activeElement === switchElement;
                switchElement.replaceWith(switchElement.cloneNode(true));
                switchElement = parentNode.querySelector('.switch-slider');
                switchElement.addEventListener('click', handleClick);
                switchElement.removeEventListener('keypress', switchElement.keypressHandler);
                const boundKeypressHandler = keypressHandler.bind(this);
                switchElement.addEventListener('keypress', boundKeypressHandler);
                switchElement.keypressHandler = boundKeypressHandler;
                switchElement.setAttribute('role', 'switch');
                switchElement.setAttribute('tabindex', '0');
                const parentAriaChecked = parentNode.getAttribute('aria-checked');
                  if (parentAriaChecked === 'true') {
                    switchElement.setAttribute('aria-checked', 'true');
                  } else {
                    switchElement.setAttribute('aria-checked', 'false');
                  }
                  if (isFocused) {
                    switchElement.focus();
                  }
  
            });          
      },
      isLinux() {
        return navigator.userAgent.toLowerCase().indexOf('linux') > -1;
      }
    }
}
</script>

<style>
td:first-child {
    text-transform: capitalize;
}
</style>
