<template>
  <div>
    <b-card>
        <template slot="header">
            <h4 class="mt-2 custom-card-header-text">Feedback</h4>
            <ul class="list-inline float-right header_table">
                <li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
                <li v-if="communities.length>1" class="list-inline-item">
                    <b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
                        <option :value="null" selected disabled>Community</option>
                        <option value="all">All</option>
                        <option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
                    </b-form-select>
                </li>
                <li v-if="circles.length>=1" class="list-inline-item place-center"><label for="circleFilter" class="custom-card-header-filter label-bottom-0">Circle:</label></li>
                <li v-if="circles.length>=1" class="list-inline-item">
                    <b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
                        <option :value="null" selected disabled>Circle</option>
                        <option value="all">All</option>
                        <option value="none">No Circle</option>
                        <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
                    </b-form-select>
                </li>
                <li>
                    <div class="row-bak d-flex-bak align-items-center-bak">
                        <datepicker @changed="dateFilter" 
                        style="padding-right: 10px;" label="Date Range"></datepicker>
                    </div>
                </li>
                <li class="list-inline-item">
                    <button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" title="Download feedback data"  class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                    <button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
                </li>
            </ul>
        </template>
        <b-card-body>
            <v-server-table :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" class="feedback-table">
                <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                    {{props.row.name}}
                </span>
                <span slot="name" slot-scope="props" v-else-if="props.row.name">
                    {{props.row.name}}
                </span>
                <span slot="received" slot-scope="props" v-if="props.row.received">
                    {{ props.row.received | momentDate }}
                </span>
                <span slot="feedback" slot-scope="props" v-if="props.row.feedback">
                    {{props.row.feedback}}
                </span>
                <span slot="feedback" slot-scope="props" v-else>
                    {{'N/A'}}
                </span>
                <span slot="received" v-else>N/A</span>
                <span slot="action" slot-scope="props">
                    <b-button :aria-label="props.row.commentCount > 0 ? 'View ' + props.row.commentCount : 'View the request'"
                        v-b-tooltip.hover.bottom class="notification-btn" variant="dropbox tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId)">
                        <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                        <i class="icon-eye"></i>
                    </b-button>
                </span>
            </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { shuffleArray } from '@/shared/utils'
import { exportCSV } from '@/assets/js/common';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from './components/datePicker';

export default {
    name: 'satisfactions',
    components: {
		datepicker,
	},
    data() {
        return {
            satisfactions: [],
            communities: [],
            circles: [],
            selected: "all",
            selectedCircle: "all",
            selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
            searchQuery: '',
            askCount: 0,
            columns: ['community', 'circleName', 'received', 'name', 'type', 'rating', 'feedback','action'],
            token: '',
            options: {
                headings: {
                    requestId: 'Request ID',
                    circleName: 'Circle'
                },
                sortable: [ 'circleName', 'received', 'name', 'type', 'rating', 'feedback'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                },
                requestFunction: async function (data) {
                    this.$parent.searchQuery = data.query;
                    return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},  params: data}).catch(function (e) {
                        if (e.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    }.bind(this));
                },
                responseAdapter({data}) {
                    this.$parent.askCount = data.askCount;
                    return {
                        data : data.feedback,
                        count: data.askCount
                    }
                }
            },
            serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction`,
            exportBtn: true,
            processBtn: false
        }
    },
    notifications: {
        showDownloadingMsg: {
            type: VueNotifications.types.success,
            message: "Export request sent.  Data is now downloading."
        },
        showWarnMsg: {
            type: VueNotifications.types.warn,
            message: "No data found for export file"
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        await this.getCommunities();
        this.loadData();
    },
    methods: {
        dateFilter(res) {
			// console.log("dates [selectedDates]:", res);
			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			this.filterByCommunity();
		},
        loadData() {
            var url = '';
            if(this.selected) {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/satisfactions`;
            }
            this.axios.get(url, {headers: {'x-access-token': this.token}}).then(response => {
                this.satisfactions = response.data;
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.$helpers.setTabindexOnTableCells();
        },
        async getCommunities() {
            const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
            .catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.communities = data;
            if(this.$route.params.community_id) {
                var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
                if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
        },
        filterByCommunity: async function() {
            // console.log("filterByCommunity")
            this.selectedCircle = "all";
                if (this.selected == "all") {
                    this.circles = [];
                    this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
                } else {
                    this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
                    this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				    });
                    this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/satisfactions?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
                }
        },
		filterByCircle: async function () {
            // console.log("filterByCircle")
			if (this.selectedCircle == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/satisfactions?startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/satisfactions?circle=${this.selectedCircle}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
			}
		},
    exportData: async function() {
        if (this.askCount > 0) {
        this.showDownloadingMsg();
        this.exportBtn = false;
        this.processBtn = true;
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction/export/?community=${this.selected ? this.selected : ''}&circle=${this.selectedCircle ? this.selectedCircle : ''}&query=${this.searchQuery}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data.satisfactionData.feedback.length) {
                    await exportCSV(response.data.satisfactionData.feedback.slice(), 'feedbacks', ['requestId','memberId'], ['received']);
                    this.exportBtn = true;
                    this.processBtn = false;
                } else {
                    this.showWarnMsg();
                    this.exportBtn = true;
                    this.processBtn = false;
                }
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        } else {
            this.showWarnMsg();
        }
    },
    redirectUrls: function(path) {
      window.open((`/engagement/members/${path}`), '_blank');
    },
    openRequestInNewTab(path) {
        window.open((`/engagement/requests/${path}`), '_blank');
    }
  }
}
</script>
