<template>
    <div>
        <b-card>
            <template slot="header">
                <h4 class="mt-2 custom-card-header-text">Member Rank Report</h4>
            </template>
            <h4 class="text-center">Please wait...</h4>
        </b-card>
  </div>
</template>

<script>
import { exportCSV } from '@/assets/js/common';
import axios from 'axios'
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';

export default {
    name: 'member-rank',
    data() {
        return {
            token: ''
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success,
            message: 'We are now preparing your export and will send you an email once it is available for download.',
            timeout: 3000
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        var queryParams = {
            headers: {'x-access-token': this.token}
        }
        this.exportData();
    },
    methods: {
        exportData: async function() {

            this.axios
                .get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/member-rank-report`, {headers: {'x-access-token': this.token}})
                .then(async response => {
                    if (response.data.isInstantDownloadable) {
                        if (response.data.membersData && response.data.membersData.length > 0) {                            
                            var response = exportCSV(response.data.membersData.slice(), 'member_rank', ['requestId', 'memberId', 'communityId'], ['last_request_made', 'last_response_sent', 'last_request_received', 'last_response_received', 'terms_accepted']);
                        }
                    } else {
                        this.showSuccessMsg();
                    }
                    this.$router.push('/dashboard');
                })
                .catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
                
        }
    }
}
</script>
