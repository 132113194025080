<template>
  <div>
    <b-card>
        <template slot="header">
            <h4 class="mt-2 custom-card-header-text">Response Approval</h4>
            <ul class="list-inline float-right">
              <li v-if="communities.length>1" class="list-inline-item"><label for="communityFilter" class="custom-card-header-filter">Community:</label></li>
              <li v-if="communities.length>1" class="list-inline-item">
                  <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                      <option :value="null" selected disabled>Community</option>
                      <option value="all">All</option>
                      <option v-for="community in communities" v-bind:value="community._id">{{community.name}}</option>
                  </b-form-select>
              </li>
              <li v-if="circles.length>=1" class="list-inline-item"><label for="circleFilter" class="custom-card-header-filter">Circle:</label></li>
              <li v-if="circles.length>=1" class="list-inline-item">
                <b-form-select v-model="selectedCircle" @change="filterByCircle" class="custom-card-header-filter" id="circleFilter">
                  <option :value="null" selected disabled>Circle</option>
                  <option value="all">All</option>
							    <option value="none">No Circle</option>
                  <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
                </b-form-select>
              </li>
              <li class="list-inline-item">
                <button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" title="Download response approval data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                <button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
              </li>
            </ul>
        </template>
        <b-card-body>
            <div class="toverflow-unset">
                <v-client-table v-bind:data="requests" v-bind:columns="columns" :options="options" id="dataTable" class="response-approval-table">
                    <span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                      {{props.row.member}}
                    </span>
                    <span slot="member" slot-scope="props" v-else-if = "props.row.member">
                      {{props.row.member}}
                    </span>
                    <span slot="advice" slot-scope="props">
                        <span v-b-tooltip.hover.bottom :title="props.row.advice" v-if="props.row.advice">{{getAdvice(props.row.advice)}}
                        </span>
                        <span v-else>N/A</span>
                    </span>
                    <span slot="date" slot-scope="props" v-if="props.row.date">
                      {{ props.row.date | momentDate }}
                    </span>
                    <span slot="date" v-else>N/A</span>
                    <span slot="action" slot-scope="props" class="res-req-action-width">
                      <ul class="list-inline mb-0 text-center">
                        <li class="list-inline-item">
                            <b-button v-b-tooltip.hover.bottom title="View response" variant="dropbox tab-focus" size="sm" @click="openResponseModal(props.row.advice)"><i class="icon-eye"></i></b-button>
                        </li>
                        <li class="list-inline-item" v-if="authUser.memberID || authUser.role =='SuperAdmin'">
                            <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                <template slot="button-content">
                                  <i class="icon-settings"></i>
                                </template>
                                <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'no')">Approve response</b-dropdown-item>
                                <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'yes')">Approve member</b-dropdown-item>
                                <!-- <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'reject', 'coach')">Reject and coach</b-dropdown-item> -->
                                <b-dropdown-item @click="$router.push(`/engagement/requests-response-coach/${props.row.requestId}/${props.row.giveId}`)">Reject and coach</b-dropdown-item>
                                <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'reject', 'no')">Reject and delete</b-dropdown-item>
                            </b-dropdown>
                        </li>
                      </ul>
                    </span>
                </v-client-table>
            </div>
        </b-card-body>
        <b-modal id="response-modal" class="response-modal" size="lg" v-model="responseModal" ok-title="Close" @ok="responseModal = false">
          {{giveAdvice}}
        </b-modal>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios'
import { exportCSV } from '@/assets/js/common';
import { setRequestStatus } from '@/assets/js/set-status';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';

export default {
  name: 'requests',
  data() {
    return {
      requests: [],
      communities: [],
			circles: [],
			selected: "all",
			selectedCircle: "all",
      columns: ['date', 'member', 'circleName', 'advice', 'action'],
      options: {
          headings: {
            member: 'Name',
            circleName: 'Circle'
          },
          sortable: ['date', 'member', 'circleName', 'advice'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
              chunk: 10,
              nav: 'fixed'
          }
      },
      token: '',
      incrementId: 0,
      responseModal: false,
      giveAdvice: '',
			exportBtn: true,
			processBtn: false
    }
  },
  notifications: {
		showWarnMsg: {
			type: VueNotifications.types.warn,
			message: "No data found for export file"
		},
		showDownloadingMsg: {
			type: VueNotifications.types.success,
			message: "Export request sent.  Data is now downloading."
		},
      showSuccessMsg: {
          type: VueNotifications.types.success
      }
  },
  async created() {
    this.token = localStorage.getItem('token');
    const user = localStorage.getItem('authUser');
    if(user) {
        this.authUser = JSON.parse(user);
    }
    await this.getCommunity();
    await this.loadData();
  },
  methods: {
    loadData() {
      var url = '';
      if(this.selected) {
        url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/?community_id=${this.selected}`;
      } else {
        url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval`;
      }
      this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
        if (response.data.resApproval) {
          this.requests = response.data.resApproval;
        }
      }).catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.$helpers.setTabindexOnTableCells();
    },
    checkData(value) {
      if(value) { return value; } else { return 'N/A';}
    },
    async getCommunity() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
      .catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.communities = data;
      if(this.$route.params.community_id) {
        var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
        if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
      } else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
    },
    filterByCommunity: async function(value) {
      this.selectedCircle = "all";
			if (this.selected == "all") {
				this.circles = [];
        const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/`, {headers: {'x-access-token': this.token}})
        .catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        if (data.resApproval) {
          this.requests = data.resApproval;
        }
      } else {
        this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
				this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				});
        const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/?community_id=${this.selected}`, {headers: {'x-access-token': this.token}})
        .catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        if (data.resApproval) {
          this.requests = data.resApproval;
        }
      }
    },
		filterByCircle: async function (value) {
			if (this.selectedCircle == "all") {
				const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/?community_id=${this.selected}`, {headers: {'x-access-token': this.token}})
        .catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        if (data.resApproval) {
          this.requests = data.resApproval;
        }
			} else {
				const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/?community_id=${this.selected}&circle=${value}`, {headers: {'x-access-token': this.token}})
        .catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        if (data.resApproval) {
          this.requests = data.resApproval;
        }
			}
		},
    setStatus: function(status) {
        return setRequestStatus(status);
    },
    exportData: async function () {
        this.showDownloadingMsg();
				this.exportBtn = false;
				this.processBtn = true;
				let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/approval/?community_id=${this.selected ? this.selected : ''}&circle=${this.selectedCircle ? this.selectedCircle : ''}`;
				let exculdedColumns = [];
				await this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
					if (response.data.resApproval && response.data.resApproval.length) {
						const resApproval = response.data.resApproval;
						await exportCSV(resApproval.slice(), 'responseApprovals');
						this.exportBtn = true;
						this.processBtn = false;
					} else {
						this.showWarnMsg();
						this.exportBtn = true;
						this.processBtn = false;
					}
				}).catch(err => {
					if (err.response.status === 401) {
						localStorage.removeItem('authUser');
						this.$router.push('/login');
					}
				});
		},
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    approveResponse: function(askId, giveId, action, status) {
        let params = {
            askId: askId,
            giveId: giveId,
            action: action,
            status: status,
            approvedBy: 'dashboard'
        }
        if (this.authUser.memberID) {
            params.adminId = this.authUser.memberID;
        }
        this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
            // setTimeout(async () => {
              await this.loadData();
              let message = '';
              if (action == 'approve') {
                message = 'Thank you for approving the response.'
                this.showSuccessMsg({message: message});
                this.announcement(message);
              } else if (action == 'reject') {
                message = 'Thank you for your response';
                this.showSuccessMsg({message: message})
                this.announcement(message);
              }
            // }, 2000);
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
    },
    getAdvice: function(advice) {
        if(advice.length > 88) {
            return advice.substr(0, 88) + '...';
        } else {
            return advice;
        }
    },
    openResponseModal: function(advice) {
        this.responseModal = true;
        this.giveAdvice = advice;
    },
    announcement(message){
            const liveRegion = document.createElement('div');
            liveRegion.setAttribute('aria-live', 'polite');
            liveRegion.setAttribute('role', 'alert');
            liveRegion.innerText = message;
            document.body.appendChild(liveRegion);
            setTimeout(() => {
                liveRegion.remove();
            },1000);
        }
  }
}
</script>
