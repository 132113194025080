<template>
	<div>
		<b-card>
			<template slot="header">
				<h4 class="mt-2 custom-card-header-text">Reports</h4>
			</template>
			<b-card-body>
				<v-client-table v-bind:data="reports" v-bind:columns="columns" :options="options" id="dataTable" class="reports-table">
					<span slot="name" slot-scope="props">
                    {{ props.row.name }}
                    </span>
					<span slot="description" slot-scope="props">
                    {{ props.row.description }}
                    </span>
                    <span slot="date range filter" slot-scope="props"> 
                        <div class="row-bak d-flex-bak align-items-center-bak">
							<datepicker @changed="dateFilter($event, props.row.type)" 
							style="padding-right: 10px;" label=""></datepicker>
						</div>
                    </span>
                    <span slot="action" slot-scope="props" class="action-width">
                        <button type="button" aria-label="Export" aria-live="assertive" @click="exportData(props.row.type)" title="Download report" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                    </span>
				</v-client-table>
			</b-card-body>
		</b-card>
		
       
	</div>
</template>

<script>

import axios from 'axios'
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from '../engagement/components/datePicker';
import { reportType } from './constants/reportConstant';
import { exportCSV } from '@/assets/js/common';

export default {
    name: 'reports',
    components: {
		datepicker,
	},
    data() {
        return {
            token: '',
            reports: [],
            columns: ['name', 'description', 'date range filter','action'],
            options: {
                sortable: ['name', 'description'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
        }
    },
    notifications: {
        showDownloadingMsg: {
            type: VueNotifications.types.success,
            message: "Export request sent.  Report will be downloaded in a moment."
        },
        showWarnMsg: {
            type: VueNotifications.types.warn,
            message: "No data found for export file"
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        this.loadData();
    },
    methods: {
        dateFilter(res, type) {
            let index = this.reports.findIndex(r => r.type == type);
			this.reports[index].startDate =  res.startDate;
			this.reports[index].endDate =  res.endDate;
		},
        loadData: async function() {
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/all-reports/`, 
            {headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data) {
                   this.reports = response.data;
                } 
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.$helpers.setTabindexOnTableCells();
        },
        exportData: async function(type) {
            let report = this.reports.find(r => r.type == type);

            const startDate = report.startDate ? report.startDate: new Date(0);
            const endDate = report.endDate ? report.endDate: new Date();
            
            const url = this.reportTypeUrl(type, startDate, endDate);
            
            const response = await this.fetchExportData(url);

            if(response && response.data) {
                await this.downloadCsv(type, response);
            }
        },
        reportTypeUrl: function(type, startDate, endDate) { 
            let url = "";
            switch (type) {
                case reportType.Interaction:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/interaction-report?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.OptOut:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/member-opt-out-report?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.OverDueRequests:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/requestOverDue?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.InvalidEmails:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/invalidEmail?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.NoFollowUp:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/noFollowUp/?export=csv&&startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.DonorEngagement:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/donorEngagement?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.ProfileUpdates: 
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/memberProfileChanges?startDate=${startDate}&&endDate=${endDate}`
                    break;
                case reportType.Referrals:
                    url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/report?startDate=${startDate}&&endDate=${endDate}`
                    break;
            }
            return url;
        },
        fetchExportData: async function(url, startDate, endDate) {
            try {
                this.showDownloadingMsg();
                this.announcement("Export request sent.  Report will be downloaded in a moment.");
                const authHeader =  {headers: {'x-access-token': this.token}};
                const response = await this.axios.get(url, authHeader)
                return response;
            } catch (error) {
                if (error.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            }
        },
        downloadCsv: async function(type, response) {
            switch (type) {
                case reportType.Interaction:
                    if (response.data && response.data.length) {
                        await exportCSV(response.data.slice(), 'interaction', ['responses'], [ 'date', 'response_date']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.OptOut:
                    if (response.data && response.data.length) {
                        await exportCSV(response.data.slice(), 'member-opt-out', [], ['date']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.OverDueRequests:
                    if (response.data && response.data.asks.length) {
                        await exportCSV(response.data.asks.slice(), 'overdue-requests', ['title','requestId','memberId', 'commentCount'], ['date']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.InvalidEmails:
                    if (response.data && response.data.length) {
                        response.data.sort(function (a, b) {
                            return b.date.toLowerCase().localeCompare(a.date.toLowerCase());
                        });
                        await exportCSV(response.data.slice(), 'invalid-emails', [], ['date']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.NoFollowUp:
                    if (response.data && response.data.give.length) {
                        await exportCSV(response.data.give.slice(), 'no-follow-up', [], ['requestReceived', 'responseDate', 'responseFeedbackDate']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.DonorEngagement:
                    if (response.data && response.data.length) {
                        let donorData = this.$lodash.orderBy(response.data, ['sent'], ['desc']);
                        await exportCSV(donorData.slice(), 'donor-engagement', ['advisorId', 'communityId'], ['sent']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.ProfileUpdates: 
                    if (response.data) {
                        await exportCSV(response.data.profileChanges.slice(), 'profileChanges', [], ['createdAt']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                case reportType.Referrals:
                    console.log("Referals download CSV called");
                    if (response.data && response.data.length) {
                        await exportCSV(response.data.slice(), 'referrals', [], ['date']);
                    } else {
                        this.showWarnMsg();
                    }
                    break;
                
            }
        },
        announcement(message){
            const liveRegion = document.createElement('div');
            liveRegion.setAttribute('aria-live', 'polite');
            liveRegion.setAttribute('role', 'alert');
            liveRegion.innerText = message;
            document.body.appendChild(liveRegion);
            setTimeout(() => {
                liveRegion.remove();
            },1000);
        }
    }
}
</script>
