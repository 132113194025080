<template>
  <div>
    <h1>Edit Response</h1>
    <form @submit.prevent="updateResponse">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Response Customer:</label>
            <input type="text" class="form-control" v-model="response.customer">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Response Member:</label>
            <input type="text" class="form-control" v-model="response.member">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Response Title:</label>
            <input type="text" class="form-control" v-model="response.title">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Response Date:</label>
            <input type="text" class="form-control" v-model="response.date">
          </div>
        </div>
      </div>
        <br />
        <div class="form-group">
          <button class="btn btn-primary">Update</button>
        </div>
    </form>
  </div>
</template>

<script>
    export default {
      name: 'responsesedit',
      data() {
        return {
          response: {}
        }
      },
      created() {
        let uri = `${process.env.VUE_APP_API_BASE_URL}/responses/edit/${this.$route.params.id}`;
        this.axios.get(uri).then((response) => {
            this.response = response.data;
        });
      },
      methods: {
        updateResponse() {
          let uri = `${process.env.VUE_APP_API_BASE_URL}/responses/update/${this.$route.params.id}`;
          this.axios.post(uri, this.response).then(() => {
            this.$router.push({name: 'Responses'});
          });
        }
      }
    }
</script>