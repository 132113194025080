<template>
    <div>
    <b-card>
        <b-card-body>
            <b-row>
                <b-col sm="5">
                    <h4 id="engagement" class="card-title mb-0">{{`${this.$route.params.slug == 'email_open' ? 'Email Open Rate On Requests': this.$route.params.slug == 'engagement_rate' ? 'Engagement Rate' : this.$route.params.slug == 'first_response' ? 'Time To First Response' : this.$route.params.slug == 'response_per_request' ? 'Responses Per Request' : this.$route.params.slug == 'satisfaction' ? 'Average Satisfaction' : this.$route.params.slug == 'profile_update' ? 'Profile Updates' : 'Daily Count' }`}}</h4>
                    <!-- <div class="small text-muted display-hide">{{changeDate()}}</div> -->
                </b-col>
                <b-col sm="7" class="d-none d-md-block">
                    <button type="button" aria-label="Export" aria-live="assertive" @click="exportData($route.params.slug)" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                    <b-button-toolbar class="float-right" aria-label="Toolbar with buttons group">
                        <b-form-radio-group class="mr-3" id="radiosBtn" buttons button-variant="outline-secondary" v-model="selected" name="radiosBtn">
                            <!-- <b-form-radio class="mx-0" value="day">Day</b-form-radio> -->
                            <b-form-radio class="mx-0" value="month">Month</b-form-radio>
                            <b-form-radio class="mx-0" value="year">Year</b-form-radio>
                        </b-form-radio-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <main-chart-example chartId="main-chart-01" class="chart-wrapper" style="margin-top:40px; height:300px;"  :bind="true" :chartData="chartData" :options="chartOptions" counter-data="0" :field="selected"></main-chart-example>
        </b-card-body>
    </b-card>
    </b-card>
        <b-card-body>
            <v-client-table v-bind:data="responseData" v-bind:columns="columns" :options="options" id="dataTable" class="analytic-table">
                <span slot="count" slot-scope="props">
                    {{props.row.count}}
                </span>
                <span slot="date" slot-scope="props">
                    {{ props.row.date }}
                </span>
            </v-client-table>
        </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { exportCSV } from '@/assets/js/common';
import VueNotifications from 'vue-notifications';
import MainChartExample from '../../dashboard/MainChartExample';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

export default {
    name: 'Search',
    components: {
        MainChartExample
    },
    data() {
        return {
            token: localStorage.getItem('token'),
            selected: 'month',
            responseData: [],
            columns: ['date', 'count'],
            options: {
                headings: {
                    count: `${this.$route.params.slug == 'email_open' ? 'Email Open Rate (In %)': this.$route.params.slug == 'engagement_rate' ? 'Engagement Rate (In %)' : this.$route.params.slug == 'first_response' ? 'Response Time (In hrs)' : this.$route.params.slug == 'response_per_request' ? 'Response Rate' : this.$route.params.slug == 'satisfaction' ? 'Satisfaction' : this.$route.params.slug == 'profile_update' ? 'Profile Updates' : 'Count' }`
                },
                sortable: ['date', 'count'],
                filterable: false,
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            },
            chartScale: 500,
            chartData: {
                labels: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                datasets: [{
                    backgroundColor: hexToRgba('#20a8d8', 10),
                    borderColor: '#20a8d8',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: []
                },
                {
                    // backgroundColor: 'transparent',
                    backgroundColor: hexToRgba('#008000', 10),
                    borderColor: '#008000',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: []
                }]
            },
            chartOptions: {
                tooltips: {
                    enabled: false,
                    custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        labelColor: function (tooltipItem, chart) {
                            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        fontColor: "#fff",
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: "#fff"
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            stepSize: Math.ceil(10 / 5),
                            max: 10,
                            fontColor: "#fff"
                        },
                        gridLines: {
                            display: true
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3
                    }
                }
            },

        }
    },
    notifications: {
        showErrorMsg: {
            type: VueNotifications.types.error
        }
    },
    beforeCreate() {
        if (this.$route.params.slug !== 'email_open' && this.$route.params.slug !== 'engagement_rate' && this.$route.params.slug !== 'first_response' && this.$route.params.slug !== 'response_per_request' && this.$route.params.slug !== 'satisfaction' && this.$route.params.slug !== 'profile_update') {
            this.$router.push('/');
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        this.loadData();
    },
    watch: {
        "selected": "loadData"
    },
    methods: {
        async loadData() {
            let url = '';
            if (this.$route.params.slug == 'email_open') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/email_open_rate_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
            }
            if (this.$route.params.slug == 'engagement_rate') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/engagement_rate_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
            }
            else if (this.$route.params.slug == 'first_response') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/first_response_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
            } else if (this.$route.params.slug == 'response_per_request') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response_per_request_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
            } else if (this.$route.params.slug == 'satisfaction') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/satisfaction_avg_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
                this.columns = ['date', 'memberFeedback', 'adviseFeedback'];
                this.options.headings = {
                    memberFeedback: 'Average Member Satisfaction',
                    adviseFeedback: 'Average Advisor Satisfaction'
                }
                this.chartOptions.legend.display = true;
                this.chartData.datasets[0].label = 'Average Member Satisfaction';
                this.chartData.datasets[1].label = 'Average Advisor Satisfaction';
                this.options.sortable = ['date', 'memberFeedback', 'adviseFeedback'];
            } else if (this.$route.params.slug == 'profile_update') {
                url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/update_profile_by_date/?date=${this.selected}&&community_id=${this.$route.query.community_id ? this.$route.query.community_id: ''}`;
            }
            if (url) {
                await this.axios.get(url, {headers: {'x-access-token': this.token}}).then(response => {
                    let labels = [];
                    let data = [];
                    let data1 = [];
                    let chartScale = 0;
                    this.responseData = response.data;
                    if (this.selected == 'day') {
                        this.responseData = [this.responseData[0]];
                    }
                    if (this.selected == 'month') {
                        // response.data = this.$lodash.orderBy(response.data, ['date'], ['desc']);
                        // sort date in desc order (old to new)
                        response.data = this.$lodash.orderBy(response.data, function(data) {return new Date(data.date);});
                    }
                    for (let i = 0; i < response.data.length; i++) {
                        labels.push(response.data[i].date);
                        if (this.$route.params.slug == 'email_open') {
                            response.data[i].count = response.data[i].open;
                            data.push(response.data[i].count);
                        } else if (this.$route.params.slug == 'satisfaction') {
                            data.push(response.data[i].memberFeedback);
                            data1.push(response.data[i].adviseFeedback);
                        } else if (this.$route.params.slug == 'first_response') {
                            if (response.data[i].count == 'N/A') {
                                data.push(0);
                            } else {
                                data.push(response.data[i].count);
                            }
                        } else {
                            data.push(response.data[i].count);
                        }
                        if (response.data[i].count > chartScale) {
                            chartScale = response.data[i].count;
                        }
                    }
                    this.chartScale = chartScale;
                    this.chartOptions.scales.yAxes[0].ticks.max = chartScale;
                    if (this.selected == 'day') {
                        data.sort()
                    } else if(this.selected == 'year') {
                        labels.reverse();
                        data.reverse();
                    }
                    this.chartData.labels = labels;
                    this.chartData.datasets[0].data = data;
                    if (this.$route.params.slug == 'satisfaction') {
                        this.chartOptions.scales.yAxes[0].ticks.max = Math.max(...data);
                        if(this.selected == 'year') {
                            data1.reverse();
                        }
                        this.chartData.datasets[1].data = data1;
                        if (Math.max(...data1) > Math.max(...data)) {
                            this.chartOptions.scales.yAxes[0].ticks.max = Math.max(...data1);
                        }
                    }
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            }
        },
        calculateSum(val) {
            return val.reduce((a, b) => {return a + b}, 0);
        },
        changeDate() {
            if (this.selected == 'day') {
                return this.$moment(new Date()).format('dddd')
            } else if (this.selected == 'month') {
                return this.$moment(new Date()).format('MMMM Y')
            } else if(this.selected == 'year') {
                return this.$moment(new Date()).format('YYYY')
            }
        },
        checkDate(value) {
            return this.$moment(value).tz("America/New_York").format('MM/DD/YYYY');
        },
        exportData: async function(fileName) {
            if (this.responseData.length) {
                if (this.selected == 'year') {
                    await exportCSV(this.responseData.slice(), fileName, [], []);
                } else {
                    await exportCSV(this.responseData.slice(), fileName, [], ['date']);
                }
            } else {
                this.showErrorMsg({message: 'No data found for export file'});
            }
        }
    }
}
</script>
