<template>
    <div>
        <b-card header="Engagement Scoring Model">
        <b-card-body>
            <v-client-table v-bind:data="engagement" v-bind:columns="columns" :options="options" id="dataTable" class="engagement-score-table">
                <div slot="category" slot-scope="props">
                    <select title="Select a category" v-model="props.row.category" :options="categories" v-on:change="UpdateEngagementScore(props.row._id, props.row.category, 'category')" class="form-control" tooltip="Select a category">
                        <option v-for="option in categories" :value="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <div slot="points" slot-scope="props">
                    <input name="name" title="Add score points" @blur="UpdateEngagementScore(props.row._id, props.row.points, 'point')" type="number" placeholder="Enter point" v-model="props.row.points" class="form-control" id="__BVID__147">
                </div>
                <div slot="time" slot-scope="props">
                    <input name="name" title="Input time in minutes" @blur="UpdateEngagementScore(props.row._id, props.row.minutes, 'time')" type="number" placeholder="Enter minutes" v-model="props.row.minutes" class="form-control" id="__BVID__148">
                </div>
                <div slot="description" slot-scope="props" v-if="authUser.role == 'SuperAdmin'">
                    <textarea name="description" title="Add a personalized description" @blur="UpdateEngagementScore(props.row._id, props.row.description, 'description')" placeholder="Enter description" rows="2" cols="40" v-model ="props.row.description" class="form-control"></textarea>
                </div>
                <div slot="description" slot-scope="props" v-else>
                    {{props.row.description}}
                </div>
            </v-client-table>
        </b-card-body>
        </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'EngagementScoringModel',
    data() {
        return {
            engagement: [],
            columns: ['rank', 'engagement_type','category', 'points','time', 'description'],
            options: {
                headings: {
                    rank: 'ID',
                    engagement_type: 'Type'
                },
                sortable: ['rank', 'engagement_type','category', 'points', 'time', 'description'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                  chunk: 5,
                  edge: false,
                  nav: 'scroll'
                }
            },
            selected:'null',
            categories:[
                {text:"Communication",value:"Communication"},
                {text:"Experiential",value:"Experiential"},
                {text:"Philanthropy",value:"Philanthropy"},
                {text:"Volunteering",value:"Volunteering"}
            ],
            token: '',
            authUser: {}
        }
    },
    created() {
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData() {
            this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/engagement-score`, {headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data) {
                    this.engagement = response.data;
                }
            }).catch(err => {
                if (err.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
            this.$helpers.setTabindexOnTableCells();
        },
        UpdateEngagementScore(id, updateField, field){
            let isUpdateField = false;
            let scoreObj = {
               
               _id: id
            }
            if (field == 'category') {
                isUpdateField = true;
                scoreObj.category = updateField;
            }
            if (field == 'point') {
                if (Math.sign(updateField) > -1) {
                    isUpdateField = true;
                    scoreObj.points = updateField;
                }
            }
            if (field == 'time') {
                if (Math.sign(updateField) > -1) {
                    isUpdateField = true;
                    scoreObj.minutes = updateField;
                }
            }
            if (field == 'description') {
                isUpdateField = true;
                scoreObj.description = updateField;
            }
            if (isUpdateField){
                this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/update-engagement-score`, scoreObj, {headers: {'x-access-token': this.token}}).then(async response => {
                    this.loadData();
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.loadData();
            }
        },
    }
}
</script>
