<template>
	<div>
		<b-card>
			<template slot="header">
				<h4 class="mt-2 custom-card-header-text">Engagement</h4>
				<ul class="list-inline float-right custom-header-rightstyle header_table">
					<li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
					<li v-if="communities.length>1" class="list-inline-item">
						<b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
							<option :value="null" selected disabled>Community</option>
							<option value="all">All</option>
							<option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
						</b-form-select>
					</li>
					<li v-if="circles.length>=1" class="list-inline-item"><label for="circleFilter" class="custom-card-header-filter">Circle:</label></li>
					<li v-if="circles.length>=1" class="list-inline-item">
						<b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
							<option :value="null" selected disabled>Circle</option>
							<option value="all">All</option>
							<option value="none">No Circle</option>
							<option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{circle.name}}</option>
						</b-form-select>
					</li>
					<li>
						<div class="row-bak d-flex-bak align-items-center-bak">
							<datepicker @changed="dateFilter"
							style="padding-right: 10px;" label="Date Range"></datepicker>
						</div>
					</li>
					<li class="list-inline-item place-center"><label for="stream-action" class="custom-card-header-filter label-bottom-0">Type:</label></li>
					<li class="list-inline-item">
						<b-form-select v-model="selectedAction" v-on:change="filterByAction()" class="custom-card-header-filter" id="stream-action">
							<option :value="null" selected disabled>Action</option>
							<option v-for="(action, idx) in actions" :key="`${idx}-${action}`" v-bind:value="action">{{ action}}</option>
						</b-form-select>
					</li>
					<li class="list-inline-item">
						<button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
						<button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
					</li>
				</ul>
			</template>
			<b-card-body>
				<v-server-table :url="serverURL" :columns="columns" :options="options" @loaded="onLoaded" name="dataTable" id="dataTable" class="engagement-stream-table">
					<span slot="community" slot-scope="props">
                        {{ props.row.community ? props.row.community : 'N/A'}}
                    </span>
					<span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if = "props.row.memberId">
                        {{ props.row.member}}
                    </span>
					<span slot="member" slot-scope="props" v-else-if = "props.row.member">
                        {{ props.row.member}}
                    </span>
					<span slot="date" slot-scope="props" v-if="props.row.date">
                    {{ props.row.date | momentDate}}
                    </span>
					<span slot="type" slot-scope="props" v-if="props.row.type" :class="[{'badge badge-primary': props.row.type == 'Request'}, {'badge badge-success': props.row.type == 'Response'}, {'badge badge-warning': props.row.type == 'Email' || props.row.type == 'Referral'}, {'badge badge-danger': props.row.type == 'Feedback'}, {'badge badge-danger': props.row.type == 'Escalation'}, {'badge badge-info': props.row.status == 'updateProfile'}, {'badge badge-danger': props.row.status == 'followedUp'}, {'badge badge-success': props.row.status == 'thankYou'},{'badge badge-info': props.row.status == 'alert'},{'badge badge-danger': props.row.status == 'optOut'}]">
                        {{ props.row.type}}
                    </span>
					<span slot="date" v-else>N/A</span>
					<span slot="view" class="action-width" slot-scope="props">
                      <b-button v-b-tooltip.hover.bottom aria-label="View the request" v-if="props.row.requestId" variant="dropbox tab-focus" size="sm" @click="openRequestInNewTab(props.row.requestId)"><i class="icon-eye"></i></b-button>
					  <b-button v-b-tooltip.hover.bottom title="View member" v-else-if="props.row.memberId" variant="dropbox tab-focus" size="sm" @click="redirectUrls(props.row.memberId)"><i class="icon-eye"></i></b-button>
                    </span>
				</v-server-table>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import {exportCSV} from '@/assets/js/common';
import axios from 'axios'
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from './components/datePicker';

export default {
	name: 'stream',
	components: {
		datepicker,
	},
	data() {
		return {
			engagement: [],
			columns: ['date', 'community', 'circleId', 'member', 'type', 'typeDetail', 'title', 'points', 'view'],
			communities: [],
			circles: [],
			selected: "all",
			selectedAction: "All",
			selectedCircle: "all",
			selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
			token: '',
			searchQuery: '',
			engagementCount: 0,
			actions: ['All', 'Request', 'Response', 'Feedback', 'Email Open', 'Email Click', 'Email Delivered', 'Referral', 'Update Profile', 'Thank You', 'Followed Up', 'Opt Out', 'Donor Alert', 'Escalation'],
			serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement`,
			options: {
				sortable: ['circleId', 'date', 'community', 'type', 'typeDetail', 'member', 'title', 'points'],
				sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
				headings: {
					typeDetail: 'Type Detail',
					circleId: 'Circle'
				},
				pagination: {
					chunk: 10,
					nav: 'fixed'
				},
				requestFunction: async function (data) {
					this.$parent.searchQuery = data.query;
					return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')}, params: data}).catch(function (e) {
						if (e.response.status === 401) {
							localStorage.removeItem('authUser');
							this.$router.push('/login');
						}
					}.bind(this));
				},
				responseAdapter({data}) {
					this.$parent.engagementCount = data.engagementCount;
					return {
						data: data.engagementData,
						count: data.engagementCount
					}
				},
				filterable: true,
				customSorting: {
					date: function (ascending) {
						return function (a, b) {
							var isValid = function (date) {
								return !isNaN(isFinite(date))
							}
							var lastA = a.date;
							var lastB = b.date;
							if (ascending && isValid(lastA) && isValid(lastB)) {
								return new Date(lastA) - new Date(lastB);
							}
							if (!ascending && isValid(lastA) && isValid(lastB)) {
								return lastA <= lastB ? 1 : -1;
							}
						}
					}
				}
			},
			exportBtn: true,
			processBtn: false
		}
	},
	notifications: {
		showWarnMsg: {
			type: VueNotifications.types.warn,
			message: "No data found for export file"
		},
		showDownloadingMsg: {
			type: VueNotifications.types.success,
			message: "Export request sent.  Data is now downloading."
		}
	},
	async created() {
		this.token = localStorage.getItem('token');
		this.getCommunities();
		this.actions.sort();
	},
	methods: {
		dateFilter(res) {
			// console.log("dates [selectedDates]:", res);
			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			console.log("this.selectedCircle : ", this.selectedCircle)
			if(this.selectedCircle != "all") {
				this.filterByCircle();
			} else if (this.selectedAction != "all") {
				this.filterByAction();
			} else {
				this.filterByCommunity();
			}
		},
		onLoaded(){
			this.$helpers.setTabindexOnTableCells();
			this.$helpers.setPaginationAnnouncement();
		},
		async getCommunities() {
			const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
			this.communities = data;
			if (this.$route.params.community_id) {
				const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
				if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();			
		},
		filterByCommunity: async function () {
			this.selectedCircle = "all";
			if (this.selected == "all") {
				this.circles = [];
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			} else {
				this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
				this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				});
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?type=${this.selectedAction}&community=${this.selected}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			}
		},
		filterByCircle: async function () {
			if (this.selectedCircle == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?type=${this.selectedAction}&community=${this.selected}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?type=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			}
		},
		filterByAction: async function () {
			if (this.selectedAction == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?community=${this.selected}&circle=${this.selectedCircle}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?type=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
			}
		},
		exportData: function () {
			if (this.engagementCount > 0) {
				this.showDownloadingMsg();
				this.exportBtn = false;
				this.processBtn = true;
				this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/getAllEngagement/?type=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}&query=${this.searchQuery}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`, {headers: {'x-access-token': this.token}}).then(async response => {
					if (response.data.engagementData && response.data.engagementData.length) {
						this.engagement = response.data.engagementData;
						await exportCSV(this.engagement.slice(), 'engagement', ['location', 'source', 'memberId', '_id', 'communityId', 'status', 'queryAction', '__v'], ['date']);
						this.exportBtn = true;
						this.processBtn = false;
					} else {
						this.showWarnMsg();
						this.exportBtn = true;
						this.processBtn = false;
					}
				}).catch(err => {
					if (err.response.status === 401) {
						localStorage.removeItem('authUser');
						this.$router.push('/login');
					}
				});
			} else {
				this.showWarnMsg();
			}
		},
		redirectUrls: function (path) {
			window.open((`/engagement/members/${path}`), '_blank');
		},
		openRequestInNewTab(path) {
			window.open((`/engagement/requests/${path}`), '_blank');
		},		
	}
}
</script>
